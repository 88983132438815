import { ButtonUnstyled } from "@mui/base";
import "../App.css";
import { useNavigate } from "react-router-dom";
import { useState, useEffect, useContext } from "react";
import { EndPointType } from "@xaratan/pagerr-common";
import {
  FaCheckCircle,
  FaExclamation,
  FaExclamationCircle,
  FaPlusCircle,
} from "react-icons/fa";
import { MdChevronRight, MdPending } from "react-icons/md";
import { Colors } from "../Colors";
import { useGlobals } from "../contexts/useGlobals";
import { usePostState } from "../helpers/usePostState";
import { findByPlaceholderText } from "@testing-library/react";
import { Link } from "@mui/material";

export default function ProviderCredentialedFacilities() {
  const navigate = useNavigate();

  const globalState = useGlobals();

  const [facilities] = usePostState(
    globalState.api.myFacilitiesListingView,
    globalState.callOptions(),
    {},
    {}
  );

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener("resize", updateWidthAndHeight);
    return () => window.removeEventListener("resize", updateWidthAndHeight);
  });

  const updateWidthAndHeight = () => {
    setWindowWidth(window.innerWidth);
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-start",
        flexDirection: "column",
        flex: 1,
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "row",
          marginBottom: 20,
          flexWrap: "wrap",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "flex-start",
            flexDirection: "column",
          }}
        >
          <h1
            style={{
              margin: 0,
              fontSize: 32,
              marginBottom: 12,
              fontWeight: "700",
            }}
          >
            My Facilities
          </h1>
          <p style={{ color: Colors.lightGray }}>
            These are facilities where you are credentialed to work.
          </p>
        </div>
        <ButtonUnstyled
          style={{
            cursor: "pointer",
            borderRadius: 4,
            paddingBlock: 4,
            paddingInline: 32,
            border: `1px solid ${Colors.blue}`,
            background: Colors.border,
            color: Colors.blue,
            fontWeight: 600,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            fontSize: 20,
            boxShadow: "2px 2px 4px rgba(0,0,0,0.2)",
            gap: 8,
            alignSelf: "flex-end",
          }}
          onClick={() => navigate("/provider/addhospital")}
        >
          Add Facility&nbsp;
          <FaPlusCircle size={20} />
        </ButtonUnstyled>
      </div>
      <div style={{ flex: 1, marginBottom: 240 }}>
        <div>
          <div
            style={{
              maxWidth: 640,
              flex: 1,
              gap: 12,
              display: "flex",
              flexDirection: "column",
            }}
          >
            {(facilities?.facilities ?? []).map(function (
              item: EndPointType<{
                required: {
                  credentialingId: "string";
                  facilityId: "string";
                  name: "string";
                  address: {
                    required: {
                      address1: "string";
                      address2: "string";
                      city: "string";
                      state: "string";
                      zip: "string";
                    };
                  };
                  entranceProtocol: "string";
                  credentialingStatus: "string";
                };
              }>
            ) {
              return (
                <div
                  className="jobCard"
                  style={{
                    flex: 1,
                    display: "flex",
                    flexWrap: "wrap",
                    border: "1px solid #eaeaea",
                    borderRadius: 4,
                    padding: 12,
                    alignItems: "stretch",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    gap: 4,
                  }}
                  key={item.credentialingId}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "flex-start",
                      flexWrap: "wrap",
                      flex: 1,
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <p style={{ fontWeight: "700", marginBottom: 0 }}>
                        {item.name}
                      </p>
                      <p
                        style={{
                          fontSize: 12,
                          fontWeight: 500,
                          marginTop: 0,
                        }}
                      >
                        {item.address.address1}
                      </p>
                    </div>
                    {item.credentialingStatus === "pending" ? (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "flex-end",
                          color: Colors.orange,
                          gap: 4,
                        }}
                      >
                        <MdPending size={20} style={{ flexShrink: 0 }} />
                        <p style={{ fontWeight: 600 }}>Credentials Pending</p>
                      </div>
                    ) : null}
                    {item.credentialingStatus === "approved" ? (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "flex-end",
                          color: Colors.green,
                          gap: 4,
                        }}
                      >
                        <FaCheckCircle size={18} style={{ flexShrink: 0 }} />
                        <p style={{ fontWeight: 600 }}>Credentials Verified</p>
                      </div>
                    ) : null}
                    {item.credentialingStatus === "rejected" ? (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "flex-end",
                          color: Colors.red,
                          gap: 4,
                        }}
                      >
                        <FaExclamationCircle
                          size={18}
                          style={{ flexShrink: 0 }}
                        />
                        <p style={{ fontWeight: 600 }}>Credentials Rejected</p>
                      </div>
                    ) : null}
                    {item.credentialingStatus === "cancelled" ? (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "flex-end",
                          color: Colors.red,
                          gap: 4,
                        }}
                      >
                        <FaExclamationCircle
                          size={18}
                          style={{ flexShrink: 0 }}
                        />
                        <p style={{ fontWeight: 600 }}>Credentials Cancelled</p>
                      </div>
                    ) : null}
                  </div>
                  {item.credentialingStatus === "pending" ? (
                    <div
                      style={{
                        padding: 8,
                        borderTop: "1px solid #eaeaea",
                      }}
                    >
                      <p style={{ fontSize: 16 }}>
                        We're working to confirm your credentials at&nbsp;
                        {item.name}. You won't be able to schedule jobs here
                        until we've confirmed your credential status.
                      </p>
                    </div>
                  ) : null}
                  {item.credentialingStatus === "rejected" ? (
                    <div
                      style={{
                        padding: 8,
                        borderTop: "1px solid #eaeaea",
                      }}
                    >
                      <p style={{ fontSize: 16 }}>
                        Your credentials at&nbsp;
                        {item.name} were rejected. We'll reach out to help you
                        figure out what's up.
                      </p>
                    </div>
                  ) : null}
                  {item.credentialingStatus === "approved" ? (
                    <Link
                      style={{
                        cursor: "pointer",
                        borderRadius: 4,
                        paddingBlock: 4,
                        paddingLeft: 12,
                        color: Colors.blue,
                        fontWeight: 600,
                        fontSize: 14,
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                        alignSelf: "flex-end",
                        gap: 4,
                        // boxShadow: "2px 2px 4px rgba(0,0,0,0.2)",
                      }}
                      underline={"hover"}
                      onClick={() => navigate("/provider/listings")}
                    >
                      See Jobs at {item.name}
                      <MdChevronRight size={20} />
                    </Link>
                  ) : null}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

function DashboardSection(props: {
  title: string;
  button?: boolean;
  buttonTitle?: string;
  handleButton?: any;
  children?: any;
}) {
  return (
    <div
      style={{
        marginBottom: 28,
        border: "1px solid #eaeaea",
        borderRadius: 8,
        padding: 12,
      }}
    >
      <div
        style={{
          display: "flex",
          flex: 1,
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <h2 style={{ marginBottom: 16 }}>{props.title}</h2>
        {props.button === undefined || false ? null : (
          <ButtonUnstyled
            style={{
              // margin: 12,
              borderRadius: 4,
              padding: 12,
              alignSelf: "flex-start",
              fontSize: 14,
              color: "#fff",
              fontWeight: "600",
              border: "0px",
              background: Colors.blue,
              cursor: "pointer",
            }}
            onClick={props.handleButton}
          >
            {props.buttonTitle}
          </ButtonUnstyled>
        )}
      </div>
      <div style={{ height: 1, background: "#eaeaea" }} />
      {props.children}
    </div>
  );
}
