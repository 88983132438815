import { ButtonUnstyled } from "@mui/base";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { Colors } from "../Colors";
import { useGlobals } from "../contexts/useGlobals";
import { usePostState } from "../helpers/usePostState";
import {
  FaChevronCircleDown,
  FaEnvelope,
  FaHospital,
  FaPlusCircle,
  FaRegHospital,
} from "react-icons/fa";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Dialog,
} from "@mui/material";
import { MdClose } from "react-icons/md";
import { useGetState } from "../helpers/useGetState";
import { Oval } from "react-loader-spinner";

export default function AdminManagerList() {
  const navigate = useNavigate();
  const globalContext = useGlobals();

  const [data, _, reloadData] = usePostState(
    globalContext.api.adminManagerListingView,
    globalContext.callOptions(),
    {},
    {}
  );

  const [facilities] = useGetState(
    globalContext.api.getAllFacilities,
    globalContext.callOptions(),
    {}
  );

  const [facility, setFacility] = useState<string>("");

  const [loading, setLoading] = useState<boolean>(false);

  const [response, setResponse] = useState<
    undefined | { type: "Success" | "Error"; message: string }
  >(undefined);

  const [modalData, setModalData] = useState<
    undefined | { managerId: string; managerName: string }
  >(undefined);

  function validateFacility(): boolean {
    if (facility.length === 0) {
      return false;
    }
    return true;
  }

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-start",
        flexDirection: "column",
        flex: 1,
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "flex-start",
          flexDirection: "column",
          marginBottom: 24,
        }}
      >
        <h1
          style={{
            margin: 0,
            fontSize: 32,
            marginBottom: 12,
            fontWeight: "700",
          }}
        >
          Facility Manager List
        </h1>
        <p style={{ color: Colors.lightGray }}>
          A list of all facility managers in Pagerr.
        </p>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 12,
          maxWidth: 620,
        }}
      >
        {data?.managers?.map(function (m) {
          return (
            <Accordion
              key={m._id}
              className={"jobCard"}
              style={{
                padding: 0,
                display: "flex",
                flexDirection: "column",
                flex: 1,
                border: "1px solid #eaeaea",
                borderRadius: 8,
              }}
              sx={{
                "&:before": {
                  display: "none",
                },
                boxShadow: "2px 2px 4px rgba(0,0,0,0.05)",
                "&:hover": {
                  boxShadow: "2px 2px 4px rgba(0,0,0,0.1)",
                },
              }}
              disableGutters={true}
            >
              <AccordionSummary
                style={{
                  display: "flex",
                  flex: 1,
                  background: "transparent",
                }}
                expandIcon={
                  <FaChevronCircleDown color={Colors.lightGray} size={16} />
                }
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    flex: 1,
                    marginRight: 8,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        height: 32,
                        width: 32,
                        borderRadius: 80,
                        background: Colors.border,
                        display: "flex",
                        flexShrink: 0,
                        alignItems: "center",
                        justifyContent: "center",
                        fontWeight: 700,
                        marginRight: 8,
                        color: Colors.blue,
                      }}
                    >
                      {m.firstName.charAt(0).toUpperCase()}
                      {m.lastName.charAt(0).toUpperCase()}
                    </div>
                    <p style={{ fontWeight: 400, fontSize: 18 }}>
                      {m.firstName} {m.lastName}
                    </p>
                  </div>
                </div>
              </AccordionSummary>
              <AccordionDetails
                style={{
                  display: "flex",
                  flex: 1,
                  background: "transparent",
                  flexDirection: "column",
                  gap: 12,
                }}
              >
                <div style={{ display: "flex", flexDirection: "row", gap: 12 }}>
                  <FaEnvelope color={Colors.blue} />
                  <p style={{ fontWeight: 600, fontSize: 14 }}>{m.email}</p>
                </div>
                <div
                  style={{ display: "flex", flexDirection: "column", gap: 8 }}
                >
                  <div
                    style={{ display: "flex", flexDirection: "row", gap: 12 }}
                  >
                    <FaRegHospital color={Colors.blue} />
                    <p style={{ fontWeight: 600, fontSize: 14 }}>
                      This person manages {m.facilities.length} facilities
                    </p>
                  </div>
                  <div
                    style={{
                      marginLeft: 24,
                      display: "flex",
                      flexDirection: "column",
                      gap: 8,
                    }}
                  >
                    {(m.facilities ?? []).map(function (f, index) {
                      return (
                        <div
                          key={index}
                          style={{
                            padding: 12,
                            borderRadius: 4,
                            fontWeight: 700,
                            background: Colors.border,
                            flexDirection: "row",
                            display: "flex",
                            alignItems: "flex-start",
                            justifyContent: "space-between",
                            gap: 8,
                            flexWrap: "wrap",
                          }}
                        >
                          <div
                            style={{
                              flexDirection: "row",
                              display: "flex",
                              gap: 8,
                            }}
                          >
                            <FaHospital />
                            <div
                              style={{
                                flexDirection: "column",
                                display: "flex",
                                alignItems: "flex-start",
                              }}
                            >
                              <p>{f.name}</p>
                              <p style={{ fontSize: 14, fontWeight: 500 }}>
                                {f.address.address1}
                                <br />
                                {f.address.address2 ?? (
                                  <>
                                    {f.address.address2}
                                    <br />
                                  </>
                                )}
                                {f.address.city},{f.address.state}&nbsp;
                                {f.address.zip}
                              </p>
                            </div>
                          </div>
                          <ButtonUnstyled
                            onClick={() => {
                              globalContext.api.removeFacilityManager
                                .call(
                                  globalContext.callOptions(),
                                  {},
                                  {
                                    facilityId: f._id,
                                    managerId: m._id,
                                  }
                                )
                                .then((result) => {
                                  if (result.status === 200) {
                                    alert("Removed facility manager");
                                    reloadData();
                                  }
                                });
                            }}
                          >
                            Remove facility
                          </ButtonUnstyled>
                        </div>
                      );
                    })}
                    <ButtonUnstyled
                      onClick={() =>
                        setModalData({
                          managerId: m._id,
                          managerName: `${m.firstName} ${m.lastName}`,
                        })
                      }
                      style={{
                        padding: 4,
                        paddingInline: 12,
                        borderRadius: 4,
                        fontWeight: 600,
                        background: Colors.blueBrightPrimary,
                        border: `1px solid ${Colors.blue}`,
                        color: Colors.blue,
                        flexDirection: "row",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: 20,
                        fontSize: 16,
                        cursor: "pointer",
                      }}
                    >
                      Add Another Facility
                      <FaPlusCircle />
                    </ButtonUnstyled>
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
          );
        })}
      </div>
      <Dialog
        open={!!modalData}
        fullWidth
        onClose={() => {
          setFacility("");
          setModalData(undefined);
          setLoading(false);
        }}
      >
        <div
          style={{
            padding: 20,
            background: "#fff",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <MdClose
            style={{ alignSelf: "flex-end", cursor: "pointer" }}
            onClick={() => setModalData(undefined)}
          />
          <p style={{ fontSize: 16, fontWeight: 700 }}>Add Facility Manager</p>
          <p style={{ fontSize: 16, fontWeight: 500, marginBottom: 12 }}>
            This will add {modalData?.managerName} to the facility you select.
          </p>
          <label>
            <b>Provider</b>
            <select
              style={{
                fontSize: 16,
                padding: 8,
                flex: 1,
              }}
              value={facility}
              onChange={(e) => {
                setFacility(e.currentTarget.value);
              }}
              disabled={loading}
            >
              <option disabled label="Please select a facility..." value="">
                Please select a facility...
              </option>
              {facilities?.facilities.map(function (p) {
                return (
                  <option key={p.id} label={`${p.name}`} value={p.id}>
                    `${p.name}`
                  </option>
                );
              })}
            </select>
          </label>
          {loading === true ? (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                padding: 12,
              }}
            >
              <Oval
                color={Colors.blue}
                secondaryColor={Colors.blue}
                height={20}
                strokeWidth={8}
              />
            </div>
          ) : (
            <ButtonUnstyled
              style={{
                cursor: validateFacility() === true ? "pointer" : undefined,
                borderRadius: 4,
                paddingBlock: 6,
                paddingInline: 20,
                fontWeight: 600,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                background: Colors.border,
                color: validateFacility() === true ? Colors.blue : "lightgray",
                border:
                  validateFacility() === true
                    ? `1px solid ${Colors.blue}`
                    : `1px solid ${Colors.border}`,
                boxShadow: "2px 2px 4px rgba(0,0,0,0.2)",
                alignSelf: "flex-end",
                marginTop: 12,
              }}
              disabled={!validateFacility()}
              onClick={() => {
                setLoading(true);

                globalContext.api.addFacilityManager
                  .call(
                    globalContext.callOptions(),
                    {},
                    {
                      facilityId: facility,
                      managerId: modalData!.managerId,
                    }
                  )
                  .then((result) => {
                    if (result.status === 200) {
                      setResponse({
                        type: "Success",
                        message: "This facility manager has been added.",
                      });
                      setLoading(false);
                      setModalData(undefined);

                      reloadData();
                    } else {
                      setResponse({
                        type: "Error",
                        message: result.error ?? "Unknown error.",
                      });
                      setLoading(false);
                      setModalData(undefined);
                    }
                  });
              }}
            >
              Add Provider To This Facility
            </ButtonUnstyled>
          )}
        </div>
      </Dialog>
      <Dialog
        open={!!response}
        fullWidth
        onClose={() => setResponse(undefined)}
      >
        <div
          style={{
            padding: 20,
            background: "#fff",
            display: "flex",
            flexDirection: "column",
            gap: 12,
          }}
        >
          <p style={{ fontSize: 16, fontWeight: 700 }}>{response?.type}</p>
          <p style={{ fontSize: 16, fontWeight: 500 }}>{response?.message}</p>

          <ButtonUnstyled
            style={{
              cursor: "pointer",
              borderRadius: 4,
              paddingBlock: 6,
              paddingInline: 20,
              fontWeight: 600,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              background:
                response?.type === "Success" ? Colors.green : Colors.orange,
              color: "#fff",
              border: "none",
              alignSelf: "flex-end",
            }}
            onClick={() => {
              setResponse(undefined);
            }}
          >
            Okay
          </ButtonUnstyled>
        </div>
      </Dialog>
    </div>
  );
}
