import { ButtonUnstyled } from "@mui/base";
import "../App.css";
import { useContext, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Dialog,
  Link,
} from "@mui/material";
import {
  MdChevronRight,
  MdClose,
  MdLocationPin,
  MdSchedule,
} from "react-icons/md";
import {
  FaCheckCircle,
  FaChevronCircleDown,
  FaChevronRight,
  FaExclamationCircle,
  FaStopCircle,
} from "react-icons/fa";
import { Colors } from "../Colors";
import moment from "moment";
import { GlobalStateContext } from "../contexts/GlobalStateContext";
import { usePostState } from "../helpers/usePostState";

export default function ProviderPastJobs() {
  const globalContext = useContext(GlobalStateContext);

  const [cancelVisible, setCancelVisible] = useState(false);
  const [cancelDate, setCancelDate] = useState<Date>(new Date());

  const [jobs] = usePostState(
    globalContext.api.myJobsListingView,
    globalContext.callOptions(),
    {},
    {
      filter: {
        state: "historical",
      },
      pagination: {
        pageSize: 1000000,
        page: 1,
      },
      sort: { sortBy: "Date / Time", sortOrder: "desc" },
    }
  );

  return (
    <>
      <Dialog
        open={cancelVisible}
        onClose={() => setCancelVisible(!cancelVisible)}
        disableScrollLock
      >
        <div
          style={{
            background: "#fff",
            display: "flex",
            flexDirection: "column",
            borderRadius: 20,
            maxWidth: 520,
            padding: 20,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: 12,
            }}
          >
            <p style={{ fontSize: 20, fontWeight: 700, margin: 0 }}>
              Are you sure you want to cancel?
            </p>
            <MdClose
              size={24}
              onClick={() => setCancelVisible(false)}
              style={{ cursor: "pointer" }}
            />
          </div>
          <div>
            <p style={{ fontSize: 14, fontWeight: 400 }}>
              Are you sure you would like to cancel this job? This action cannot
              be undone.
              <br />
              <br />
              Since the job starts in&nbsp;
              {(
                moment(cancelDate).diff(moment(new Date())) /
                (3600000 * 24)
              ).toFixed(0)}
              &nbsp;days, you will be charged $[x] for cancelling on short
              notice. Please reach out to the Pagerr Team if you have any
              questions.
            </p>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignSelf: "flex-end",
              marginTop: 12,
            }}
          >
            <ButtonUnstyled
              style={{
                cursor: "pointer",
                margin: 4,
                border: "0px",
                borderRadius: 4,
                paddingBlock: 6,
                paddingInline: 8,
                background: "#ffffff",
                color: "gray",
                fontWeight: 600,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
              }}
              onClick={() => setCancelVisible(!cancelVisible)}
            >
              <p>GO BACK</p>
            </ButtonUnstyled>
            <ButtonUnstyled
              style={{
                cursor: "pointer",
                margin: 4,
                border: `1px solid ${Colors.blue}`,
                borderRadius: 4,
                paddingBlock: 6,
                paddingInline: 8,
                background: Colors.border,
                color: Colors.blue,
                fontWeight: 600,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                boxShadow: "2px 2px 4px rgba(0,0,0,0.2)",
              }}
              onClick={() => {
                setCancelVisible(false);
              }}
            >
              <p>CONFIRM CANCEL</p>
            </ButtonUnstyled>
          </div>
        </div>
      </Dialog>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          flexDirection: "column",
          flex: 1,
          marginBottom: 320,
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "flex-start",
            flexDirection: "column",
            marginBottom: 24,
          }}
        >
          <h1
            style={{
              margin: 0,
              fontSize: 32,
              marginBottom: 12,
              fontWeight: "700",
            }}
          >
            Job History
          </h1>
          <p style={{ color: Colors.lightGray }}>
            These are jobs you have completed, or that were cancelled by a
            facility.
          </p>
        </div>

        {globalContext.sessionInfo.value?.provider?.status ===
        "approved" ? null : (
          <div
            style={{
              padding: 20,
              background: Colors.border,
              border: `1px solid ${Colors.blue}`,
              color: Colors.blue,
              fontWeight: 600,
              borderRadius: 8,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginBottom: 8,
            }}
          >
            <FaExclamationCircle
              style={{
                color: Colors.blue,
                marginRight: 12,
                flexShrink: 0,
              }}
              size={24}
            />
            <p>
              Welcome to Pagerr! We're still validating your account, so for now
              you won't be able to claim shifts. Expect an email from us once
              your account has been validated.
            </p>
          </div>
        )}

        <div>
          {globalContext.sessionInfo.value?.provider?.status === "pending" ? (
            <></>
          ) : jobs?.jobs.length === 0 ? (
            <div
              style={{
                padding: 20,
                background: Colors.border,
                border: `1px solid ${Colors.blue}`,
                color: Colors.blue,
                fontWeight: 600,
                borderRadius: 8,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <FaExclamationCircle
                size={24}
                style={{ marginRight: 12, flexShrink: 0 }}
              />
              <p>
                Looks like you haven't completed any jobs through Pagerr yet.
                Head over and&nbsp;
                <Link
                  style={{ color: Colors.blue, cursor: "pointer" }}
                  href={"/provider/listings"}
                >
                  see what jobs are available!
                  <MdChevronRight size={12} style={{ marginLeft: 8 }} />
                </Link>
              </p>
            </div>
          ) : (
            <></>
          )}
          <div style={{ display: "flex", flexDirection: "column", gap: 12 }}>
            {jobs?.jobs.map(function (item) {
              return (
                <Accordion
                  key={item._id}
                  className={"jobCard"}
                  style={{
                    padding: 0,
                    display: "flex",
                    flexDirection: "column",
                    flex: 1,
                    border: "1px solid #eaeaea",
                    borderRadius: 8,
                  }}
                  sx={{
                    "&:before": {
                      display: "none",
                    },
                    boxShadow: "2px 2px 4px rgba(0,0,0,0.05)",
                    "&:hover": {
                      boxShadow: "2px 2px 4px rgba(0,0,0,0.1)",
                    },
                  }}
                  disableGutters={true}
                >
                  <AccordionSummary
                    style={{
                      display: "flex",
                      flex: 1,
                      background: "transparent",
                      color:
                        new Date() > new Date(item.endDate)
                          ? "gray"
                          : Colors.blue,
                    }}
                    expandIcon={
                      <FaChevronCircleDown color={Colors.lightGray} size={16} />
                    }
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                        flex: 1,
                        marginRight: 8,
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        {/* {JSON.stringify(item.cancelledDate)} */}
                        {item.cancelledDate ? (
                          <FaStopCircle color={Colors.red} size={24} />
                        ) : (
                          <FaCheckCircle color={Colors.green} size={24} />
                        )}
                        <p
                          style={{
                            marginRight: 0,
                            marginBlock: 0,
                            fontWeight: 700,
                            marginLeft: 8,
                          }}
                        >
                          {new Date(item.startDate).toLocaleString("en-US", {
                            month: "long",
                            day: "numeric",
                          })}
                          &nbsp;at&nbsp;
                          {item.facilityName}
                        </p>
                      </div>
                      {item.cancelledDate ? (
                        <div
                          style={{
                            background: Colors.red,

                            paddingBlock: 4,
                            paddingInline: 12,
                            borderRadius: 40,
                            margin: 2,
                          }}
                        >
                          <p
                            style={{
                              margin: 0,
                              fontWeight: 700,
                              fontSize: 16,
                              color: "#fff",
                            }}
                          >
                            Cancelled
                          </p>
                        </div>
                      ) : (
                        <div
                          style={{
                            background: Colors.border,
                            paddingBlock: 4,
                            paddingInline: 12,
                            borderRadius: 40,
                            margin: 2,
                          }}
                        >
                          <p
                            style={{
                              margin: 0,
                              fontWeight: 700,
                              fontSize: 16,
                            }}
                          >
                            {globalContext.sessionInfo.value?.provider
                              .providerType === "CRNA"
                              ? item.crnaRate === 0
                                ? "W2 Rate"
                                : `$${(item.crnaRate ?? 0 / 100).toFixed(
                                    0
                                  )}/hr)`
                              : null}
                            {globalContext.sessionInfo.value?.provider
                              .providerType === "MD/DO"
                              ? item.mddoRate === 0
                                ? "W2 Rate"
                                : `$${((item.mddoRate ?? 0) / 100).toFixed(
                                    0
                                  )}/hr`
                              : null}
                          </p>
                        </div>
                      )}
                    </div>
                  </AccordionSummary>
                  <AccordionDetails
                    style={{
                      display: "flex",
                      flex: 1,
                      background: "transparent",
                      flexDirection: "column",
                      gap: 12,
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "space-between",
                        marginTop: 4,
                        flexWrap: "wrap",
                        gap: 4,
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          background: Colors.blue,
                          color: "#fff",
                          paddingBlock: 4,
                          paddingInline: 8,
                          borderRadius: 4,
                        }}
                      >
                        <MdSchedule
                          size={18}
                          style={{ marginRight: 4, flexShrink: 0 }}
                        />
                        <p style={{ fontSize: 16, fontWeight: 500 }}>
                          {item.startTime}
                          {" to "}
                          {item.endTime}
                        </p>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          background: Colors.blue,
                          color: "#fff",
                          paddingBlock: 4,
                          paddingInline: 8,
                          borderRadius: 4,
                        }}
                      >
                        <MdLocationPin
                          size={18}
                          style={{ marginRight: 4, flexShrink: 0 }}
                        />
                        <p style={{ fontSize: 16, fontWeight: 500 }}>
                          {item.facilityAddress.address1}
                          &nbsp;
                          {item.facilityAddress.address2},{" "}
                          {item.facilityAddress.city},{" "}
                          {item.facilityAddress.state}{" "}
                          {item.facilityAddress.zip}
                        </p>
                      </div>
                    </div>
                    <div style={{ marginBottom: 12 }}>
                      <p
                        style={{
                          marginBottom: 4,
                          fontWeight: 700,
                          fontSize: 14,
                        }}
                      >
                        JOB DESCRIPTION
                      </p>
                      <p> {item.description}</p>
                    </div>
                    <div style={{ marginBottom: 12 }}>
                      <p
                        style={{
                          marginBottom: 4,
                          fontWeight: 700,
                          fontSize: 14,
                        }}
                      >
                        SPECIALTY REQUIREMENTS
                      </p>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          flexWrap: "wrap",
                          gap: 4,
                        }}
                      >
                        {item.specialties.map(function (item) {
                          return (
                            <div
                              key={item.specialty}
                              style={{
                                background: "#eaeaea",
                                paddingBlock: 4,
                                paddingInline: 12,
                                borderRadius: 12,
                              }}
                            >
                              <p style={{ fontWeight: 500, maxLines: 1 }}>
                                {item.specialty.charAt(0).toUpperCase()}
                                {item.specialty.slice(1)}
                              </p>
                            </div>
                          );
                        })}
                      </div>
                      {new Date() > new Date(item.endDate) ? null : (
                        <div
                          style={{
                            display: "flex",
                            flex: 1,
                            justifyContent: "flex-end",
                            marginTop: 8,
                          }}
                        >
                          <ButtonUnstyled
                            style={{
                              cursor: "pointer",
                              border: `1px solid ${Colors.blue}`,
                              borderRadius: 4,
                              paddingBlock: 6,
                              paddingInline: 8,
                              background: Colors.border,
                              color: Colors.blue,
                              fontWeight: 600,
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              justifyContent: "space-between",

                              boxShadow: "2px 2px 4px rgba(0,0,0,0.2)",
                            }}
                            onClick={() => {
                              setCancelDate(new Date(item.startDate));
                              setCancelVisible(true);
                            }}
                          >
                            CANCEL JOB
                            <FaChevronRight
                              style={{ marginLeft: 20 }}
                              size={10}
                            />
                          </ButtonUnstyled>
                        </div>
                      )}
                    </div>
                  </AccordionDetails>
                </Accordion>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
}
