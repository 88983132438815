import { ButtonUnstyled } from "@mui/base";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { Colors } from "../Colors";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Dialog,
} from "@mui/material";
import {
  FaChevronCircleDown,
  FaExclamationCircle,
  FaPhone,
  FaPlusCircle,
  FaUserCircle,
} from "react-icons/fa";
import { MdClose, MdLocationPin } from "react-icons/md";
import { usePostState } from "../helpers/usePostState";
import { useGlobals } from "../contexts/useGlobals";

export default function AdminFacilityList() {
  const navigate = useNavigate();
  const globalContext = useGlobals();

  const [modalData, setModalData] = useState<string>("");

  const [data, _, reloadData] = usePostState(
    globalContext.api.adminFacilityListingView,
    globalContext.callOptions(),
    {},
    {}
  );

  const [providers] = usePostState(
    globalContext.api.adminProviderListingView,
    globalContext.callOptions(),
    {},
    {}
  );

  const [successVisible, setSuccessVisible] = useState<boolean>(false);
  const credentialedStatuses = ["pending", "approved", "rejected", "cancelled"];
  const employmentTypes = ["w2", "1099"];

  const [provider, setProvider] = useState<string>("");
  const [credential, setCredential] = useState<string>("");
  const [employmentType, setEmploymentType] = useState<string>("");

  function validateProvider(): string {
    if (provider === "") {
      return "Please select a provider.";
    }
    return "";
  }

  function validateEmploymentTypes(): string {
    if (employmentType === "") {
      return "Please select an employment type.";
    }
    return "";
  }

  function validateCredential(): string {
    if (credential === "") {
      return "Please select a credentialed status.";
    }
    return "";
  }

  function validate() {
    return !(
      !!validateProvider() ||
      !!validateEmploymentTypes() ||
      !!validateCredential()
    );
  }

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-start",
        flexDirection: "column",
        flex: 1,
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "flex-start",
          flexDirection: "column",
          marginBottom: 24,
        }}
      >
        <h1
          style={{
            margin: 0,
            fontSize: 32,
            marginBottom: 12,
            fontWeight: "700",
          }}
        >
          Facility List
        </h1>
        <p style={{ color: Colors.lightGray }}>
          A list of all facilities in Pagerr.
        </p>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 12,
          maxWidth: 620,
        }}
      >
        {data?.facilities?.map(function (f) {
          return (
            <Accordion
              key={f._id}
              className={"jobCard"}
              style={{
                padding: 0,
                display: "flex",
                flexDirection: "column",
                flex: 1,
                border: "1px solid #eaeaea",
                borderRadius: 8,
              }}
              sx={{
                "&:before": {
                  display: "none",
                },
                boxShadow: "2px 2px 4px rgba(0,0,0,0.05)",
                "&:hover": {
                  boxShadow: "2px 2px 4px rgba(0,0,0,0.1)",
                },
              }}
              disableGutters={true}
            >
              <AccordionSummary
                style={{
                  display: "flex",
                  flex: 1,
                  background: "transparent",
                }}
                expandIcon={
                  <FaChevronCircleDown color={Colors.lightGray} size={16} />
                }
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    flex: 1,
                    marginRight: 8,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        height: 32,
                        width: 32,
                        borderRadius: 80,
                        background: Colors.border,
                        display: "flex",
                        flexShrink: 0,
                        alignItems: "center",
                        justifyContent: "center",
                        fontWeight: 700,
                        marginRight: 8,
                        color: Colors.blue,
                      }}
                    >
                      {f.name.charAt(0).toUpperCase()}
                    </div>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <p style={{ fontWeight: 500, fontSize: 18 }}>{f.name}</p>
                      <p style={{ fontWeight: 400, fontSize: 13 }}>
                        {f.address.city}, {f.address.state}
                      </p>
                    </div>
                  </div>
                </div>
              </AccordionSummary>
              <AccordionDetails
                style={{
                  display: "flex",
                  flex: 1,
                  background: "transparent",
                  flexDirection: "column",
                  gap: 12,
                }}
              >
                <div style={{ display: "flex", flexDirection: "row", gap: 12 }}>
                  <FaPhone color={Colors.blue} />
                  <p style={{ fontWeight: 600, fontSize: 14 }}>
                    ({f.phone.slice(0, 3)})&nbsp;{f.phone.slice(3, 6)}-
                    {f.phone.slice(6, 10)}
                  </p>
                </div>
                <div style={{ display: "flex", flexDirection: "row", gap: 12 }}>
                  <MdLocationPin color={Colors.blue} />
                  <p style={{ fontWeight: 600, fontSize: 14 }}>
                    {f.address.address1},
                    {f.address.address2 ?? ` ${f.address.address2},`}&nbsp;
                    {f.address.city},&nbsp;
                    {f.address.state}
                    ,&nbsp;
                    {f.address.zip}
                  </p>
                </div>
                <div
                  style={{ display: "flex", flexDirection: "column", gap: 8 }}
                >
                  <div
                    style={{ display: "flex", flexDirection: "row", gap: 12 }}
                  >
                    <FaUserCircle color={Colors.blue} />
                    <p style={{ fontWeight: 600, fontSize: 14 }}>
                      {f.providers.length} credentialed Pagerr providers at this
                      facility
                    </p>
                  </div>
                  <div
                    style={{
                      marginLeft: 24,
                      display: "flex",
                      flexDirection: "column",
                      gap: 8,
                      alignItems: "flex-start",
                    }}
                  >
                    {(f.providers ?? []).map(function (p, index) {
                      return (
                        <div
                          key={index}
                          style={{
                            border:
                              p.credentialingStatus === "approved"
                                ? `1px solid ${Colors.green}`
                                : `1px solid ${Colors.orange}`,
                            padding: 4,
                            paddingInline: 12,
                            borderRadius: 20,
                            fontWeight: 600,
                            background:
                              p.credentialingStatus === "approved"
                                ? Colors.green
                                : Colors.orange,
                            color: "#fff",
                            flexDirection: "row",
                            display: "flex",
                            alignItems: "center",
                            gap: 20,
                          }}
                        >
                          <p>
                            {p.firstName}&nbsp;{p.lastName}, {p.providerType}
                          </p>
                          {p.credentialingStatus === "approved" ? null : (
                            <FaExclamationCircle />
                          )}
                        </div>
                      );
                    })}
                    <ButtonUnstyled
                      style={{
                        padding: 4,
                        paddingInline: 12,
                        borderRadius: 20,
                        fontWeight: 600,
                        background: Colors.blueBrightPrimary,
                        border: `1px solid ${Colors.blue}`,
                        color: Colors.blue,
                        flexDirection: "row",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: 20,
                        fontSize: 16,
                        cursor: "pointer",
                      }}
                      onClick={() => setModalData(f._id)}
                    >
                      Add Another Provider
                      <FaPlusCircle />
                    </ButtonUnstyled>
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
          );
        })}
      </div>
      <Dialog
        open={!!modalData}
        onClose={() => {
          setModalData("");
          setModalData("");
          setCredential("");
          setProvider("");
          setEmploymentType("");
        }}
      >
        <div
          style={{
            padding: 20,
            background: "#fff",
            display: "flex",
            flexDirection: "column",
            gap: 20,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
            }}
          >
            <div>
              <p style={{ fontSize: 16, fontWeight: 700 }}>
                Add Facility Credentials
              </p>
              <p style={{ fontSize: 16, fontWeight: 500, marginBottom: 12 }}>
                This will add this provider as a credentialed provider at the
                facility you select.
              </p>
            </div>
          </div>
          <label>
            <b>Provider</b>
            <select
              style={{
                fontSize: 16,
                padding: 8,
                flex: 1,
              }}
              value={provider}
              onChange={(e) => {
                setProvider(e.currentTarget.value);
              }}
            >
              <option disabled label="Please select a provider..." value="">
                Please select a provider...
              </option>
              {providers?.providers.map(function (p) {
                return (
                  <option
                    key={p._id}
                    label={`${p.firstName} ${p.lastName}, ${p.providerType} (${p.email})`}
                    value={p._id}
                  >
                    {p.firstName} {p.lastName}, ${p.providerType} ({p.email})
                  </option>
                );
              })}
            </select>
            {validateProvider()}
          </label>
          <label>
            <b>Employment Type</b>
            <select
              style={{
                fontSize: 16,
                padding: 8,
                flex: 1,
              }}
              value={employmentType}
              onChange={(e) => setEmploymentType(e.currentTarget.value)}
            >
              <option disabled label="" value="">
                Please select a status...
              </option>
              {employmentTypes.map(function (status) {
                return (
                  <option
                    key={status}
                    label={`${status.charAt(0).toUpperCase()}${status.slice(
                      1
                    )}`}
                    value={status}
                  >
                    {status.charAt(0).toUpperCase()}
                    {status.slice(1)}
                  </option>
                );
              })}
            </select>
            {validateEmploymentTypes()}
          </label>
          <label>
            <b>Credentialed Status</b>
            <select
              style={{
                fontSize: 16,
                padding: 8,
                flex: 1,
              }}
              value={credential}
              onChange={(e) => setCredential(e.currentTarget.value)}
            >
              <option disabled label="" value="">
                Please select a status...
              </option>
              {credentialedStatuses.map(function (status) {
                return (
                  <option
                    key={status}
                    label={`${status.charAt(0).toUpperCase()}${status.slice(
                      1
                    )}`}
                    value={status}
                  >
                    {status.charAt(0).toUpperCase()}
                    {status.slice(1)}
                  </option>
                );
              })}
            </select>
            {validateCredential()}
          </label>
          <ButtonUnstyled
            style={{
              cursor: validate() === true ? "pointer" : undefined,
              borderRadius: 4,
              paddingBlock: 6,
              paddingInline: 20,
              fontWeight: 600,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              background: Colors.border,
              color: validate() === true ? Colors.blue : "lightgray",
              border:
                validate() === true
                  ? `1px solid ${Colors.blue}`
                  : `1px solid ${Colors.border}`,
              boxShadow: "2px 2px 4px rgba(0,0,0,0.2)",
              alignSelf: "flex-end",
              marginTop: 12,
            }}
            disabled={!validate()}
            onClick={() => {
              globalContext.api.upsertCredentialing.call(
                globalContext.callOptions(),
                {},
                {
                  facilityId: modalData,
                  providerId: provider,
                  status: credential,
                  employmentType: employmentType,
                }
              );
              reloadData();
              setSuccessVisible(true);
              setEmploymentType("");
              setModalData("");
            }}
          >
            Add Provider To This Facility
          </ButtonUnstyled>
        </div>
      </Dialog>

      <Dialog open={successVisible} onClose={() => setSuccessVisible(false)}>
        <div
          style={{
            padding: 20,
            background: "#fff",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <MdClose
            style={{ alignSelf: "flex-end", cursor: "pointer" }}
            onClick={() => setSuccessVisible(false)}
          />
          <p style={{ fontSize: 16, fontWeight: 700 }}>Success</p>
          <p style={{ fontSize: 12, fontWeight: 500, marginBottom: 12 }}>
            A new credential has been created.
          </p>
          <ButtonUnstyled
            style={{
              cursor: "pointer",
              borderRadius: 4,
              paddingBlock: 6,
              paddingInline: 8,
              fontWeight: 600,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              background: Colors.border,
              color: Colors.blue,
              border: `1px solid ${Colors.blue}`,
              boxShadow: "2px 2px 4px rgba(0,0,0,0.2)",
              alignSelf: "flex-end",
              marginTop: 12,
            }}
            onClick={() => {
              setSuccessVisible(false);
            }}
          >
            Okay
          </ButtonUnstyled>
        </div>
      </Dialog>
    </div>
  );
}
