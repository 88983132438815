import { Link } from "@mui/material";
import { MdAddCircleOutline } from "react-icons/md";
import { useLocation, useNavigate } from "react-router-dom";
import { Colors } from "../Colors";
import "../App.css";
import { FaBuilding, FaUserCog, FaUsers } from "react-icons/fa";

export default function AdminMenu() {
  return (
    <>
      <div
        style={{
          background: "#FBFBFD",
          paddingInline: 30,
          minHeight: "100vh",
          zIndex: 1,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <MenuHeader label="Providers" />
          <MenuLink
            title={"Provider List"}
            link="providers"
            icon={<FaUsers />}
          />
          <MenuLink
            title={"Add Provider"}
            link="addprovider"
            icon={<MdAddCircleOutline />}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <MenuHeader label="Facilities" />
          <MenuLink
            title={"Facility List"}
            icon={<FaBuilding />}
            link="facilities"
          />
          <MenuLink
            title={"Add Facility"}
            icon={<MdAddCircleOutline />}
            link="addfacility"
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <MenuHeader label="Facility Managers" />
          <MenuLink
            title={"Manager List"}
            icon={<FaUserCog />}
            link="managers"
          />
          <MenuLink
            title={"Add Manager"}
            icon={<MdAddCircleOutline />}
            link="addmanager"
          />
        </div>
      </div>
    </>
  );
}

function MenuHeader(props: { label: string }) {
  return (
    <p
      style={{
        margin: 0,
        marginTop: 28,
        fontWeight: 700,
        fontSize: 14,
        lineHeight: "18px",
        color: Colors.lightGray,
      }}
    >
      {props.label}
    </p>
  );
}

function MenuLink(props: { title: string; link?: string; icon: any }) {
  const location = useLocation();
  const path = location.pathname;
  const navigate = useNavigate();

  return (
    <Link
      onClick={
        props.link === undefined ? undefined : () => navigate(`./${props.link}`)
      }
      underline={"none"}
      component="button"
      style={{
        display: "flex",
        flex: 1,
        padding: 12,
        marginBlock: 8,
        background:
          path === `/admin/${props.link}`
            ? `${Colors.blueBrightPrimary}`
            : "transparent",
        fontWeight: 400,
        borderRadius: 12,
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "flex-start",
        color:
          props.link === undefined
            ? "rgba(102, 112, 133, 0.4)"
            : path === `/admin/${props.link}`
            ? Colors.blue
            : Colors.lightGray,
        fontSize: 16,
        cursor: props.link === undefined ? "default" : "pointer",
      }}
    >
      {props.icon}
      <p style={{ marginLeft: 12 }} className="p.link">
        {props.title}
      </p>
    </Link>
  );
}
