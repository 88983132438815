import { useContext, useEffect, useState } from "react";
import { Colors } from "../Colors";
import { ButtonUnstyled } from "@mui/base";
import { MdArrowBack } from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";
import { useGetState } from "../helpers/useGetState";
import { GlobalStateContext } from "../contexts/GlobalStateContext";

export default function FacilityEditFacility() {
  const navigate = useNavigate();
  const params = useParams();
  const globalContext = useContext(GlobalStateContext);

  const facilityId = String(params.facilityId);

  const [facility, setFacility] = useGetState(
    globalContext.api.getFacility,
    globalContext.callOptions(),
    {
      facilityId: facilityId,
    }
  );

  const states = [
    {
      name: "Alabama",
      abbreviation: "AL",
    },
    {
      name: "Alaska",
      abbreviation: "AK",
    },
    {
      name: "American Samoa",
      abbreviation: "AS",
    },
    {
      name: "Arizona",
      abbreviation: "AZ",
    },
    {
      name: "Arkansas",
      abbreviation: "AR",
    },
    {
      name: "California",
      abbreviation: "CA",
    },
    {
      name: "Colorado",
      abbreviation: "CO",
    },
    {
      name: "Connecticut",
      abbreviation: "CT",
    },
    {
      name: "Delaware",
      abbreviation: "DE",
    },
    {
      name: "District Of Columbia",
      abbreviation: "DC",
    },
    {
      name: "Federated States Of Micronesia",
      abbreviation: "FM",
    },
    {
      name: "Florida",
      abbreviation: "FL",
    },
    {
      name: "Georgia",
      abbreviation: "GA",
    },
    {
      name: "Guam",
      abbreviation: "GU",
    },
    {
      name: "Hawaii",
      abbreviation: "HI",
    },
    {
      name: "Idaho",
      abbreviation: "ID",
    },
    {
      name: "Illinois",
      abbreviation: "IL",
    },
    {
      name: "Indiana",
      abbreviation: "IN",
    },
    {
      name: "Iowa",
      abbreviation: "IA",
    },
    {
      name: "Kansas",
      abbreviation: "KS",
    },
    {
      name: "Kentucky",
      abbreviation: "KY",
    },
    {
      name: "Louisiana",
      abbreviation: "LA",
    },
    {
      name: "Maine",
      abbreviation: "ME",
    },
    {
      name: "Marshall Islands",
      abbreviation: "MH",
    },
    {
      name: "Maryland",
      abbreviation: "MD",
    },
    {
      name: "Massachusetts",
      abbreviation: "MA",
    },
    {
      name: "Michigan",
      abbreviation: "MI",
    },
    {
      name: "Minnesota",
      abbreviation: "MN",
    },
    {
      name: "Mississippi",
      abbreviation: "MS",
    },
    {
      name: "Missouri",
      abbreviation: "MO",
    },
    {
      name: "Montana",
      abbreviation: "MT",
    },
    {
      name: "Nebraska",
      abbreviation: "NE",
    },
    {
      name: "Nevada",
      abbreviation: "NV",
    },
    {
      name: "New Hampshire",
      abbreviation: "NH",
    },
    {
      name: "New Jersey",
      abbreviation: "NJ",
    },
    {
      name: "New Mexico",
      abbreviation: "NM",
    },
    {
      name: "New York",
      abbreviation: "NY",
    },
    {
      name: "North Carolina",
      abbreviation: "NC",
    },
    {
      name: "North Dakota",
      abbreviation: "ND",
    },
    {
      name: "Northern Mariana Islands",
      abbreviation: "MP",
    },
    {
      name: "Ohio",
      abbreviation: "OH",
    },
    {
      name: "Oklahoma",
      abbreviation: "OK",
    },
    {
      name: "Oregon",
      abbreviation: "OR",
    },
    {
      name: "Palau",
      abbreviation: "PW",
    },
    {
      name: "Pennsylvania",
      abbreviation: "PA",
    },
    {
      name: "Puerto Rico",
      abbreviation: "PR",
    },
    {
      name: "Rhode Island",
      abbreviation: "RI",
    },
    {
      name: "South Carolina",
      abbreviation: "SC",
    },
    {
      name: "South Dakota",
      abbreviation: "SD",
    },
    {
      name: "Tennessee",
      abbreviation: "TN",
    },
    {
      name: "Texas",
      abbreviation: "TX",
    },
    {
      name: "Utah",
      abbreviation: "UT",
    },
    {
      name: "Vermont",
      abbreviation: "VT",
    },
    {
      name: "Virgin Islands",
      abbreviation: "VI",
    },
    {
      name: "Virginia",
      abbreviation: "VA",
    },
    {
      name: "Washington",
      abbreviation: "WA",
    },
    {
      name: "West Virginia",
      abbreviation: "WV",
    },
    {
      name: "Wisconsin",
      abbreviation: "WI",
    },
    {
      name: "Wyoming",
      abbreviation: "WY",
    },
  ];

  const [name, setName] = useState<string>(facility?.name ?? "");
  const [entranceProtocol, setEntranceProtocol] = useState<string>(
    facility?.entranceProtocol ?? ""
  );
  const [phone, setPhone] = useState<string>(facility?.phone ?? "");
  const [address1, setAddress1] = useState<string>(
    facility?.address?.address1 ?? ""
  );
  const [address2, setAddress2] = useState<string>(
    facility?.address?.address2 ?? ""
  );
  const [city, setCity] = useState<string>(facility?.address?.city ?? "");
  const [state, setState] = useState<string>(facility?.address?.state ?? "");
  const [zip, setZip] = useState<string>(facility?.address?.zip ?? "");

  useEffect(() => {
    if (facility) {
      setName(facility.name);
      setEntranceProtocol(facility.entranceProtocol);
      setPhone(facility.phone);
      setAddress1(facility.address.address1);
      setAddress2(facility.address.address2);
      setCity(facility.address.city);
      setState(facility.address.state);
      setZip(facility.address.zip);
    }
  }, [facility]);

  function validateName(): string {
    if (name.length === 0) {
      return "Name is required.";
    }
    return "";
  }

  function validateEntranceProtocol(): string {
    if (entranceProtocol.length === 0) {
      return "Entrance protocol is required.";
    }
    return "";
  }

  function validatePhone(): string {
    if (phone.length === 10) {
      return "";
    }
    return "Please enter a valid phone number (including area code) with no dashes or spaces.";
  }

  function validateAddress1(): string {
    if (address1.length === 0) {
      return "Address is required.";
    }
    return "";
  }

  function validateCity(): string {
    if (city.length === 0) {
      return "City is required.";
    }
    return "";
  }

  function validateState(): string {
    if (state.length === 0) {
      return "State is required.";
    }
    return "";
  }

  function validateZip(): string {
    if (zip.length === 5) {
      return "";
    }
    return "A valid zip code is required.";
  }

  function validate() {
    return !(
      !!validateName() ||
      !!validateAddress1() ||
      !!validateCity() ||
      !!validateEntranceProtocol() ||
      !!validatePhone() ||
      !!validateState() ||
      !!validateZip()
    );
  }

  function changes() {
    if (!facility) {
      return false;
    }

    return !(
      name === facility.name &&
      entranceProtocol === facility.entranceProtocol &&
      phone === facility.phone &&
      address1 === facility.address.address1 &&
      address2 === facility.address.address2 &&
      city === facility.address.city &&
      state === facility.address.state &&
      zip === facility.address.zip
    );
  }

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-start",
        flexDirection: "column",
        flex: 1,
      }}
    >
      <div style={{ display: "flex", flexDirection: "row" }}>
        {/* <MdArrowBack
          size={32}
          style={{ flexShrink: 0, marginTop: 4, marginRight: 8 }}
        /> */}
        <div
          style={{
            display: "flex",
            alignItems: "flex-start",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
              marginBottom: 12,
            }}
          >
            <MdArrowBack
              size={24}
              style={{ cursor: "pointer" }}
              onClick={() => navigate("/facility/locations")}
            />
            <h1
              style={{
                margin: 0,
                fontSize: 32,
                fontWeight: "700",
                marginLeft: 8,
              }}
            >
              Edit Facility
            </h1>
          </div>
          <p style={{ color: Colors.lightGray, marginBottom: 20 }}>
            Modify the details of a facility you manage.
          </p>
        </div>
      </div>
      <div
        style={{
          marginBottom: 200,
          maxWidth: 620,
          display: "flex",
          flexDirection: "column",
          flexWrap: "wrap",
          gap: 12,
        }}
      >
        <label>
          Facility Name
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.currentTarget.value)}
          />
          <p style={{ height: 20, marginInline: 8, color: Colors.blue }}>
            {validateName()}
          </p>
        </label>
        <label>
          Entrance Protocol
          <input
            type="text"
            value={entranceProtocol}
            onChange={(e) => setEntranceProtocol(e.currentTarget.value)}
          />
          <p style={{ height: 20, marginInline: 8, color: Colors.blue }}>
            {validateEntranceProtocol()}
          </p>
        </label>
        <label>
          Main Contact Phone Number
          <input
            type="text"
            value={phone}
            onChange={(e) => setPhone(e.currentTarget.value)}
          />
          <p style={{ height: 20, marginInline: 8, color: Colors.blue }}>
            {validatePhone()}
          </p>
        </label>
        <label>
          Street Address 1
          <input
            type="text"
            value={address1}
            onChange={(e) => setAddress1(e.currentTarget.value)}
          />
          <p style={{ height: 20, marginInline: 8, color: Colors.blue }}>
            {validateAddress1()}
          </p>
        </label>
        <label>
          Street Address 2
          <input
            type="text"
            value={address2}
            onChange={(e) => setAddress2(e.currentTarget.value)}
          />
          <p style={{ height: 20, marginInline: 8, color: Colors.blue }}></p>
        </label>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            gap: "inherit",
          }}
        >
          <label style={{ flex: 1 }}>
            City
            <input
              type="text"
              value={city}
              onChange={(e) => setCity(e.currentTarget.value)}
            />
            <p style={{ height: 20, marginInline: 8, color: Colors.blue }}>
              {validateCity()}
            </p>
          </label>
          <label style={{ flex: 1 }}>
            State
            <select
              style={{
                padding: 7,
                borderRadius: 8,
                fontSize: 16,
              }}
              value={state}
              onChange={(e) => setState(e.currentTarget.value)}
            >
              <option disabled value="" label="">
                Select a state...
              </option>
              {states.map(function (item) {
                return (
                  <option
                    key={item.abbreviation}
                    style={{
                      height: 20,
                      marginInline: 8,
                      color: Colors.blue,
                    }}
                    value={item.abbreviation}
                    label={`${item.abbreviation} - ${item.name}`}
                  >
                    {item.abbreviation} - {item.name}
                  </option>
                );
              })}
            </select>
            <p style={{ height: 20, marginInline: 8, color: Colors.blue }}>
              {validateState()}
            </p>
          </label>
          <label style={{ flex: 1 }}>
            Zip Code
            <input
              type="text"
              value={zip}
              onChange={(e) => setZip(e.currentTarget.value)}
              maxLength={5}
            />
            <p style={{ height: 20, marginInline: 8, color: Colors.blue }}>
              {validateZip()}
            </p>
          </label>
        </div>
        <div
          style={{
            display: "flex",
            // marginTop: 10,
            marginInline: 12,
            justifyContent: "flex-end",
          }}
        >
          <ButtonUnstyled
            style={{
              cursor: changes() === true ? "pointer" : undefined,
              borderRadius: 4,
              paddingBlock: 6,
              paddingInline: 32,
              fontWeight: 600,
              margin: 4,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              background: Colors.border,
              color: changes() === true ? "red" : "lightgray",
              border:
                changes() === true ? `1px solid red` : `1px solid lightgray`,
              boxShadow:
                changes() === true ? "2px 2px 4px rgba(0,0,0,0.2)" : "none",
            }}
            onClick={() => {
              if (!facility) {
                return;
              }

              setAddress1(facility.address.address1);
              setAddress2(facility.address.address2);
              setCity(facility.address.city);
              setEntranceProtocol(facility.entranceProtocol);
              setName(facility.name);
              setPhone(facility.phone);
              setState(facility.address.state);
              setZip(facility.address.zip);
            }}
          >
            Discard Changes
          </ButtonUnstyled>
          <ButtonUnstyled
            style={{
              cursor:
                changes() === true
                  ? validate() === true
                    ? "pointer"
                    : undefined
                  : undefined,
              borderRadius: 4,
              paddingBlock: 6,
              paddingInline: 32,
              fontWeight: 600,
              margin: 4,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              background: Colors.border,
              color:
                changes() === true
                  ? validate() === true
                    ? Colors.blue
                    : "lightgray"
                  : "lightgray",
              border:
                changes() === true
                  ? validate() === true
                    ? `1px solid ${Colors.blue}`
                    : `1px solid lightgray`
                  : `1px solid lightgray`,
              boxShadow:
                changes() === true
                  ? validate() === true
                    ? "2px 2px 4px rgba(0,0,0,0.2)"
                    : "none"
                  : "none",
            }}
            onClick={() => {
              if (changes() && validate()) {
                globalContext.api.updateFacility
                  .call(
                    globalContext.callOptions(),
                    {},
                    {
                      _id: facilityId,
                      timezone: "America/Chicago",
                      address: {
                        address1: address1,
                        address2: address2,
                        city: city,
                        state: state,
                        zip: zip,
                      },
                      entranceProtocol: entranceProtocol,
                      name: name,
                    }
                  )
                  .then((response) => {
                    if (response.status === 200) {
                      setFacility({
                        _id: facilityId,
                        active: facility?.active ?? false,
                        //TODO (Joe): Add timezone updating to this file
                        timezone: "America/Chicago",
                        address: {
                          address1: address1,
                          address2: address2,
                          city: city,
                          state: state,
                          zip: zip,
                        },
                        entranceProtocol: entranceProtocol,
                        name: name,
                        phone: phone,
                      });
                      navigate("/facility/locations");
                    } else {
                      alert("Error updating facility");
                    }
                  });
              }
            }}
          >
            Save Changes
          </ButtonUnstyled>
        </div>
      </div>
    </div>
  );
}
