import {
  ApiCallOptions,
  EndPointType,
  FlatEndPointType,
  FlatEndPointTypeDefinition,
  HttpResponse,
} from "@xaratan/pagerr-common";
import { Dispatch, SetStateAction, useEffect, useState } from "react";

type GetCaller<
  RequestParamsType extends FlatEndPointTypeDefinition,
  ResponseType
> = (
  callOptions: ApiCallOptions,
  requestParameters: FlatEndPointType<RequestParamsType>,
  abortController?: AbortController
) => Promise<HttpResponse<EndPointType<ResponseType>>>;

type GetEndPoint<
  RequestParamsType extends FlatEndPointTypeDefinition,
  ResponseType
> = {
  call: GetCaller<RequestParamsType, ResponseType>;
};

export function useGetState<
  RequestParamsType extends FlatEndPointTypeDefinition,
  ResponseType
>(
  endPoint: GetEndPoint<RequestParamsType, ResponseType>,
  callOptions: ApiCallOptions,
  requestParameters: FlatEndPointType<RequestParamsType>
): [
  EndPointType<ResponseType> | undefined,
  Dispatch<SetStateAction<EndPointType<ResponseType> | undefined>>,
  (
    abortController?: AbortController,
    requestParameters?: FlatEndPointType<RequestParamsType>
  ) => void
] {
  const [state, setState] = useState<EndPointType<ResponseType> | undefined>(
    undefined
  );

  function load(
    abortController?: AbortController,
    params?: FlatEndPointType<RequestParamsType>
  ) {
    endPoint
      .call(callOptions, params ?? requestParameters, abortController)
      .then((response) => {
        if (response.status === 200) {
          setState(response.data);
        } else {
          //Let the failure handler take care of any issues.
        }
      })
      .catch((err) => {
        //Let the failure handler take care of any issues.
      });
  }

  useEffect(() => {
    const abortController = new AbortController();

    load(abortController);

    return () => {
      abortController.abort();
    };
  }, []);

  return [state, setState, load];
}
