import { ButtonUnstyled } from "@mui/base";
import { useEffect, useState } from "react";
import { Colors } from "../Colors";
import { useGlobals } from "../contexts/useGlobals";
import { useGetState } from "../helpers/useGetState";

export default function FacilityProfile() {
  const globalState = useGlobals();

  const [profile, setProfile] = useGetState(
    globalState.api.getAccount,
    globalState.callOptions(),
    {}
  );

  const [firstName, setFirstName] = useState<string | undefined>(undefined);
  const [lastName, setLastName] = useState<string | undefined>(undefined);
  const [phone, setPhone] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (profile) {
      setFirstName(profile.firstName);
      setLastName(profile.lastName);
      setPhone(profile.phone);
    }
  }, [profile]);

  function saveProfile() {
    if (changes() && validate()) {
      let update: any = {};

      if (firstName && firstName !== profile?.firstName) {
        update.firstName = firstName;
      }

      if (lastName && lastName !== profile?.lastName) {
        update.lastName = lastName;
      }

      if (phone && phone !== profile?.phone) {
        update.phone = phone;
      }

      globalState.api.updateAccount
        .call(globalState.callOptions(), {}, update)
        .then((response) => {
          if (response.status === 200) {
            setProfile(response.data);

            //Update the session info
            globalState.sessionInfo.setValue((info) => {
              return {
                ...info!,
                firstName: response.data?.firstName ?? "",
                lastName: response.data?.lastName ?? "",
              };
            });
          } else {
            alert("Error updating profile.");
          }
        });
    }
  }

  function validateFirstName(): string {
    if (!firstName || firstName.length === 0) {
      return "First name is required.";
    }
    return "";
  }

  function validateLastName(): string {
    if (!lastName || lastName.length === 0) {
      return "Last name is required.";
    }
    return "";
  }

  function validatePhone(): string {
    if (!phone || phone.length === 10) {
      return "";
    }
    return "Please enter a valid phone number (including area code) with no dashes or spaces.";
  }

  function validate() {
    return !(
      !!validateFirstName() ||
      !!validateLastName() ||
      !!validatePhone()
    );
  }

  function changes() {
    return !(
      firstName === profile?.firstName &&
      lastName === profile?.lastName &&
      phone === profile?.phone
    );
  }

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-start",
        flexDirection: "column",
        flex: 1,
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <h1
          style={{
            margin: 0,
            fontSize: 32,
            marginBottom: 12,
            fontWeight: "700",
          }}
        >
          Your Profile
        </h1>
        <p style={{ color: Colors.lightGray, marginBottom: 20 }}>
          This is where you can manage your personal information.
        </p>
        <div
          style={{
            display: "flex",
            flex: 1,
            flexDirection: "column",
            maxWidth: 520,
          }}
        >
          <label>
            First Name
            <input
              value={(firstName ?? "").toString()}
              onChange={(e) => setFirstName(e.currentTarget.value)}
            />
            <p style={{ height: 20, marginInline: 8, color: Colors.blue }}>
              {validateFirstName()}
            </p>
          </label>
          <label>
            Last Name
            <input
              value={(lastName ?? "").toString()}
              onChange={(e) => setLastName(e.currentTarget.value)}
            />
            <p style={{ height: 20, marginInline: 8, color: Colors.blue }}>
              {validateLastName()}
            </p>
          </label>
          <label>
            Phone Number
            <input
              value={(phone ?? "").toString()}
              onChange={(e) => setPhone(e.currentTarget.value)}
              maxLength={10}
            />
            <p style={{ height: 20, marginInline: 8, color: Colors.blue }}>
              {validatePhone()}
            </p>
          </label>
          <label>
            Email
            <input
              style={
                {
                  // background: "none",
                  // border: "1px solid #eaeaea",
                }
              }
              value={(profile?.email ?? "").toString()}
              // onChange={(e) => setEmail(e.currentTarget.value)}
              disabled
              onClick={() => alert("Email cannot be modified.")}
            />
            <p style={{ height: 20, marginInline: 8, color: "gray" }}>
              Email cannot be modified
            </p>
          </label>
          <div
            style={{
              display: "flex",
              // marginTop: 10,
              marginInline: 12,
              justifyContent: "flex-end",
            }}
          >
            <ButtonUnstyled
              style={{
                cursor: changes() === true ? "pointer" : undefined,
                borderRadius: 4,
                paddingBlock: 6,
                paddingInline: 32,
                fontWeight: 600,
                margin: 4,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                background: Colors.border,
                color: changes() === true ? "red" : "lightgray",
                border:
                  changes() === true ? `1px solid red` : `1px solid lightgray`,
                boxShadow:
                  changes() === true ? "2px 2px 4px rgba(0,0,0,0.2)" : "none",
              }}
              onClick={() => {
                setFirstName(profile?.firstName ?? "");
                setLastName(profile?.lastName ?? "");
                setPhone(profile?.phone ?? "");
              }}
            >
              Discard Changes
            </ButtonUnstyled>
            <ButtonUnstyled
              style={{
                cursor:
                  changes() === true
                    ? validate() === true
                      ? "pointer"
                      : undefined
                    : undefined,
                borderRadius: 4,
                paddingBlock: 6,
                paddingInline: 32,
                fontWeight: 600,
                margin: 4,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                background: Colors.border,
                color:
                  changes() === true
                    ? validate() === true
                      ? Colors.blue
                      : "lightgray"
                    : "lightgray",
                border:
                  changes() === true
                    ? validate() === true
                      ? `1px solid ${Colors.blue}`
                      : `1px solid lightgray`
                    : `1px solid lightgray`,
                boxShadow:
                  changes() === true
                    ? validate() === true
                      ? "2px 2px 4px rgba(0,0,0,0.2)"
                      : "none"
                    : "none",
              }}
              onClick={() => {
                saveProfile();
              }}
            >
              Save Changes
            </ButtonUnstyled>
          </div>
        </div>
      </div>
    </div>
  );
}
