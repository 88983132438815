import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import FacilityMenu from "./FacilityMenu";
import FacilityHeader from "./FacilityHeader";
import { mobileTrigger } from "./MobileTrigger";

export default function FacilityChrome() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener("resize", updateWidthAndHeight);
    return () => window.removeEventListener("resize", updateWidthAndHeight);
  });

  const updateWidthAndHeight = () => {
    setWindowWidth(window.innerWidth);
  };

  const windowTrigger = mobileTrigger;

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          zIndex: 0,
          minHeight: "100vh",
        }}
      >
        <div
          style={{
            display: "flex",
            flex: 1,
            flexDirection: "column",
          }}
        >
          <FacilityHeader windowTrigger={windowTrigger} />
          <div
            style={{
              display: "flex",
              flex: 1,
              flexDirection: "row",
            }}
          >
            {windowWidth > windowTrigger ? (
              <FacilityMenu handleClick={() => null} />
            ) : null}
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                flex: 1,
                padding: windowWidth > windowTrigger ? 32 : 12,
                zIndex: 3,
                background: "#fff",
              }}
            >
              <Outlet />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
