import { ButtonUnstyled } from "@mui/base";
import { useEffect, useState } from "react";
import { Colors } from "../Colors";
import { useGlobals } from "../contexts/useGlobals";
import { useGetState } from "../helpers/useGetState";

export default function ProviderProfile() {
  const globalState = useGlobals();

  // const [sessionInfo, setSessionInfo] = useGetState(
  //   globalState.api.sessionInfo,
  //   globalState.callOptions(),
  //   {}
  // );

  const [profile, setProfile] = useGetState(
    globalState.api.getAccount,
    globalState.callOptions(),
    {}
  );

  const states = [
    {
      name: "Alabama",
      abbreviation: "AL",
    },
    {
      name: "Alaska",
      abbreviation: "AK",
    },
    {
      name: "American Samoa",
      abbreviation: "AS",
    },
    {
      name: "Arizona",
      abbreviation: "AZ",
    },
    {
      name: "Arkansas",
      abbreviation: "AR",
    },
    {
      name: "California",
      abbreviation: "CA",
    },
    {
      name: "Colorado",
      abbreviation: "CO",
    },
    {
      name: "Connecticut",
      abbreviation: "CT",
    },
    {
      name: "Delaware",
      abbreviation: "DE",
    },
    {
      name: "District Of Columbia",
      abbreviation: "DC",
    },
    {
      name: "Federated States Of Micronesia",
      abbreviation: "FM",
    },
    {
      name: "Florida",
      abbreviation: "FL",
    },
    {
      name: "Georgia",
      abbreviation: "GA",
    },
    {
      name: "Guam",
      abbreviation: "GU",
    },
    {
      name: "Hawaii",
      abbreviation: "HI",
    },
    {
      name: "Idaho",
      abbreviation: "ID",
    },
    {
      name: "Illinois",
      abbreviation: "IL",
    },
    {
      name: "Indiana",
      abbreviation: "IN",
    },
    {
      name: "Iowa",
      abbreviation: "IA",
    },
    {
      name: "Kansas",
      abbreviation: "KS",
    },
    {
      name: "Kentucky",
      abbreviation: "KY",
    },
    {
      name: "Louisiana",
      abbreviation: "LA",
    },
    {
      name: "Maine",
      abbreviation: "ME",
    },
    {
      name: "Marshall Islands",
      abbreviation: "MH",
    },
    {
      name: "Maryland",
      abbreviation: "MD",
    },
    {
      name: "Massachusetts",
      abbreviation: "MA",
    },
    {
      name: "Michigan",
      abbreviation: "MI",
    },
    {
      name: "Minnesota",
      abbreviation: "MN",
    },
    {
      name: "Mississippi",
      abbreviation: "MS",
    },
    {
      name: "Missouri",
      abbreviation: "MO",
    },
    {
      name: "Montana",
      abbreviation: "MT",
    },
    {
      name: "Nebraska",
      abbreviation: "NE",
    },
    {
      name: "Nevada",
      abbreviation: "NV",
    },
    {
      name: "New Hampshire",
      abbreviation: "NH",
    },
    {
      name: "New Jersey",
      abbreviation: "NJ",
    },
    {
      name: "New Mexico",
      abbreviation: "NM",
    },
    {
      name: "New York",
      abbreviation: "NY",
    },
    {
      name: "North Carolina",
      abbreviation: "NC",
    },
    {
      name: "North Dakota",
      abbreviation: "ND",
    },
    {
      name: "Northern Mariana Islands",
      abbreviation: "MP",
    },
    {
      name: "Ohio",
      abbreviation: "OH",
    },
    {
      name: "Oklahoma",
      abbreviation: "OK",
    },
    {
      name: "Oregon",
      abbreviation: "OR",
    },
    {
      name: "Palau",
      abbreviation: "PW",
    },
    {
      name: "Pennsylvania",
      abbreviation: "PA",
    },
    {
      name: "Puerto Rico",
      abbreviation: "PR",
    },
    {
      name: "Rhode Island",
      abbreviation: "RI",
    },
    {
      name: "South Carolina",
      abbreviation: "SC",
    },
    {
      name: "South Dakota",
      abbreviation: "SD",
    },
    {
      name: "Tennessee",
      abbreviation: "TN",
    },
    {
      name: "Texas",
      abbreviation: "TX",
    },
    {
      name: "Utah",
      abbreviation: "UT",
    },
    {
      name: "Vermont",
      abbreviation: "VT",
    },
    {
      name: "Virgin Islands",
      abbreviation: "VI",
    },
    {
      name: "Virginia",
      abbreviation: "VA",
    },
    {
      name: "Washington",
      abbreviation: "WA",
    },
    {
      name: "West Virginia",
      abbreviation: "WV",
    },
    {
      name: "Wisconsin",
      abbreviation: "WI",
    },
    {
      name: "Wyoming",
      abbreviation: "WY",
    },
  ];

  const [firstName, setFirstName] = useState<string | undefined>(undefined);
  const [lastName, setLastName] = useState<string | undefined>(undefined);
  const [education, setEducation] = useState<string | undefined>(undefined);
  const [phone, setPhone] = useState<string | undefined>(undefined);
  const [address1, setAddress1] = useState<string>("");
  const [address2, setAddress2] = useState<string>("");
  const [city, setCity] = useState<string>("");
  const [state, setState] = useState<string>("");
  const [zip, setZip] = useState<string>("");

  useEffect(() => {
    if (profile) {
      setFirstName(profile.firstName);
      setLastName(profile.lastName);
      setEducation(
        globalState.sessionInfo.value?.provider?.educationLevel ?? ""
      );
      // TODO update to be educationLevel (MD, DO, or CRNA)
      setPhone(profile.phone);
      setAddress1(profile.address?.address1 ? profile.address.address1 : "");
      setAddress2(profile.address?.address2 ? profile.address.address2 : "");
      setCity(profile.address?.city ? profile.address.city : "");
      setState(profile.address?.state ? profile.address.state : "");
      setZip(profile.address?.zip ? profile.address.zip : "");
    }
  }, [profile]);

  function saveProfile() {
    if (changes() && validate()) {
      let update: any = {};

      if (firstName && firstName !== profile?.firstName) {
        update.firstName = firstName;
      }

      if (lastName && lastName !== profile?.lastName) {
        update.lastName = lastName;
      }

      if (phone && phone !== profile?.phone) {
        update.phone = phone;
      }

      //TODO Shaun implement API to update address

      if (addressChanges()) {
        update.address = {};

        update.address.address1 = address1;
        update.address.address2 = address2;
        update.address.city = city;
        update.address.state = state;
        update.address.zip = zip;
      }

      globalState.api.updateAccount
        .call(globalState.callOptions(), {}, update)
        .then((response) => {
          if (response.status === 200) {
            setProfile(response.data);

            //Update the session info
            globalState.sessionInfo.setValue((info) => {
              return {
                ...info!,
                firstName: response.data?.firstName ?? "",
                lastName: response.data?.lastName ?? "",
              };
            });
          } else {
            alert("Error updating profile.");
          }
        });
    }
  }

  function validateFirstName(): string {
    if (!firstName || firstName.length === 0) {
      return "First name is required.";
    }
    return "";
  }

  function validateLastName(): string {
    if (!lastName || lastName.length === 0) {
      return "Last name is required.";
    }
    return "";
  }

  function validatePhone(): string {
    if (phone === undefined || phone.length === 10) {
      return "";
    }
    return "Please enter a valid phone number (including area code) with no dashes or spaces.";
  }

  function validateAddress1(): string {
    if (address1.length === 0) {
      return "Address is required.";
    }
    return "";
  }

  function validateCity(): string {
    if (city.length === 0) {
      return "City is required.";
    }
    return "";
  }

  function validateState(): string {
    if (state.length === 0) {
      return "State is required.";
    }
    return "";
  }

  function validateZip(): string {
    if (zip.length === 5) {
      return "";
    }
    return "A valid zip code is required.";
  }

  function validate() {
    return !(
      !!validateFirstName() ||
      !!validateLastName() ||
      !!validatePhone() ||
      !!validateAddress1() ||
      !!validateCity() ||
      !!validateState() ||
      !!validateZip()
    );
  }

  function addressChanges() {
    return (
      address1 !== profile?.address?.address1 ||
      address2 !== profile?.address?.address2 ||
      city !== profile?.address?.city ||
      state !== profile?.address?.state ||
      zip !== profile?.address?.zip
    );
  }

  function changes() {
    return !(
      firstName === profile?.firstName &&
      lastName === profile?.lastName &&
      phone === profile?.phone &&
      !addressChanges()
    );
  }

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-start",
        flexDirection: "column",
        flex: 1,
        paddingBottom: 240,
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <h1
          style={{
            margin: 0,
            fontSize: 32,
            marginBottom: 12,
            fontWeight: 700,
          }}
        >
          Your Profile
        </h1>
        <p style={{ color: Colors.lightGray, marginBottom: 20 }}>
          This is where you can manage your personal information.
        </p>
        <div
          style={{
            display: "flex",
            flex: 1,
            flexDirection: "column",
            maxWidth: 620,
            gap: 12,
          }}
        >
          <div
            style={{
              display: "flex",
              flex: 1,
              flexDirection: "row",
              gap: "inherit",
              flexWrap: "wrap",
            }}
          >
            <label
              style={{
                display: "flex",
                flex: 1,
              }}
            >
              First Name
              <input
                type="text"
                value={(firstName ?? "").toString()}
                onChange={(e) => setFirstName(e.currentTarget.value)}
              />
              <p style={{ marginInline: 8, color: Colors.blue }}>
                {validateFirstName()}
              </p>
            </label>
            <label
              style={{
                display: "flex",
                flex: 1,
              }}
            >
              Last Name
              <input
                type="text"
                value={(lastName ?? "").toString()}
                onChange={(e) => setLastName(e.currentTarget.value)}
              />
              <p style={{ marginInline: 8, color: Colors.blue }}>
                {validateLastName()}
              </p>
            </label>
            <label
              style={{
                display: "flex",
                flex: 1,
              }}
            >
              Education
              <input
                type="text"
                value={(education ?? "").toString()}
                disabled
                onChange={(e) => setEducation(e.currentTarget.value)}
              />
              <p style={{ marginInline: 8, color: Colors.lightGray }}>
                Cannot be modified
              </p>
            </label>
          </div>
          <label>
            Email
            <input
              value={(profile?.email ?? "").toString()}
              // onChange={(e) => setEmail(e.currentTarget.value)}
              disabled
              onClick={() => alert("Email cannot be modified.")}
            />
            <p style={{ marginInline: 8, color: Colors.lightGray }}>
              Cannot be modified
            </p>
          </label>
          <label>
            Phone Number
            <input
              type="tel"
              value={(phone ?? "").toString()}
              onChange={(e) => setPhone(e.currentTarget.value)}
              maxLength={10}
              pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
            />
            <p style={{ marginInline: 8, color: Colors.blue }}>
              {validatePhone()}
            </p>
          </label>
          <div
            style={{ display: "flex", flexDirection: "column", gap: "inherit" }}
          >
            <label>
              Street Address 1
              <input
                value={address1}
                type="text"
                onChange={(e) => setAddress1(e.currentTarget.value)}
              />
              <p style={{ marginInline: 8, color: Colors.blue }}>
                {validateAddress1()}
              </p>
            </label>
            <label>
              Street Address 2
              <input
                value={address2}
                type="text"
                onChange={(e) => setAddress2(e.currentTarget.value)}
              />
              <p style={{ marginInline: 8, color: Colors.blue }}></p>
            </label>
            <div
              style={{
                display: "flex",
                flex: 1,
                gap: "inherit",
                flexWrap: "wrap",
              }}
            >
              <label style={{ display: "flex", flex: 1 }}>
                City
                <input
                  value={city}
                  type="text"
                  onChange={(e) => setCity(e.currentTarget.value)}
                />
                <p style={{ marginInline: 8, color: Colors.blue }}>
                  {validateCity()}
                </p>
              </label>
              <label style={{ display: "flex", flex: 1 }}>
                State
                <select
                  style={{ display: "flex", flex: 1, padding: 8, fontSize: 16 }}
                  onChange={(e) => setState(e.currentTarget.value)}
                  value={state}
                >
                  <option disabled value="" label="">
                    Select a state...
                  </option>
                  {states.map(function (s) {
                    return (
                      <option
                        key={s.abbreviation}
                        value={s.abbreviation}
                        label={`${s.abbreviation} - ${s.name}`}
                      >
                        {s.abbreviation} - {s.name}
                      </option>
                    );
                  })}
                </select>
                <p style={{ marginInline: 8, color: Colors.blue }}>
                  {validateState()}
                </p>
              </label>
              <label style={{ display: "flex", flex: 1 }}>
                Zip
                <input
                  value={zip}
                  type="text"
                  onChange={(e) => setZip(e.currentTarget.value)}
                  maxLength={5}
                />
                <p style={{ marginInline: 8, color: Colors.blue }}>
                  {validateZip()}
                </p>
              </label>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              gap: 12,
              flexWrap: "wrap-reverse",
              marginTop: 20,
            }}
          >
            <ButtonUnstyled
              style={{
                cursor: changes() ? "pointer" : undefined,
                borderRadius: 4,
                paddingBlock: 6,
                paddingInline: 32,
                fontSize: 16,
                fontWeight: 600,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                background: Colors.border,
                color: changes() ? "red" : "lightgray",
                border: changes() ? `1px solid red` : `1px solid lightgray`,
                boxShadow: changes() ? "2px 2px 4px rgba(0,0,0,0.2)" : "none",
              }}
              onClick={() => {
                setFirstName(profile?.firstName ?? "");
                setLastName(profile?.lastName ?? "");
                setPhone(profile?.phone ?? "");
                setAddress1(profile?.address?.address1 ?? "");
                setAddress2(profile?.address?.address2 ?? "");
                setCity(profile?.address?.city ?? "");
                setState(profile?.address?.state ?? "");
                setZip(profile?.address?.zip ?? "");
              }}
            >
              Discard Changes
            </ButtonUnstyled>
            <ButtonUnstyled
              style={{
                cursor: changes() && validate() ? "pointer" : undefined,
                borderRadius: 4,
                paddingBlock: 6,
                fontSize: 16,
                paddingInline: 32,
                fontWeight: 600,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                background: Colors.border,
                color: changes() && validate() ? Colors.blue : "lightgray",
                border:
                  changes() && validate()
                    ? `1px solid ${Colors.blue}`
                    : `1px solid lightgray`,
                boxShadow:
                  changes() && validate()
                    ? "2px 2px 4px rgba(0,0,0,0.2)"
                    : "none",
              }}
              onClick={() => {
                saveProfile();
                console.log(changes());
                console.log(validate());
              }}
              disabled={!changes() || !validate()}
            >
              Save Changes
            </ButtonUnstyled>
          </div>
        </div>
        {/* {JSON.stringify(changes())}
        <br />
        {JSON.stringify(validate())} */}
      </div>
    </div>
  );
}
