import { Popover } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import {
  MdKeyboardArrowDown,
  MdLogout,
  MdMedicalServices,
} from "react-icons/md";
import { Colors } from "../Colors";
import { ButtonUnstyled } from "@mui/base";
import { useLocation, useNavigate } from "react-router-dom";
import { GlobalStateContext } from "../contexts/GlobalStateContext";
import { FaChevronRight, FaHospital } from "react-icons/fa";

function determineDisplayName(
  firstName: string | undefined,
  lastName: string | undefined,
  email: string | undefined
) {
  if (firstName && firstName.length > 0) {
    if (lastName && lastName.length > 0) {
      return `${firstName} ${lastName}`;
    } else {
      return firstName;
    }
  }

  if (lastName && lastName.length > 0) {
    return lastName;
  }

  return email ?? ""; //An undefined email shouldn't really happen
}

export default function LogoutButton() {
  const globalState = useContext(GlobalStateContext);

  const location = useLocation();

  const navigate = useNavigate();
  const [data, setData] = useState<{
    name: string;
    email: string;
    display: string;
  }>({
    name:
      (globalState.sessionInfo.value?.firstName ?? "") +
      " " +
      (globalState.sessionInfo.value?.lastName ?? ""),
    email: globalState.sessionInfo.value?.email ?? "",
    display: determineDisplayName(
      globalState.sessionInfo.value?.firstName,
      globalState.sessionInfo.value?.lastName,
      globalState.sessionInfo.value?.email
    ),
  });

  useEffect(() => {
    setData({
      name:
        (globalState.sessionInfo.value?.firstName ?? "") +
        " " +
        (globalState.sessionInfo.value?.lastName ?? ""),
      email: globalState.sessionInfo.value?.email ?? "",
      display: determineDisplayName(
        globalState.sessionInfo.value?.firstName,
        globalState.sessionInfo.value?.lastName,
        globalState.sessionInfo.value?.email
      ),
    });
  }, [
    globalState.sessionInfo.value?.firstName,
    globalState.sessionInfo.value?.lastName,
    globalState.sessionInfo.value?.email,
  ]);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <>
      <ButtonUnstyled
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-end",
          background: "none",
          border: "none",
          fontSize: 16,
          cursor: "pointer",
          padding: 0,
          margin: 0,
        }}
        underline={"none"}
        onClick={handleClick}
      >
        <div
          style={{
            display: "flex",
            height: 28,
            width: 28,
            alignItems: "center",
            justifyContent: "center",
            borderRadius: 40,
            background: "rgba(238, 244, 255, 1)",
          }}
        >
          <p
            style={{
              textAlign: "center",
              color: Colors.blue,
              fontWeight: 600,
            }}
          >
            {data.display.charAt(0).toUpperCase()}
          </p>
        </div>
        <p
          style={{
            marginLeft: 4,
            marginRight: 12,
          }}
        >
          {data.display}&nbsp;
          {globalState?.sessionInfo?.value?.provider?.educationLevel ?? ""}
        </p>
        <MdKeyboardArrowDown color={Colors.blue} />
      </ButtonUnstyled>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            // padding: 4,
            // gap: 4,
          }}
        >
          {globalState.sessionInfo.value?.facilities &&
          Array.isArray(globalState.sessionInfo.value?.facilities) &&
          globalState.sessionInfo.value?.provider ? (
            location.pathname.split("/")[1] === "facility" ? (
              <ButtonUnstyled
                className="logoutButton"
                onClick={() => {
                  navigate("/provider/listings");
                }}
              >
                <MdMedicalServices />
                Switch to Provider View
                <FaChevronRight size={12} />
              </ButtonUnstyled>
            ) : (
              <ButtonUnstyled
                className="logoutButton"
                onClick={() => {
                  navigate("/facility/listings");
                }}
              >
                <FaHospital />
                Switch to Facility View
                <FaChevronRight size={12} />
              </ButtonUnstyled>
            )
          ) : null}

          <ButtonUnstyled
            className="logoutButton"
            onClick={() => {
              globalState.token.setValue(undefined);
              globalState.sessionInfo.setValue(undefined);
              navigate("/");
            }}
          >
            Log out
            <MdLogout />
          </ButtonUnstyled>
        </div>
      </Popover>
    </>
  );
}
