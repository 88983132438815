import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Dialog } from "@mui/material";
import { Colors } from "../Colors";
import { ButtonUnstyled } from "@mui/base";
import { MdVisibility, MdVisibilityOff } from "react-icons/md";
import { FaStethoscope } from "react-icons/fa";
import { useGetState } from "../helpers/useGetState";
import { useGlobals } from "../contexts/useGlobals";
import {
  ApiCallOptions,
  AuthProvider,
  FailureHandler,
} from "@xaratan/pagerr-common";

export default function ResetPassword() {
  let navigate = useNavigate();
  const globalState = useGlobals();
  const params = useParams();

  const token = params.token ?? "";

  const [passwordRules] = useGetState(
    globalState.api.passwordRules,
    globalState.callOptions(),
    {}
  );

  const [successVisible, setSuccessVisible] = useState<boolean>(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [passwordVisible, setPasswordVisible] = useState<boolean>(false);

  function validatePassword(): string[] {
    if (passwordRules?.rules) {
      const failures: string[] = [];

      for (let r of passwordRules.rules) {
        if (
          Array.from(password.matchAll(new RegExp(r.regex, "g"))).length <
          r.count
        ) {
          failures.push(r.message);
        }
      }

      return failures;
    }

    return [];
  }

  function validateConfirmPassword(): string {
    if (password === confirmPassword) {
      return "";
    }

    return "Passwords must match";
  }

  function validate() {
    return !(validatePassword().length !== 0 || !!validateConfirmPassword());
  }

  return (
    <div
      style={{
        minHeight: "100vh",
        background: Colors.border,
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Dialog open={successVisible} onClose={() => navigate("/login")}>
        <div style={{ padding: 20, display: "flex", flexDirection: "column" }}>
          <h3>Success!</h3>
          <p style={{ marginInline: 4 }}>
            Your password has been reset. Head over to the login page!
          </p>
          <ButtonUnstyled
            onClick={() => {
              navigate("/login");
            }}
            style={{
              margin: 12,
              borderRadius: 4,
              paddingInline: 24,
              paddingBlock: 8,
              fontSize: 14,
              color: Colors.blue,
              fontWeight: "600",
              background: Colors.blueBrightPrimary,
              border: `1px solid ${Colors.blue}`,
              cursor: "pointer",

              alignSelf: "flex-end",
            }}
          >
            Continue
          </ButtonUnstyled>
        </div>
      </Dialog>

      <div style={{ marginBottom: 200, marginTop: 40, marginInline: 20 }}>
        <span
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: 20,
          }}
        >
          <div
            style={{
              background: Colors.blue,
              borderRadius: 24,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              height: 48,
              width: 48,
            }}
          >
            <FaStethoscope style={{ color: "#FBFBFD" }} size={20} />
          </div>
          <h2
            style={{
              color: Colors.blue,
              fontSize: 40,
              fontWeight: 800,
            }}
          >
            Pagerr
          </h2>
        </span>
        <div
          style={{
            display: "flex",
            padding: 16,
            borderRadius: 8,
            background: "#fff",
            // border: "1px solid gray",
            boxShadow: "0px 4px 8px rgba(0,0,0,0.2)",
            flexDirection: "column",
          }}
        >
          <h3 style={{}}>Password Reset</h3>
          <p style={{ maxWidth: 420, marginBottom: 12, marginInline: 4 }}>
            Enter your new password below.
          </p>
          <form
            style={{ display: "flex", flexDirection: "column", gap: 20 }}
            onSubmit={(e) => {
              // login()
              e.preventDefault();

              if (validate()) {
                const callOptions: ApiCallOptions = {
                  authProvider: AuthProvider.null(),
                  baseUrl: "",
                  failureHandler: FailureHandler.null(),
                };

                globalState.api.resetPassword
                  .call(
                    callOptions,
                    {},
                    {
                      token: token,
                      password: password,
                    }
                  )
                  .then((result) => {
                    if (result.status === 200) {
                      setSuccessVisible(true);
                    } else {
                      alert("Failed to reset password");
                    }
                  });
              }
            }}
          >
            <label>
              Password:
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  contain: "layout",
                }}
              >
                <input
                  type={passwordVisible === true ? "text" : "password"}
                  onChange={(evt) => setPassword(evt.currentTarget.value)}
                  value={password}
                  style={{ flex: 1 }}
                />
                {passwordVisible === false ? (
                  <MdVisibility
                    size={20}
                    onClick={() => setPasswordVisible(!passwordVisible)}
                    style={{
                      color: Colors.blue,
                      position: "absolute",
                      right: 8,
                    }}
                  />
                ) : (
                  <MdVisibilityOff
                    size={20}
                    onClick={() => setPasswordVisible(!passwordVisible)}
                    style={{
                      color: Colors.blue,
                      position: "absolute",
                      right: 8,
                    }}
                  />
                )}
              </div>
              <p
                style={{
                  minHeight: 12,
                  fontWeight: 600,
                  color: Colors.blue,
                  marginInline: 8,
                }}
              >
                <ul>
                  {validatePassword().map((failure) => {
                    return <li>{failure}</li>;
                  })}
                </ul>
              </p>
            </label>
            <label>
              Confirm Password:
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  contain: "layout",
                }}
              >
                <input
                  type={passwordVisible === true ? "text" : "password"}
                  onChange={(evt) =>
                    setConfirmPassword(evt.currentTarget.value)
                  }
                  value={confirmPassword}
                  style={{ flex: 1 }}
                />
                {passwordVisible === false ? (
                  <MdVisibility
                    size={20}
                    onClick={() => setPasswordVisible(!passwordVisible)}
                    style={{
                      color: Colors.blue,
                      position: "absolute",
                      right: 8,
                    }}
                  />
                ) : (
                  <MdVisibilityOff
                    size={20}
                    onClick={() => setPasswordVisible(!passwordVisible)}
                    style={{
                      color: Colors.blue,
                      position: "absolute",
                      right: 8,
                    }}
                  />
                )}
              </div>
              <p
                style={{
                  minHeight: 12,
                  fontWeight: 600,
                  color: Colors.blue,
                  marginInline: 8,
                }}
              >
                {validateConfirmPassword()}
              </p>
            </label>
            <input
              type="submit"
              disabled={!validate()}
              style={{
                margin: 12,
                borderRadius: 4,
                padding: 12,
                fontSize: 14,
                color: validate() === true ? Colors.blue : "lightgray",
                fontWeight: 600,
                background:
                  validate() === true ? Colors.blueBrightPrimary : "#eaeaea",
                border:
                  validate() === true
                    ? `1px solid ${Colors.blue}`
                    : "1px solid lightgray",
                cursor: validate() === true ? "pointer" : undefined,
              }}
              value="Change Password"
            />
          </form>
        </div>
      </div>
    </div>
  );
}
