import "../styles/Settings.css";
import { useNavigate } from "react-router-dom";
import { useState, useEffect, useContext } from "react";
import { GlobalStateContext } from "../contexts/GlobalStateContext";
import axios from "axios";
import { useGetState } from "../helpers/useGetState";
import { Colors } from "../Colors";

function getCancelTokenSource() {
  const cancelToken = axios.CancelToken;
  const source = cancelToken.source();
  return source;
}

export default function ProviderAlertSettings() {
  const navigate = useNavigate();
  const globalState = useContext(GlobalStateContext);

  const [alerts] = useGetState(
    globalState.api.getAlertPreferences,
    globalState.callOptions(),
    {}
  );

  const [emails, setEmails] = useState<boolean | undefined>(undefined);
  const [texts, setTexts] = useState<boolean | undefined>(undefined);

  useEffect(() => {
    if (alerts) {
      setEmails(alerts.email);
      setTexts(alerts.text);
    }
  }, [alerts]);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener("resize", updateWidthAndHeight);
    return () => window.removeEventListener("resize", updateWidthAndHeight);
  }, []);

  const updateWidthAndHeight = () => {
    setWindowWidth(window.innerWidth);
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-start",
        flexDirection: "column",
        flex: 1,
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "flex-start",
          flexDirection: "column",
        }}
      >
        <h1
          style={{
            margin: 0,
            fontSize: 32,
            marginBottom: 12,
            fontWeight: "700",
          }}
        >
          Alert Settings
        </h1>
        <p style={{ color: Colors.lightGray, marginBottom: 20 }}>
          Let us know when you want to be alerted of a new job listing.
        </p>
      </div>
      <div
        style={{
          border: "1px solid #eaeaea",
          borderRadius: 16,
        }}
      >
        <div
          style={{
            display: "flex",
            padding: 40,
            flexWrap: "wrap",
          }}
        >
          <div
            style={{
              flex: 1,
            }}
          >
            <p style={{ fontSize: 20, fontWeight: 600 }}>Contact Methods</p>
            <p style={{ color: Colors.lightGray, marginBottom: 32 }}>
              How do you want us to contact you when a new job appears?
            </p>
          </div>
          <div
            style={{
              flex: 1,
              flexDirection: "column",
              display: "flex",
            }}
          >
            <label
              style={{
                fontSize: 16,
                display: "flex",
                flexDirection: "row",
                marginTop: 8,
              }}
            >
              <input
                type="checkbox"
                style={{ marginRight: 8 }}
                checked={!!emails}
                onChange={(e) => {
                  setEmails(e.currentTarget.checked);

                  globalState.api.updateAlertPreferences
                    .call(
                      globalState.callOptions(),
                      {},
                      {
                        email: e.currentTarget.checked,
                      }
                    )
                    .then((response) => {
                      if (response.status === 200) {
                        console.log("Success");
                      } else {
                        console.log("Error");
                      }
                    });
                }}
              />
              <div>
                <p>Email</p>
                <p
                  style={{
                    fontWeight: 400,
                    fontSize: 12,
                    color: Colors.lightGray,
                  }}
                >
                  We will send a message to the email you used to sign up to
                  Pagerr when a new job appears.
                </p>
              </div>
            </label>
            <label
              style={{
                fontSize: 16,
                display: "flex",
                flexDirection: "row",
                marginTop: 8,
              }}
            >
              <input
                type="checkbox"
                style={{ marginRight: 8 }}
                checked={!!texts}
                onChange={(e) => {
                  setTexts(e.target.checked);

                  globalState.api.updateAlertPreferences
                    .call(
                      globalState.callOptions(),
                      {},
                      {
                        text: e.currentTarget.checked,
                      }
                    )
                    .then((response) => {
                      if (response.status === 200) {
                        console.log("Success");
                      } else {
                        console.log("Error");
                      }
                    });
                }}
              />
              <div>
                <p>Text</p>
                <p
                  style={{
                    fontWeight: 400,
                    fontSize: 12,
                    color: Colors.lightGray,
                  }}
                >
                  We will send a text message to the phone number you used to
                  sign up to Pagerr when a new job appears.
                </p>
              </div>
            </label>
          </div>
        </div>

        {/* </table> */}
      </div>
    </div>
  );
}
