import { ButtonUnstyled } from "@mui/base";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useState, useEffect, useContext } from "react";
import {
  MdChevronRight,
  MdClose,
  MdEvent,
  MdLocationPin,
  MdSchedule,
} from "react-icons/md";
import { FaExclamationCircle } from "react-icons/fa";
import { Dialog, Link } from "@mui/material";
import { Colors } from "../Colors";
import { EndPointType } from "@xaratan/pagerr-common";
import moment from "moment";
import { GlobalStateContext } from "../contexts/GlobalStateContext";
import { usePostState } from "../helpers/usePostState";
import { SmartTable } from "./SmartTable";
import { mobileTrigger } from "./MobileTrigger";
import { Oval } from "react-loader-spinner";

export default function JobListings() {
  const navigate = useNavigate();
  const globalContext = useContext(GlobalStateContext);
  const [searchParams] = useSearchParams();

  const [confirmVisible, setConfirmVisible] = useState(false);
  const [successVisible, setSuccessVisible] = useState(false);

  const [loading, setLoading] = useState<boolean>(false);

  const [reloadHint, setReloadHint] = useState(0);

  const [jobs] = usePostState(
    globalContext.api.providerJobListings,
    globalContext.callOptions(),
    {},
    {
      filter: {
        job: searchParams.get("job") ?? undefined,
      },
      pagination: {
        pageSize: 1,
        page: 1,
      },
    },
    !!searchParams.has("job")
  );

  const windowTrigger = mobileTrigger;

  const [modalData, setModalData] = useState<
    | EndPointType<{
        required: {
          _id: "string";
          facilityId: "string";
          facilityName: "string";
          description: "string";
          type: "string";
          startDate: "string";
          endDate: "string";
          startTime: "string";
          endTime: "string";
          specialties: [
            {
              required: {
                specialty: "string";
              };
            }
          ];
          certification: "string";
          createdAt: "string";
          claimed: "boolean";
        };
        optional: {
          mddoRate: "number";
          mddoTotal: "number";
          crnaRate: "number";
          crnaTotal: "number";
        };
      }>
    | undefined
  >(undefined);

  useEffect(() => {
    if (jobs && searchParams.has("job")) {
      const jobId = searchParams.get("job")!;
      const claim = searchParams.get("claim") === "true";

      const job = jobs.jobs.find((j) => {
        return j._id === jobId;
      });

      if (job) {
        setModalData(job);

        if (claim) {
          setConfirmVisible(true);
        } else {
        }
      }
    }
  }, [jobs]);

  return (
    <>
      {modalData ? (
        <Dialog
          open={!!modalData}
          onClose={() => setModalData(undefined)}
          fullWidth
        >
          <div
            style={{
              background: "#fff",
              display: "flex",
              flexDirection: "column",
              borderRadius: 20,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                padding: 20,
              }}
            >
              <p style={{ fontSize: 20, fontWeight: 700, margin: 0 }}>
                Job Details
              </p>
              <MdClose
                size={24}
                onClick={() => setModalData(undefined)}
                style={{ cursor: "pointer" }}
              />
            </div>
            <div
              style={{
                borderRadius: 18,
                border: "1px solid #efeff4",
                padding: 20,
                flexDirection: "column",
                listStyle: "none",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      margin: 4,
                      background: Colors.blue,
                      paddingBlock: 4,
                      paddingInline: 8,
                      borderRadius: 4,
                      color: "#fff",
                    }}
                  >
                    <MdLocationPin
                      size={18}
                      style={{ marginRight: 4, flexShrink: 0 }}
                    />
                    <p style={{ fontSize: 16, fontWeight: 500 }}>
                      {modalData?.facilityName ?? ""}
                    </p>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      margin: 4,
                      background: Colors.blue,
                      paddingBlock: 4,
                      paddingInline: 8,
                      borderRadius: 4,
                      color: "#fff",
                    }}
                  >
                    <p style={{ fontSize: 16, fontWeight: 500 }}>
                      {globalContext.sessionInfo.value?.provider
                        .providerType === "CRNA"
                        ? modalData.crnaRate === 0
                          ? "W2 Rate"
                          : `$${(modalData.crnaRate ?? 0 / 100).toFixed(0)}/hr)`
                        : null}
                      {globalContext.sessionInfo.value?.provider
                        .providerType === "MD/DO"
                        ? modalData.mddoRate === 0
                          ? "W2 Rate"
                          : `$${((modalData.mddoRate ?? 0) / 100).toFixed(
                              0
                            )}/hr`
                        : null}
                    </p>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "space-between",
                      flexWrap: "wrap",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        margin: 4,
                        background: "#eaeaea",
                        paddingBlock: 4,
                        paddingInline: 8,
                        borderRadius: 4,
                      }}
                    >
                      <MdEvent
                        size={18}
                        style={{
                          marginRight: 4,
                          display: "flex",
                          flexShrink: 0,
                        }}
                      />
                      <p style={{ fontSize: 16, fontWeight: 500, maxLines: 1 }}>
                        {new Date(modalData!.startDate).toLocaleString(
                          "en-US",
                          {
                            month: "long",
                            day: "numeric",
                            weekday: "short",
                          }
                        )}
                      </p>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        margin: 4,
                        background: "#eaeaea",
                        paddingBlock: 4,
                        paddingInline: 8,
                        borderRadius: 4,
                      }}
                    >
                      <MdSchedule
                        size={18}
                        style={{
                          marginRight: 4,
                          display: "flex",
                          flexShrink: 0,
                        }}
                      />
                      <p style={{ fontSize: 16, fontWeight: 500 }}>
                        {/* {new Date(modalData!.startDate).toLocaleTimeString(
                          "en-US",
                          {
                            hour: "numeric",
                            minute: "2-digit",
                          }
                        )} */}
                        {modalData.startTime}
                        {" - "}
                        {modalData.endTime}
                        {/* {new Date(modalData!.endDate).toLocaleTimeString(
                          "en-US",
                          {
                            hour: "numeric",
                            minute: "2-digit",
                          }
                        )} */}
                        {new Date(modalData!.startDate).getDay() ===
                        new Date(modalData!.endDate).getDay()
                          ? undefined
                          : "(+1)"}
                      </p>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        margin: 4,
                        background: "#eaeaea",
                        paddingBlock: 4,
                        paddingInline: 8,
                        borderRadius: 4,
                      }}
                    >
                      <MdSchedule size={18} style={{ marginRight: 4 }} />
                      <p style={{ fontSize: 16, fontWeight: 500 }}>
                        {(
                          moment(modalData!.endDate).diff(
                            moment(modalData!.startDate)
                          ) / 3600000
                        ).toFixed(0)}
                        &nbsp;hrs
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  style={{ height: 1, background: "#eaeaea", marginBlock: 12 }}
                />
                <div
                  style={{ display: "flex", flexDirection: "column", gap: 12 }}
                >
                  <div>
                    <p
                      style={{ marginBottom: 4, fontWeight: 700, fontSize: 14 }}
                    >
                      DESCRIPTION
                    </p>
                    <p>{modalData!.description}</p>
                  </div>
                  <div>
                    <p
                      style={{ marginBottom: 4, fontWeight: 700, fontSize: 14 }}
                    >
                      SPECIALTY REQUIREMENTS
                    </p>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "wrap",
                      }}
                    >
                      {modalData!.specialties.map(function (item) {
                        return (
                          <div
                            key={item.specialty}
                            style={{
                              background: "#eaeaea",
                              paddingBlock: 4,
                              paddingInline: 12,
                              borderRadius: 40,
                              margin: 2,
                            }}
                          >
                            <p style={{ fontWeight: 500, maxLines: 1 }}>
                              {item.specialty.charAt(0).toUpperCase()}
                              {item.specialty.slice(1)}
                            </p>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <div>
                    <p
                      style={{
                        marginBottom: 4,
                        fontWeight: 700,
                        fontSize: 14,
                      }}
                    >
                      CERTIFICATION REQUIREMENTS
                    </p>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "wrap",
                      }}
                    >
                      {[modalData!.certification].map(function (item) {
                        return (
                          <div
                            key={item}
                            style={{
                              background: "#eaeaea",
                              paddingBlock: 4,
                              paddingInline: 12,
                              borderRadius: 40,
                              margin: 2,
                            }}
                          >
                            <p style={{ fontWeight: 500, maxLines: 1 }}>
                              {item.charAt(0).toUpperCase()}
                              {item.slice(1)}
                            </p>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignSelf: "flex-end",
                  }}
                >
                  <ButtonUnstyled
                    style={{
                      cursor: "pointer",
                      marginTop: 4,
                      borderRadius: 4,
                      paddingBlock: 4,
                      paddingLeft: 12,
                      border: `1px solid ${Colors.blue}`,
                      background: Colors.border,
                      color: Colors.blue,
                      fontWeight: 600,
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",

                      boxShadow: "2px 2px 4px rgba(0,0,0,0.2)",
                    }}
                    onClick={() => setConfirmVisible(true)}
                  >
                    <p>CLAIM JOB</p>
                    <MdChevronRight size={20} style={{ marginLeft: 8 }} />
                  </ButtonUnstyled>
                </div>
              </div>
            </div>
          </div>
        </Dialog>
      ) : (
        <></>
      )}
      {modalData ? (
        <Dialog
          open={confirmVisible}
          onClose={() => setConfirmVisible(!confirmVisible)}
          disableScrollLock
          fullWidth
        >
          <div
            style={{
              background: "#fff",
              display: "flex",
              flexDirection: "column",
              borderRadius: 20,
              padding: 20,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                marginBottom: 12,
              }}
            >
              <p style={{ fontSize: 20, fontWeight: 700, margin: 0 }}>
                Please Confirm
              </p>
            </div>
            <div>
              <p style={{ fontSize: 14, fontWeight: 400 }}>
                Please confirm that you would like to claim this job at&nbsp;
                <b>{modalData!.facilityName}</b> on&nbsp;
                <b>
                  {new Date(modalData!.startDate).toLocaleDateString("en-US", {
                    month: "long",
                    day: "numeric",
                    weekday: "short",
                    year: "numeric",
                  })}
                </b>
                &nbsp; from{" "}
                <b>
                  {modalData.startTime} to {modalData.endTime}{" "}
                </b>
                {/* {new Date(modalData!.startDate).toLocaleString("en-US", {
                    hour: "numeric",
                    minute: "2-digit",
                  })}
                </b>
                &nbsp;to&nbsp;
                <b>
                  {new Date(modalData!.endDate).toLocaleString("en-US", {
                    hour: "numeric",
                    minute: "2-digit",
                  })}
                </b> */}
                .
              </p>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignSelf: "flex-end",
                marginTop: 12,
              }}
            >
              <ButtonUnstyled
                style={{
                  cursor: "pointer",
                  margin: 4,
                  border: "0px",
                  borderRadius: 4,
                  paddingBlock: 6,
                  paddingInline: 8,
                  background: "#fff",
                  color: "gray",
                  fontWeight: 600,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
                onClick={() => setConfirmVisible(!confirmVisible)}
              >
                <p>GO BACK</p>
              </ButtonUnstyled>
              <ButtonUnstyled
                disabled={loading}
                style={{
                  cursor: loading === false ? "pointer" : "default",
                  margin: 4,
                  borderRadius: 4,
                  paddingBlock: 6,
                  paddingInline: 8,
                  border: `1px solid ${Colors.blue}`,
                  background: Colors.border,
                  color: Colors.blue,
                  fontWeight: 600,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  boxShadow: "2px 2px 4px rgba(0,0,0,0.2)",
                }}
                onClick={() => {
                  // alert(modalData._id);
                  setLoading(true);
                  globalContext.api.claimJob
                    .call(
                      globalContext.callOptions(),
                      {},
                      {
                        jobId: modalData!._id,
                      }
                    )
                    .then((response) => {
                      if (response.status === 200) {
                        setConfirmVisible(false);
                        setLoading(false);
                        // setModalData(undefined);
                        setSuccessVisible(true);
                        setReloadHint((prev) => prev + 1);
                      } else {
                        alert("Unable to claim the job.");
                        setConfirmVisible(false);
                        setLoading(false);
                        // setModalData(undefined);
                        setSuccessVisible(false);
                        setReloadHint((prev) => prev + 1);
                      }
                    })
                    .catch((error) => {
                      alert("Error claiming the job.");
                    });
                }}
              >
                {loading === false ? (
                  <>
                    <p>CONFIRM CLAIM JOB</p>
                    <MdChevronRight size={20} style={{ marginLeft: 4 }} />
                  </>
                ) : (
                  <Oval
                    color={Colors.blue}
                    secondaryColor={Colors.blue}
                    width={20}
                    strokeWidth={8}
                  />
                )}
              </ButtonUnstyled>
            </div>
          </div>
        </Dialog>
      ) : (
        <></>
      )}
      {modalData ? (
        <Dialog
          open={successVisible}
          onClose={() => setSuccessVisible(!successVisible)}
        >
          <div
            style={{
              background: "#fff",
              display: "flex",
              flexDirection: "column",
              borderRadius: 20,
              padding: 20,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                marginBottom: 12,
              }}
            >
              <p style={{ fontSize: 20, fontWeight: 700, margin: 0 }}>
                Job Confirmed!
              </p>
            </div>
            <div>
              <p style={{ fontSize: 14, fontWeight: 400 }}>
                You're confirmed for a job at&nbsp;
                <b>{modalData!.facilityName}</b> on&nbsp;
                <b>
                  {new Date(modalData!.startDate).toLocaleDateString("en-US", {
                    month: "long",
                    day: "numeric",
                    weekday: "short",
                    year: "numeric",
                  })}
                </b>
                &nbsp; from{" "}
                <b>
                  {new Date(modalData!.startDate).toLocaleString("en-US", {
                    hour: "numeric",
                    minute: "2-digit",
                  })}
                </b>
                &nbsp; to&nbsp;
                <b>
                  {new Date(modalData!.endDate).toLocaleString("en-US", {
                    hour: "numeric",
                    minute: "2-digit",
                  })}
                </b>
                .
              </p>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignSelf: "flex-end",
                marginTop: 12,
              }}
            >
              <ButtonUnstyled
                style={{
                  cursor: "pointer",
                  margin: 4,
                  border: "0px",
                  borderRadius: 4,
                  paddingBlock: 6,
                  paddingInline: 8,
                  background: "#fff",
                  color: "gray",
                  fontWeight: 600,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
                onClick={() => navigate("/provider/jobs")}
              >
                <p>GO TO MY JOBS</p>
              </ButtonUnstyled>
              <ButtonUnstyled
                style={{
                  cursor: "pointer",
                  margin: 4,
                  borderRadius: 4,
                  paddingBlock: 6,
                  paddingInline: 8,
                  border: `1px solid ${Colors.blue}`,
                  background: Colors.border,
                  color: Colors.blue,
                  fontWeight: 600,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",

                  boxShadow: "2px 2px 4px rgba(0,0,0,0.2)",
                }}
                onClick={() => {
                  setConfirmVisible(false);
                  setModalData(undefined);
                  setSuccessVisible(false);
                }}
              >
                <p>SEE MORE JOBS</p>
              </ButtonUnstyled>
            </div>
          </div>
        </Dialog>
      ) : (
        <></>
      )}
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          flexDirection: "column",
          flex: 1,
          marginBottom: 320,
        }}
      >
        {globalContext.sessionInfo.value?.provider?.status === "approved" ? (
          <SmartTable<
            EndPointType<{
              required: {
                _id: "string";
                facilityId: "string";
                facilityName: "string";
                description: "string";
                type: "string";
                startDate: "string";
                endDate: "string";
                startTime: "string";
                endTime: "string";
                specialties: [
                  {
                    required: {
                      specialty: "string";
                    };
                  }
                ];
                certification: "string";
                createdAt: "string";
                claimed: "boolean";
              };
              optional: {
                mddoRate: "number";
                mddoTotal: "number";
                crnaRate: "number";
                crnaTotal: "number";
              };
            }>
          >
            key={reloadHint}
            columns={[
              {
                columnTitle: "Facility",
                columnKey: "Facility",
                sort: true,
                showColumn(windowWidth) {
                  return true;
                },
                cell(item, windowWidth) {
                  return <td>{item.facilityName}</td>;
                },
              },
              {
                columnTitle: "Date / Time",
                columnKey: "Date / Time",
                sort: true,
                showColumn(windowWidth) {
                  return true;
                },
                cell(item, windowWidth) {
                  return (
                    <td>
                      {new Date(item.startDate).toLocaleString([], {
                        month: "numeric",
                        day: "numeric",
                        weekday: "short",
                      })}
                      &nbsp;
                      {item.startTime}
                      {/* {new Date(item.startDate).toLocaleString([], {
                        hour: "numeric",
                        minute: "2-digit",
                      })} */}
                      {" to "}
                      {item.endTime}
                      {/* {new Date(item.endDate).toLocaleString([], {
                        hour: "numeric",
                        minute: "2-digit",
                      })} */}
                      {new Date(item.startDate).getDay() ===
                      new Date(item.endDate).getDay()
                        ? undefined
                        : "(+1)"}
                    </td>
                  );
                },
              },
              {
                columnTitle: "Duration",
                columnKey: "Duration",
                sort: true,
                showColumn(windowWidth) {
                  return windowWidth >= windowTrigger;
                },
                cell(item, windowWidth) {
                  return (
                    <td>
                      {Math.floor(
                        moment(item.endDate).diff(moment(item.startDate)) /
                          3600000
                      ).toFixed(0)}
                      h&nbsp;
                      {Math.round(
                        (Math.abs(
                          moment(item.endDate).diff(moment(item.startDate)) /
                            3600000
                        ) -
                          parseInt(
                            Math.floor(
                              moment(item.endDate).diff(
                                moment(item.startDate)
                              ) / 3600000
                            ).toFixed(0)
                          )) *
                          60
                      )}
                      m
                    </td>
                  );
                },
              },
              {
                columnTitle: "$ Total",
                columnKey: "$ Total",
                sort: true,
                showColumn(windowWidth) {
                  return windowWidth >= windowTrigger;
                },
                cell(item, windowWidth) {
                  return (
                    <td>
                      {globalContext.sessionInfo.value?.provider
                        .providerType === "MD/DO"
                        ? item.mddoRate === 0
                          ? "W2 Rate"
                          : Math.floor(
                              (item.mddoRate! *
                                moment(item!.endDate).diff(
                                  moment(item!.startDate)
                                )) /
                                3600000
                            ).toLocaleString("en-US") ?? null
                        : null}
                      {globalContext.sessionInfo.value?.provider
                        .providerType === "CRNA"
                        ? item.crnaRate === 0
                          ? "W2 Rate"
                          : Math.floor(
                              (item.crnaRate! *
                                moment(item!.endDate).diff(
                                  moment(item!.startDate)
                                )) /
                                3600000
                            ).toLocaleString("en-US") ?? null
                        : null}
                      {/* {Math.floor(
                        (sumRate(
                          globalContext.sessionInfo.value!.provider!
                            .providerType ?? "MD/DO",
                          [
                            item.mddoRate
                              ? {
                                  providerType: "MD/DO",
                                  baseRate: item.mddoRate,
                                  rateBonus: 0,
                                }
                              : undefined,
                            item.crnaRate
                              ? {
                                  providerType: "CRNA",
                                  baseRate: item.crnaRate,
                                  rateBonus: 0,
                                }
                              : undefined,
                          ].filter((x) => !!x) as {
                            providerType: string;
                            baseRate: number;
                            rateBonus: number;
                          }[]
                        ) *
                          moment(item!.endDate).diff(moment(item!.startDate))) /
                          3600000
                      ).toLocaleString("en-US")} */}
                    </td>
                  );
                },
              },
              {
                columnTitle: "$/Hour",
                columnKey: "$/Hour",
                sort: true,
                showColumn(windowWidth) {
                  return windowWidth >= windowTrigger;
                },
                cell(item, windowWidth) {
                  return (
                    <td>
                      {globalContext.sessionInfo.value?.provider
                        .providerType === "MD/DO"
                        ? item.mddoRate === 0
                          ? "W2 Rate"
                          : `$${item.mddoRate!}/hr`
                        : null}
                      {globalContext.sessionInfo.value?.provider
                        .providerType === "CRNA"
                        ? item.crnaRate === 0
                          ? "W2 Rate"
                          : `$${item.crnaRate!}/hr`
                        : null}
                    </td>
                  );
                },
              },
              {
                columnTitle: "",
                columnKey: "action",
                sort: false,
                showColumn(windowWidth) {
                  return true;
                },
                cell(item, windowWidth) {
                  return (
                    <td>
                      <MdChevronRight size={20} />
                    </td>
                  );
                },
              },
            ]}
            emptyContent={(windowWidth: number) => {
              return (
                <div
                  style={{
                    padding: 20,
                    background: Colors.border,
                    border: `1px solid ${Colors.blue}`,
                    color: Colors.blue,
                    fontWeight: 600,
                    borderRadius: 8,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <FaExclamationCircle
                    size={24}
                    style={{ marginRight: 12, flexShrink: 0 }}
                  />
                  <p>
                    Looks like there aren't any open job listings at your
                    credentialed facilities. If you want, you can&nbsp;
                    <Link
                      style={{ color: Colors.blue, cursor: "pointer" }}
                      href={"/provider/facilities"}
                    >
                      get credentialed at more hospitals!
                      <MdChevronRight size={12} style={{ marginLeft: 8 }} />
                    </Link>
                  </p>
                </div>
              );
            }}
            headerContent={(windowWidth: number) => {
              return (
                <>
                  <h1
                    style={{
                      margin: 0,
                      fontSize: 32,
                      marginBottom: 12,
                      fontWeight: "700",
                    }}
                  >
                    Job Board
                  </h1>
                  <p style={{ color: Colors.lightGray }}>
                    These are open jobs that fit your criteria.
                  </p>
                </>
              );
            }}
            rowKey={(row: any) => {
              return row._id;
            }}
            pageSizeOptions={[100]}
            defaultPageSize={100}
            defaultSortedColumn={"Date / Time"}
            defaultSortedDirection={1}
            rowClickCallback={(rowData) => {
              setModalData(rowData);
            }}
            load={async (
              page: number,
              pageSize: number,
              sortColumn: string | undefined,
              sortedDirection: number
            ) => {
              const result = await globalContext.api.providerJobListings.call(
                globalContext.callOptions(),
                {},
                {
                  pagination: {
                    pageSize: pageSize,
                    page: page,
                  },
                  ...(sortColumn
                    ? {
                        sort: {
                          sortBy: sortColumn,
                          sortOrder: sortedDirection === 1 ? "asc" : "desc",
                        },
                      }
                    : {}),
                }
              );

              if (result.status === 200) {
                return {
                  data: result.data!.jobs ?? [],
                  total: result.data!.size ?? 0,
                };
              } else {
                return {
                  error: result.error,
                  data: [],
                  total: 0,
                };
              }
            }}
          />
        ) : (
          <>
            <h1
              style={{
                margin: 0,
                fontSize: 32,
                marginBottom: 12,
                fontWeight: "700",
              }}
            >
              Job Board
            </h1>
            <p style={{ color: Colors.lightGray, marginBottom: 24 }}>
              These are open jobs that fit your criteria.
            </p>
            {globalContext.sessionInfo.value?.provider?.status ===
            "approved" ? null : (
              <div
                style={{
                  padding: 20,
                  background: Colors.border,
                  border: `1px solid ${Colors.blue}`,
                  color: Colors.blue,
                  fontWeight: 600,
                  borderRadius: 8,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginBottom: 8,
                }}
              >
                <FaExclamationCircle
                  style={{
                    color: Colors.blue,
                    marginRight: 12,
                    flexShrink: 0,
                  }}
                  size={24}
                />
                <p>
                  Welcome to Pagerr! We're still validating your account, so for
                  now you won't be able to claim shifts. Expect an email from us
                  once your account has been validated.
                </p>
              </div>
            )}
          </>
        )}
      </div>
      {/* <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          flexDirection: "column",
          flex: 1,
          marginBottom: 320,
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "flex-end",
            justifyContent: "space-between",
            flexDirection: "row",
            marginBottom: 24,
            flexWrap: "wrap",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "flex-start",
              flexDirection: "column",
            }}
          >
            <h1
              style={{
                margin: 0,
                fontSize: 32,
                marginBottom: 12,
                fontWeight: "700",
              }}
            >
              Job Board
            </h1>
            <p style={{ color: Colors.lightGray }}>
              These are open jobs that fit your criteria.
            </p>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: 12,
              alignItems: "center",
              color: Colors.lightGray,
            }}
          >
            <p style={{ fontWeight: 600, fontSize: 14 }}>1-12 of 100</p>
            <ButtonUnstyled className="pageButton" disabled>
              <FaChevronLeft />
            </ButtonUnstyled>
            <ButtonUnstyled className="pageButton">
              <FaChevronRight />
            </ButtonUnstyled>
          </div>
        </div>
        {globalContext.sessionInfo.value?.provider?.status === "pending" ? (
          <div
            style={{
              padding: 20,
              background: Colors.border,
              border: `1px solid ${Colors.blue}`,
              color: Colors.blue,
              fontWeight: 600,
              borderRadius: 8,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginBottom: 8,
            }}
          >
            <FaExclamationCircle
              style={{
                color: Colors.blue,
                marginRight: 12,
                flexShrink: 0,
              }}
              size={24}
            />
            <p>
              Welcome to Pagerr! We're still validating your account, so for now
              you won't be able to claim the jobs listed below. Expect an email
              from us once your account has been validated.
            </p>
          </div>
        ) : (
          <></>
        )}
        {jobs === undefined ? (
          <PagerrLoader />
        ) : (
          <>
            {jobs.jobs?.length === 0 ? (
              <></>
            ) : (
              <table
                style={{
                  borderCollapse: "collapse",
                  tableLayout: "fixed",
                  marginBottom: 240,
                }}
              >
                <thead style={{ marginBottom: 8 }}>
                  <tr
                    style={{
                      display: "table-row",
                      textAlign: "left",
                    }}
                  >
                    <th
                      style={{
                        borderTopLeftRadius: 8,
                        borderBottomLeftRadius: 8,
                        cursor: "default",
                      }}
                    >
                      <FilterButton
                        label="Facility"
                        onSortAsc={() => alert("Sort Ascending")}
                        onSortDesc={() => alert("Sort Descending")}
                        active={true}
                        ascending={true}
                      />
                    </th>
                    <th style={{ cursor: "default" }}>
                      <FilterButton
                        label="Date"
                        onSortAsc={() => alert("Sort Ascending")}
                        onSortDesc={() => alert("Sort Descending")}
                        active={false}
                        ascending={true}
                      />
                    </th>
                    {mobile() === false ? (
                      <th style={{ cursor: "default" }}>
                        <FilterButton
                          label="Time"
                          onSortAsc={() => alert("Sort Ascending")}
                          onSortDesc={() => alert("Sort Descending")}
                          active={false}
                          ascending={true}
                        />
                      </th>
                    ) : null}
                    {mobile() === false ? (
                      <th style={{ cursor: "default" }}>
                        <FilterButton
                          label="Duration"
                          onSortAsc={() => alert("Sort Ascending")}
                          onSortDesc={() => alert("Sort Descending")}
                          active={false}
                          ascending={true}
                        />
                      </th>
                    ) : null}
                    <th style={{ cursor: "default" }}>
                      <FilterButton
                        label="$ Total"
                        onSortAsc={() => alert("Sort Ascending")}
                        onSortDesc={() => alert("Sort Descending")}
                        active={false}
                        ascending={true}
                      />
                    </th>
                    {mobile() === false ? (
                      <th style={{ cursor: "default" }}>
                        <FilterButton
                          label="$/Hour"
                          onSortAsc={() => alert("Sort Ascending")}
                          onSortDesc={() => alert("Sort Descending")}
                          active={false}
                          ascending={true}
                        />
                      </th>
                    ) : null}
                    <th
                      style={{
                        borderTopRightRadius: 8,
                        borderBottomRightRadius: 8,
                        cursor: "default",
                      }}
                    />
                  </tr>
                </thead>
                <tbody>
                  {(jobs?.jobs ?? []).map(function (item) {
                    return (
                      <tr
                        key={item._id}
                        style={{
                          borderBottom: "1px solid #eaeaea",
                          textAlign: "left",
                          marginBlock: 5,
                          margin: 10,
                          padding: 10,
                          display: "table-row",
                        }}
                      >
                        <td>{item.facilityName}</td>
                        <td>
                          <div style={{ display: "flex" }}>
                            <p>
                              {new Date(item.startDate).toLocaleString([], {
                                month: "numeric",
                                day: "numeric",
                                weekday: "short",
                              })}
                              &nbsp;
                            </p>
                            {new Date(item.startDate).getDay() ===
                            new Date(item.endDate).getDay()
                              ? undefined
                              : "(+1)"}
                          </div>
                        </td>
                        {mobile() === false ? (
                          <td>
                            {new Date(item.startDate).toLocaleString([], {
                              hour: "numeric",
                              minute: "2-digit",
                            })}
                            &nbsp;-&nbsp;
                            {new Date(item.endDate).toLocaleString([], {
                              hour: "numeric",
                              minute: "2-digit",
                            })}
                          </td>
                        ) : null}
                        {mobile() === false ? (
                          <td>
                            {Math.floor(
                              moment(item.endDate).diff(
                                moment(item.startDate)
                              ) / 3600000
                            ).toFixed(0)}
                            h&nbsp;
                            {Math.round(
                              (Math.abs(
                                moment(item.endDate).diff(
                                  moment(item.startDate)
                                ) / 3600000
                              ) -
                                parseInt(
                                  Math.floor(
                                    moment(item.endDate).diff(
                                      moment(item.startDate)
                                    ) / 3600000
                                  ).toFixed(0)
                                )) *
                                60
                            )}
                            m
                          </td>
                        ) : null}
                        <td>
                          $
                          {Math.floor(
                            (sumRate(
                              globalContext.sessionInfo.value!.provider!
                                .providerType,
                              item.criteria
                            ) *
                              moment(item!.endDate).diff(
                                moment(item!.startDate)
                              )) /
                              3600000
                          ).toLocaleString("en-US")}
                        </td>
                        {mobile() === false ? (
                          <td>
                            $
                            {sumRate(
                              globalContext.sessionInfo.value!.provider!
                                .providerType,
                              item.criteria
                            )}
                            /hr
                          </td>
                        ) : null}
                        <td
                          style={{
                            textAlign: "right",
                          }}
                        >
                          <MdChevronRight
                            size={22}
                            style={{
                              borderRadius: 20,
                              color: Colors.blue,
                            }}
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            )}
            {jobs.jobs?.length === 0 ? (
              <div
                style={{
                  padding: 20,
                  background: Colors.border,
                  border: `1px solid ${Colors.blue}`,
                  color: Colors.blue,
                  fontWeight: 600,
                  borderRadius: 8,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginBottom: 8,
                }}
              >
                <FaExclamationCircle
                  size={24}
                  style={{ marginRight: 12, flexShrink: 0 }}
                />
                <p>
                  Looks like there aren't any open job listings at your
                  credentialed facilities. If you want, you can&nbsp;
                  <Link
                    style={{ color: Colors.blue, cursor: "pointer" }}
                    href={"/provider/facilities"}
                  >
                    get credentialed at more hospitals!
                    <MdChevronRight size={12} style={{ marginLeft: 8 }} />
                  </Link>
                </p>
              </div>
            ) : (
              <></>
            )}
          </>
        )}
      </div> */}
    </>
  );
}
