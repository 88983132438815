import { Link } from "@mui/material";
import { ButtonUnstyled } from "@mui/base";
import { useState } from "react";
import { MdVisibility, MdVisibilityOff } from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";
import { Colors } from "../Colors";
import { useGlobals } from "../contexts/useGlobals";
import { useSignup } from "../contexts/useSignup";
import { Oval } from "react-loader-spinner";
import {
  ApiCallOptions,
  AuthProvider,
  FailureHandler,
} from "@xaratan/pagerr-common";
import { useGetState } from "../helpers/useGetState";

export default function FacilitySignupAccountInfo() {
  // const signup = useSignup();
  const globals = useGlobals();
  const params = useParams();
  const navigate = useNavigate();

  const signupToken = params.signupToken;

  const [passwordRules] = useGetState(
    globals.api.passwordRules,
    globals.callOptions(),
    {}
  );

  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");

  const [loading, setLoading] = useState<boolean>(false);

  function validateFirstName(): string {
    if (firstName.length === 0) {
      return "First name is required";
    }

    return "";
  }

  function validateLastName(): string {
    if (lastName.length === 0) {
      return "Last name is required";
    }

    return "";
  }

  function validatePassword(): string[] {
    if (passwordRules?.rules) {
      const failures: string[] = [];

      for (let r of passwordRules.rules) {
        if (
          Array.from(password.matchAll(new RegExp(r.regex, "g"))).length <
          r.count
        ) {
          failures.push(r.message);
        }
      }

      return failures;
    }

    return [];
  }

  function validateConfirmPassword(): string {
    if (password === confirmPassword) {
      return "";
    }

    return "Passwords must match";
  }

  function validatePhone(): string {
    if (phone.length === 10) {
      return "";
    }
    return "Please enter a phone number with no dashes or spaces.";
  }

  function validate() {
    return !(
      !!validateFirstName() ||
      !!validateLastName() ||
      validatePassword().length !== 0 ||
      !!validateConfirmPassword() ||
      !!validatePhone()
    );
  }

  const [passwordVisible, setPasswordVisible] = useState<boolean>(false);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <p
        style={{
          textAlign: "right",
          fontSize: 12,
          fontWeight: 700,
          color: "#fff",
          paddingBlock: 4,
          paddingInline: 8,
          borderRadius: 8,
          background: Colors.blue,
          alignSelf: "flex-end",
        }}
      >
        Step 1/1
      </p>
      <h3 style={{ margin: 0 }}>Welcome to Pagerr!</h3>
      <p style={{ marginBottom: 24 }}>
        Fill out your account info below to get signed up.
      </p>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 12,
          marginBottom: 20,
        }}
      >
        <label>
          First Name:
          <input
            type="text"
            onChange={(evt) => setFirstName(evt.currentTarget.value)}
            value={firstName}
            disabled={loading}
            placeholder="Jane"
          />
          <p
            style={{
              minHeight: 12,
              fontWeight: 600,
              color: Colors.blue,
              marginInline: 8,
            }}
          >
            {validateFirstName()}
          </p>
        </label>
        <label>
          Last name:
          <input
            type="text"
            onChange={(evt) => setLastName(evt.currentTarget.value)}
            value={lastName}
            disabled={loading}
            placeholder="Smith"
          />
          <p
            style={{
              minHeight: 12,
              fontWeight: 600,
              color: Colors.blue,
              marginInline: 8,
            }}
          >
            {validateLastName()}
          </p>
        </label>
        <label>
          Phone Number
          <input
            type="text"
            maxLength={10}
            placeholder="000-000-0000"
            value={phone}
            onChange={(e) => setPhone(e.currentTarget.value)}
            disabled={loading}
          />
          <p
            style={{
              minHeight: 12,
              fontWeight: 600,
              color: Colors.blue,
              marginInline: 8,
            }}
          >
            {validatePhone()}
          </p>
        </label>

        <label>
          Password:
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              contain: "layout",
            }}
          >
            <input
              type={passwordVisible === true ? "text" : "password"}
              onChange={(evt) => {
                setPassword(evt.currentTarget.value);

                if (evt.currentTarget.value === confirmPassword) {
                  setPassword(evt.currentTarget.value);
                }
              }}
              value={password}
              disabled={loading}
              style={{ flex: 1 }}
            />
            {passwordVisible === false ? (
              <MdVisibility
                size={20}
                onClick={() => setPasswordVisible(!passwordVisible)}
                style={{
                  color: Colors.blue,
                  position: "absolute",
                  right: 8,
                }}
              />
            ) : (
              <MdVisibilityOff
                size={20}
                onClick={() => setPasswordVisible(!passwordVisible)}
                style={{
                  color: Colors.blue,
                  position: "absolute",
                  right: 8,
                }}
              />
            )}
          </div>
          <p
            style={{
              minHeight: 12,
              fontWeight: 600,
              color: Colors.blue,
              marginInline: 8,
            }}
          >
            <ul>
              {validatePassword().map((failure) => {
                return <li>{failure}</li>;
              })}
            </ul>
          </p>
        </label>
        <label>
          Confirm Password:
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              contain: "layout",
            }}
          >
            <input
              type={passwordVisible === true ? "text" : "password"}
              onChange={(evt) => {
                setConfirmPassword(evt.currentTarget.value);

                if (evt.currentTarget.value === password) {
                  setPassword(evt.currentTarget.value);
                }
              }}
              value={confirmPassword}
              style={{ flex: 1 }}
              disabled={loading}
            />
            {passwordVisible === false ? (
              <MdVisibility
                size={20}
                onClick={() => setPasswordVisible(!passwordVisible)}
                style={{
                  color: Colors.blue,
                  position: "absolute",
                  right: 8,
                }}
              />
            ) : (
              <MdVisibilityOff
                size={20}
                onClick={() => setPasswordVisible(!passwordVisible)}
                style={{
                  color: Colors.blue,
                  position: "absolute",
                  right: 8,
                }}
              />
            )}
          </div>
          <p
            style={{
              minHeight: 12,
              fontWeight: 600,
              color: Colors.blue,
              marginInline: 8,
            }}
          >
            {validateConfirmPassword()}
          </p>
        </label>
      </div>
      {loading === true ? (
        <div
          style={{
            display: "flex",
            flex: 1,
            // background: "red",
            justifyContent: "center",
          }}
        >
          <Oval
            color={Colors.blue}
            secondaryColor={Colors.blue}
            height={20}
            strokeWidth={8}
          />
        </div>
      ) : (
        <ButtonUnstyled
          onClick={() => {
            setLoading(true);
            const ops: ApiCallOptions = {
              authProvider: AuthProvider.null(),
              failureHandler: FailureHandler.null(),
              baseUrl: "",
            };
            globals.api.facilityOnboarding
              .call(
                ops,
                {},
                {
                  firstName: firstName,
                  lastName: lastName,
                  password: password,
                  phone: phone,
                  token: signupToken!,
                }
              )
              .then((response) => {
                if (response.status === 200) {
                  const sessionKey = response.data?.session ?? "";

                  globals.token.setValue(sessionKey);

                  globals.api.sessionInfo
                    .call(globals.callOptions(), {})
                    .then((response) => {
                      setLoading(false);

                      if (response.status === 200) {
                        globals.sessionInfo.setValue(response.data);
                        setFirstName("");
                        setLastName("");
                        setPassword("");
                        setPhone("");

                        navigate("/facility/listings");
                      } else {
                        alert("Error logging in.");
                      }
                    });
                } else {
                  alert("Error creating account.");
                }
              });
          }}
          style={{
            margin: 12,
            borderRadius: 4,
            padding: 12,
            fontSize: 14,
            color: validate() === true ? Colors.blue : "lightgray",
            fontWeight: "600",
            background:
              validate() === true ? Colors.blueBrightPrimary : "#eaeaea",
            border:
              validate() === true
                ? `1px solid ${Colors.blue}`
                : "1px solid lightgray",
            cursor: validate() === true ? "pointer" : undefined,
          }}
          disabled={!validate()}
        >
          Continue
        </ButtonUnstyled>
      )}
      <p style={{ fontSize: 16, margin: 12 }}>
        By signing up you agree to the&nbsp;
        <Link style={{ cursor: "pointer" }}>Terms and Conditions</Link>
        &nbsp;and&nbsp;
        <Link style={{ cursor: "pointer" }}>Privacy Policy</Link>.
      </p>
      <p style={{ fontSize: 16, margin: 12 }}>
        Already have an account?&nbsp;<Link href="/login">Login</Link>.
      </p>
    </div>
  );
}
