import { ButtonUnstyled } from "@mui/base";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { Colors } from "../Colors";
import { Dialog } from "@mui/material";
import { MdClose } from "react-icons/md";
import { Oval } from "react-loader-spinner";
import { useGlobals } from "../contexts/useGlobals";

export default function AdminAddManager() {
  const navigate = useNavigate();
  const globals = useGlobals();

  const [email, setEmail] = useState<string>("");

  function validateEmail(): string {
    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      return "Please enter a valid email address";
    }

    return "";
  }

  const [response, setResponse] = useState<
    | undefined
    | {
        type: "Error" | "Success";
        message:
          | "An invitation has been sent!"
          | "There has been an error. Please try again.";
      }
  >(undefined);

  const [loading, setLoading] = useState<boolean>(false);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-start",
        flexDirection: "column",
        flex: 1,
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "flex-start",
          flexDirection: "column",
          marginBottom: 24,
        }}
      >
        <h1
          style={{
            margin: 0,
            fontSize: 32,
            marginBottom: 12,
            fontWeight: "700",
          }}
        >
          Add Manager
        </h1>
        <p style={{ color: Colors.lightGray }}>
          Add a facility manager to Pagerr by entering their email below.
        </p>
      </div>
      <div style={{ maxWidth: 620 }}>
        <label>
          Email
          <input
            type={"email"}
            value={email}
            onChange={(e) => setEmail(e.currentTarget.value)}
            disabled={loading}
          />
          <p style={{ height: 20, marginInline: 8 }}>{validateEmail()}</p>
        </label>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            alignItems: "flex-start",
          }}
        >
          {loading === true ? (
            <Oval
              color={Colors.blue}
              secondaryColor={Colors.blue}
              height={20}
              strokeWidth={8}
            />
          ) : (
            <ButtonUnstyled
              disabled={!!validateEmail()}
              style={{
                cursor: validateEmail() === "" ? "pointer" : undefined,
                borderRadius: 4,
                paddingBlock: 6,
                paddingInline: 20,
                fontWeight: 600,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                background: Colors.border,
                color: validateEmail() === "" ? Colors.blue : "lightgray",
                border:
                  validateEmail() === ""
                    ? `1px solid ${Colors.blue}`
                    : `1px solid lightgray`,
                boxShadow:
                  validateEmail() === ""
                    ? "2px 2px 4px rgba(0,0,0,0.2)"
                    : "none",
              }}
              onClick={() => {
                setLoading(true);

                globals.api.facilityOnboardingInit
                  .call(
                    globals.callOptions(),
                    {},
                    {
                      email: email,
                    }
                  )
                  .then((result) => {
                    if (result.status === 200) {
                      //TODO: Joe show whatever you want for success
                      setResponse({
                        message: "An invitation has been sent!",
                        type: "Success",
                      });
                    } else {
                      //TODO: Joe show whatever you want for failure
                      const errorMessage = result.error ?? "Unknown error";
                      setResponse({
                        message: "There has been an error. Please try again.",
                        type: "Error",
                      });
                    }
                    setLoading(false);
                  });
              }}
            >
              Add Facility Manager
            </ButtonUnstyled>
          )}
        </div>
      </div>
      <Dialog
        open={!!response}
        fullWidth
        onClose={() => {
          setResponse(undefined);
          setLoading(false);
          setEmail("");
        }}
      >
        <div
          style={{
            padding: 20,
            background: "#fff",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flex: 1,
              justifyContent: "space-between",
              alignItems: "flex-start",
            }}
          >
            <p style={{ fontSize: 24, fontWeight: 700, color: Colors.green }}>
              {response?.type}
            </p>
            <MdClose
              style={{ cursor: "pointer" }}
              onClick={() => {
                setResponse(undefined);
                setLoading(false);
                setEmail("");
              }}
              size={24}
            />
          </div>
          <p style={{ fontSize: 16, fontWeight: 500, marginBottom: 12 }}>
            {response?.message}
          </p>
        </div>
      </Dialog>
    </div>
  );
}
