import {
  ApiCallOptions,
  AuthProvider,
  EndPointType,
  FailureHandler,
  initApi,
  SessionInfoResponseDefinition,
} from "@xaratan/pagerr-common";
import { ApiEndPoints } from "@xaratan/pagerr-common/dist/api/ApiEndPoints";
import { AxiosRequestConfig } from "axios";
import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import AxiosHttpRequest from "../helpers/AxiosHttpRequest";

const api = initApi(new AxiosHttpRequest());

export type StateVariable<T> = {
  value: T;
  setValue: (value: T | ((value: T) => T)) => void;
};

function nullStateVariable<T>(value: T): StateVariable<T> {
  let obj: any = {};

  obj.value = value;

  obj.setValue = (value: T) => {
    obj.value = value;
  };

  return obj;
}

export type GlobalStateType = {
  token: StateVariable<string | undefined>;
  redirect: StateVariable<
    | {
        pathname: string;
        search: string;
      }
    | undefined
  >;
  sessionInfo: StateVariable<
    EndPointType<SessionInfoResponseDefinition> | undefined
  >;
  ready: StateVariable<boolean>;
};

/// Shaun's code

function callOptions(
  failureHandler?: FailureHandler,
  authProvider?: AuthProvider
): ApiCallOptions {
  return {
    baseUrl: "",
    authProvider: authProvider,
    failureHandler: failureHandler,
  };
}

export type GlobalStateAccessor = {
  api: ApiEndPoints;
  callOptions: typeof callOptions;
  token: StateVariable<string | undefined>;
  redirect: StateVariable<
    | {
        pathname: string;
        search: string;
      }
    | undefined
  >;
  sessionInfo: StateVariable<
    EndPointType<SessionInfoResponseDefinition> | undefined
  >;
  ready: React.MutableRefObject<boolean>;
};

export function initializeGlobalState(): GlobalStateType {
  return {
    token: nullStateVariable<string | undefined>(undefined),
    redirect: nullStateVariable<
      | {
          pathname: string;
          search: string;
        }
      | undefined
    >(undefined),
    ready: nullStateVariable<boolean>(false),
    sessionInfo: nullStateVariable<
      EndPointType<SessionInfoResponseDefinition> | undefined
    >(undefined),
  };
}

function safeJSONParse<T>(text: string, fallback: T) {
  try {
    return JSON.parse(text);
  } catch (error) {
    return fallback;
  }
}

class DefaultAuthProvider extends AuthProvider {
  constructor(
    private authTokenSource: () => string | undefined,
    private setStateAuth: (token: string | undefined) => void
  ) {
    super();
  }

  addAuth(request: AxiosRequestConfig): AxiosRequestConfig {
    if (request.headers) {
      request.headers.authorization = `Bearer ${this.authTokenSource()}`;
    } else {
      request.headers = {
        authorization: `Bearer ${this.authTokenSource()}`,
      };
    }

    return request;
  }

  setAuth(response: any) {
    this.setStateAuth(response.token);
  }
}

const GlobalStateContext = createContext<GlobalStateAccessor>({
  //Temporary data
  api: api,
  callOptions: callOptions,
  token: nullStateVariable<string | undefined>(undefined),
  redirect: nullStateVariable<
    | {
        pathname: string;
        search: string;
      }
    | undefined
  >(undefined),
  ready: {} as any,
  sessionInfo: {} as any,
});

function useCreateStateVariable<T>(value: T): StateVariable<T> {
  const [state, setState] = useState<T>(value);

  return {
    value: state,
    setValue: setState,
  };
}

function useSaveEffect<T>(
  ready: boolean,
  key: string,
  value: T,
  toString: (value: T) => string
) {
  useEffect(() => {
    if (ready) {
      //The state has changed!
      const saveState = async () => {
        if (value === undefined) {
          localStorage.removeItem(key);
        } else {
          localStorage.setItem(key, toString(value));
        }
      };

      saveState().catch((error) => {
        console.log("Error saving state: " + error);
      });
    }
  }, [value]);
}

function nullToUndefined<T>(value: T | null | undefined): undefined | T {
  return value === null ? undefined : value;
}

function getGlobalStateProvider() {
  const GlobalStateProvider = (props: { children: ReactNode }) => {
    const globalContext = useContext(GlobalStateContext);

    //init all of the state variables
    globalContext.token = useCreateStateVariable<string | undefined>(
      nullToUndefined(localStorage.getItem("token"))
    );
    globalContext.redirect = useCreateStateVariable<
      { pathname: string; search: string } | undefined
    >(
      localStorage.getItem("redirect")
        ? safeJSONParse(localStorage.getItem("redirect")!, undefined)
        : undefined
    );
    globalContext.sessionInfo = useCreateStateVariable<
      EndPointType<SessionInfoResponseDefinition> | undefined
    >(
      localStorage.getItem("sessionInfo")
        ? safeJSONParse(localStorage.getItem("sessionInfo")!, undefined)
        : undefined
    );
    globalContext.ready = useRef<boolean>(false);

    const defaultAuthProvider = new DefaultAuthProvider(
      () => {
        return globalContext.token.value;
      },
      (authToken) => {
        globalContext.token.setValue(authToken);
      }
    );

    // useEffect(() => {
    //   if (globalContext.ready.current) {
    //     //The state has changed!
    //     const saveState = async () => {
    //       localStorage.setItem("token", globalContext.token.value ?? "");
    //     };

    //     saveState().catch((error) => {
    //       console.log("Error saving state: " + error);
    //     });
    //   }
    // }, [globalContext.token.value]);

    useSaveEffect(
      globalContext.ready.current,
      "token",
      globalContext.token.value,
      (value) => value ?? ""
    );
    useSaveEffect(
      globalContext.ready.current,
      "redirect",
      globalContext.redirect.value,
      (value) => JSON.stringify(value)
    );
    useSaveEffect(
      globalContext.ready.current,
      "sessionInfo",
      globalContext.sessionInfo.value,
      (value) => JSON.stringify(value)
    );

    useEffect(() => {
      globalContext.ready.current = true;
    }, []);

    return (
      <GlobalStateContext.Provider
        value={{
          token: globalContext.token,
          redirect: globalContext.redirect,
          ready: globalContext.ready,
          sessionInfo: globalContext.sessionInfo,
          api: api,
          callOptions: (
            failureHandler?: FailureHandler,
            authProvider?: AuthProvider
          ) => {
            return {
              baseUrl: "",
              authProvider: authProvider ?? defaultAuthProvider,
              failureHandler: failureHandler,
            };
          },
        }}
      >
        {props.children}
      </GlobalStateContext.Provider>
    );
  };

  return GlobalStateProvider;
}

const GlobalStateConsumer = (props: {
  children: (value: GlobalStateAccessor) => ReactNode;
}) => {
  return (
    <GlobalStateContext.Consumer>{props.children}</GlobalStateContext.Consumer>
  );
};

export { getGlobalStateProvider, GlobalStateConsumer, GlobalStateContext };
