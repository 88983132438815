import { ButtonUnstyled } from "@mui/base";
import { useNavigate } from "react-router-dom";
import { useState, useContext } from "react";
import { EndPointType } from "@xaratan/pagerr-common";
import { MdChevronRight, MdClose, MdEvent, MdSchedule } from "react-icons/md";
import {
  FaChevronRight,
  FaExclamationCircle,
  FaRegBuilding,
} from "react-icons/fa";
import { Dialog, Link } from "@mui/material";
import { Colors } from "../Colors";
import { GlobalStateContext } from "../contexts/GlobalStateContext";
import { SmartTable } from "./SmartTable";
import moment from "moment";
import { mobileTrigger } from "./MobileTrigger";

export default function FacilityJobListings() {
  const navigate = useNavigate();
  const globalContext = useContext(GlobalStateContext);

  const [cancelVisible, setCancelVisible] = useState(false);

  const [reloadHint, setReloadHint] = useState(0);

  const [modalData, setModalData] = useState<
    | EndPointType<{
        required: {
          _id: "string";
          facilityId: "string";
          facilityName: "string";
          description: "string";
          type: "string";
          startDate: "string";
          duration: "number";
          endDate: "string";
          startTime: "string";
          endTime: "string";
          specialties: [
            {
              required: {
                specialty: "string";
              };
            }
          ];
          certification: "string";
          createdAt: "string";
        };
        optional: {
          mddoRate: "number";
          mddoTotal: "number";
          crnaRate: "number";
          crnaTotal: "number";
          cancelledDate: "string";
          provider: {
            required: {
              providerId: "string";
              specialties: [
                {
                  required: {
                    specialty: "string";
                  };
                }
              ];
              address: {
                required: {
                  address1: "string";
                  address2: "string";
                  city: "string";
                  state: "string";
                  zip: "string";
                };
              };
              phone: "string";
              email: "string";
              firstName: "string";
              lastName: "string";
            };
            optional: {
              boardCertification: "string";
              providerType: "string";
            };
          };
        };
      }>
    | undefined
  >(undefined);

  const windowTrigger = mobileTrigger;

  return (
    <>
      <>
        {modalData ? (
          <Dialog
            open={cancelVisible}
            onClose={() => setCancelVisible(!cancelVisible)}
            disableScrollLock
            fullWidth
          >
            <div
              style={{
                background: "#fff",
                display: "flex",
                flexDirection: "column",
                borderRadius: 20,
                padding: 20,
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginBottom: 12,
                }}
              >
                <p style={{ fontSize: 20, fontWeight: 700, margin: 0 }}>
                  Confirm Cancel
                </p>
                {/* TODO (Joe): Loading state? */}
              </div>
              <div>
                <p style={{ fontSize: 14, fontWeight: 400 }}>
                  Are you sure you would like to cancel this job listing? It
                  will be removed from the job board immediately. This action
                  cannot be undone.
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignSelf: "flex-end",
                  marginTop: 12,
                }}
              >
                <ButtonUnstyled
                  style={{
                    cursor: "pointer",
                    margin: 6,
                    borderRadius: 4,
                    paddingBlock: 6,
                    paddingInline: 8,
                    background: "none",
                    color: "gray",
                    fontWeight: 600,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    border: "none",
                  }}
                  onClick={() => setCancelVisible(!cancelVisible)}
                >
                  <p>GO BACK</p>
                </ButtonUnstyled>
                <ButtonUnstyled
                  style={{
                    cursor: "pointer",
                    margin: 6,
                    borderRadius: 4,
                    paddingBlock: 6,
                    paddingInline: 8,
                    background: Colors.border,
                    color: Colors.blue,
                    fontWeight: 600,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    border: `1px solid ${Colors.blue}`,
                    boxShadow: "2px 2px 4px rgba(0,0,0,0.2)",
                  }}
                  onClick={() => {
                    globalContext.api.cancelJob
                      .call(
                        globalContext.callOptions(),
                        {},
                        {
                          jobId: modalData._id,
                        }
                      )
                      .then((result) => {
                        if (result.status === 200) {
                          alert("Job cancelled!");

                          setModalData(undefined);
                          setReloadHint((prev) => prev + 1);
                          setCancelVisible(false);
                        } else {
                          //TODO (Joe): couldn't cancel job for some reason - show error
                        }
                      });
                  }}
                >
                  CONFIRM CANCEL
                </ButtonUnstyled>
              </div>
            </div>
          </Dialog>
        ) : (
          <></>
        )}
        {modalData ? (
          <Dialog
            open={!!modalData}
            onClose={() => {
              setModalData(undefined);
            }}
            disableScrollLock
            fullWidth
          >
            <div
              style={{
                background: "#fff",
                display: "flex",
                flexDirection: "column",
                borderRadius: 20,
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  padding: 20,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    flex: 1,
                  }}
                >
                  <p style={{ fontSize: 20, fontWeight: 700, margin: 0 }}>
                    Job Details
                  </p>
                  <div
                    style={{
                      border: "none",
                      paddingInline: 12,
                      marginInline: 12,
                      borderRadius: 30,
                      paddingBlock: 4,
                      background: modalData!.cancelledDate
                        ? Colors.red
                        : !modalData.provider
                        ? Colors.blue
                        : Colors.blueBrightPrimary,
                      color: modalData!.cancelledDate
                        ? Colors.blueBrightPrimary
                        : !modalData.provider
                        ? Colors.blueBrightPrimary
                        : Colors.blue,
                      fontWeight: 600,
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                      alignSelf: "flex-start",
                    }}
                  >
                    <p style={{ fontSize: 14 }}>
                      {modalData.cancelledDate
                        ? "Canceled"
                        : modalData.provider === undefined
                        ? "Open"
                        : "Filled"}
                    </p>
                  </div>
                </div>
                <MdClose
                  size={24}
                  onClick={() => {
                    setModalData(undefined);
                  }}
                  style={{ cursor: "pointer" }}
                />
              </div>
              <div
                style={{
                  borderRadius: 18,
                  border: "1px solid #efeff4",
                  padding: 20,
                  flexDirection: "column",
                  listStyle: "none",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 4,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      background: Colors.blue,
                      paddingBlock: 4,
                      paddingInline: 8,
                      borderRadius: 4,
                      color: "#fff",
                      alignSelf: "flex-start",
                      gap: 4,
                    }}
                  >
                    <FaRegBuilding />
                    <p style={{ fontSize: 16, fontWeight: 500 }}>
                      {modalData.facilityName}
                    </p>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                      gap: "inherit",
                      flexWrap: "wrap",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        background: "#eaeaea",
                        paddingBlock: 4,
                        paddingInline: 8,
                        borderRadius: 4,
                        gap: 4,
                      }}
                    >
                      <MdEvent size={18} />
                      <p style={{ fontSize: 16, fontWeight: 500 }}>
                        {new Date(modalData!.startDate).toLocaleString(
                          "en-US",
                          {
                            month: "long",
                            day: "numeric",
                            weekday: "short",
                          }
                        )}
                      </p>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        background: "#eaeaea",
                        paddingBlock: 4,
                        paddingInline: 8,
                        borderRadius: 4,
                        gap: 4,
                      }}
                    >
                      <MdSchedule size={18} />
                      <p style={{ fontSize: 16, fontWeight: 500 }}>
                        {new Date(modalData.startDate).toLocaleString([], {
                          month: "numeric",
                          day: "numeric",
                          weekday: "short",
                        })}
                        &nbsp;
                        {modalData.startTime}
                        {" to "}
                        {modalData.endTime}{" "}
                        {new Date(modalData.startDate).getDay() ===
                        new Date(modalData.endDate).getDay() ? undefined : (
                          <span
                            style={{
                              color: Colors.blue,
                            }}
                          >
                            (+1)
                          </span>
                        )}
                      </p>
                    </div>
                  </div>
                  <div
                    style={{
                      height: 1,
                      background: "#eaeaea",
                      marginBlock: 12,
                    }}
                  />
                  <p
                    style={{
                      marginBottom: 4,
                      fontWeight: 500,
                      fontSize: 14,
                    }}
                  >
                    DESCRIPTION
                  </p>
                  <p style={{ marginBottom: 12 }}>{modalData.description}</p>
                  {modalData.provider === undefined ? null : (
                    <div style={{ marginBottom: 12 }}>
                      <p
                        style={{
                          marginBottom: 4,
                          fontWeight: 500,
                          fontSize: 14,
                        }}
                      >
                        Provider Information
                      </p>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          flexWrap: "wrap",
                        }}
                      >
                        <div
                          style={{
                            background: "#eaeaea",
                            paddingBlock: 4,
                            paddingInline: 12,
                            borderRadius: 40,
                            margin: 2,
                          }}
                        >
                          <p
                            style={{
                              fontWeight: 500,
                              fontSize: 16,
                            }}
                          >
                            {modalData.provider.firstName}&nbsp;
                            {modalData.provider.lastName},&nbsp;
                            {modalData.provider.providerType}
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                  {modalData.provider === undefined ? (
                    <div style={{ marginBottom: 12 }}>
                      <p
                        style={{
                          marginBottom: 4,
                          fontWeight: 500,
                          fontSize: 14,
                        }}
                      >
                        ACCEPTED PROVIDER TYPES
                      </p>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          flexWrap: "wrap",
                        }}
                      >
                        {[
                          modalData.mddoRate
                            ? {
                                providerType: "MD/DO",
                                baseRate: modalData.mddoRate,
                                rateBonus: 0,
                              }
                            : undefined,
                          modalData.crnaRate
                            ? {
                                providerType: "CRNA",
                                baseRate: modalData.crnaRate,
                                rateBonus: 0,
                              }
                            : undefined,
                        ]
                          .filter((x) => !!x)
                          .map(function (c) {
                            if (!c) return null;

                            return (
                              <div
                                key={c.providerType}
                                style={{
                                  background: "#eaeaea",
                                  paddingBlock: 4,
                                  paddingInline: 12,
                                  borderRadius: 40,
                                  margin: 2,
                                }}
                              >
                                <p style={{ fontWeight: 500, maxLines: 1 }}>
                                  {c.providerType.charAt(0).toUpperCase()}
                                  {c.providerType.slice(1)} ($
                                  {((c.baseRate + c.rateBonus) / 100).toFixed()}
                                  /hr)
                                </p>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  ) : null}
                  <div style={{ marginBottom: 12 }}>
                    <p
                      style={{ marginBottom: 4, fontWeight: 500, fontSize: 14 }}
                    >
                      SPECIALTY REQUIREMENTS
                    </p>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "wrap",
                      }}
                    >
                      {modalData!.specialties.map(function (item) {
                        return (
                          <div
                            key={item.specialty}
                            style={{
                              background: "#eaeaea",
                              paddingBlock: 4,
                              paddingInline: 12,
                              borderRadius: 40,
                              margin: 2,
                            }}
                          >
                            <p style={{ fontWeight: 500, maxLines: 1 }}>
                              {item.specialty.charAt(0).toUpperCase()}
                              {item.specialty.slice(1)}
                            </p>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <div style={{ marginBottom: 12 }}>
                    <p
                      style={{ marginBottom: 4, fontWeight: 500, fontSize: 14 }}
                    >
                      CERTIFICATION REQUIREMENTS
                    </p>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "wrap",
                      }}
                    >
                      {[modalData!.certification].map(function (e) {
                        return (
                          <div
                            key={e}
                            style={{
                              background: Colors.border,
                              paddingBlock: 4,
                              paddingInline: 12,
                              borderRadius: 40,
                              margin: 2,
                            }}
                          >
                            <p style={{ fontWeight: 500, maxLines: 1 }}>
                              {e.charAt(0).toUpperCase()}
                              {e.slice(1)}
                            </p>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  {modalData!.cancelledDate ? null : (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignSelf: "flex-end",
                      }}
                    >
                      <ButtonUnstyled
                        style={{
                          cursor: "pointer",
                          marginTop: 12,
                          borderRadius: 4,
                          paddingBlock: 6,
                          paddingInline: 8,
                          background: Colors.border,
                          color: Colors.blue,
                          fontWeight: 600,
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "space-between",
                          border: `1px solid ${Colors.blue}`,

                          boxShadow: "2px 2px 4px rgba(0,0,0,0.2)",
                        }}
                        onClick={() => setCancelVisible(!cancelVisible)}
                      >
                        CANCEL JOB
                      </ButtonUnstyled>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Dialog>
        ) : (
          <></>
        )}
      </>
      <SmartTable<
        EndPointType<{
          required: {
            _id: "string";
            facilityId: "string";
            facilityName: "string";
            description: "string";
            type: "string";
            startDate: "string";
            endDate: "string";
            duration: "number";
            startTime: "string";
            endTime: "string";
            specialties: [
              {
                required: {
                  specialty: "string";
                };
              }
            ];
            certification: "string";
            createdAt: "string";
          };
          optional: {
            mddoRate: "number";
            mddoTotal: "number";
            crnaRate: "number";
            crnaTotal: "number";
            cancelledDate: "string";
            provider: {
              required: {
                providerId: "string";
                specialties: [
                  {
                    required: {
                      specialty: "string";
                    };
                  }
                ];
                address: {
                  required: {
                    address1: "string";
                    address2: "string";
                    city: "string";
                    state: "string";
                    zip: "string";
                  };
                };
                phone: "string";
                email: "string";
                firstName: "string";
                lastName: "string";
              };
              optional: {
                boardCertification: "string";
                providerType: "string";
              };
            };
          };
        }>
      >
        key={reloadHint}
        columns={[
          {
            columnTitle: "Facility",
            columnKey: "Facility",
            sort: true,
            showColumn(windowWidth) {
              return true;
            },
            cell(item, windowWidth) {
              return <td>{item.facilityName}</td>;
            },
          },
          {
            columnTitle: "Date / Time",
            columnKey: "Date / Time",
            sort: true,
            showColumn(windowWidth) {
              return true;
            },
            cell(item, windowWidth) {
              return (
                <td>
                  <p>
                    {new Date(item.startDate).toLocaleString([], {
                      month: "numeric",
                      day: "numeric",
                      weekday: "short",
                    })}
                    &nbsp;
                    {item.startTime}
                    {/* {new Date(item.startDate).toLocaleString([], {
                        hour: "numeric",
                        minute: "2-digit",
                      })} */}
                    {" to "}
                    {item.endTime}
                    {/* {new Date(item.endDate).toLocaleString([], {
                        hour: "numeric",
                        minute: "2-digit",
                      })} */}
                    {new Date(item.startDate).getDay() ===
                    new Date(item.endDate).getDay() ? undefined : (
                      <span
                        style={{
                          color: Colors.blue,
                          fontSize: 12,
                          fontWeight: 900,
                        }}
                      >
                        (+1)
                      </span>
                    )}
                  </p>
                </td>
              );
            },
          },
          {
            columnTitle: "Duration",
            columnKey: "Duration",
            sort: true,
            showColumn(windowWidth) {
              return windowWidth >= windowTrigger;
            },
            cell(item, windowWidth) {
              return (
                <td>
                  {Math.floor(item.duration / 60).toFixed(0)}h&nbsp;
                  {Math.round(
                    Math.abs(item.duration) -
                      parseInt(Math.floor(item.duration).toFixed(0))
                  )}
                  m
                </td>
              );
            },
          },
          {
            columnTitle: "$ Total",
            columnKey: "$ Total",
            sort: false,
            showColumn(windowWidth) {
              return windowWidth >= windowTrigger;
            },
            cell(item, windowWidth) {
              return (
                <td>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    {item.provider === undefined ? (
                      [
                        item.mddoRate
                          ? {
                              providerType: "MD/DO",
                              baseRate: item.mddoRate,
                              rateBonus: 0,
                            }
                          : undefined,
                        item.crnaRate
                          ? {
                              providerType: "CRNA",
                              baseRate: item.crnaRate,
                              rateBonus: 0,
                            }
                          : undefined,
                      ]
                        .filter((x) => !!x)
                        .map(function (i) {
                          if (!i) return <></>;

                          return (
                            <p
                              key={i.providerType}
                              style={{
                                display: "flex",
                                maxLines: 1,
                                flexShrink: 0,
                              }}
                            >
                              {i.providerType.charAt(0).toUpperCase()}
                              {i.providerType.slice(1)}: $
                              {Math.floor(
                                (((i.baseRate + i.rateBonus) / 100) *
                                  moment(item.endDate).diff(
                                    moment(item.startDate)
                                  )) /
                                  3600000
                              ).toLocaleString("en-US")}
                            </p>
                          );
                        })
                    ) : (
                      <p
                        style={{
                          display: "flex",
                          maxLines: 1,
                          flexShrink: 0,
                        }}
                      >
                        {item.provider.providerType}:&nbsp;
                        {[
                          item.mddoRate
                            ? {
                                providerType: "MD/DO",
                                baseRate: item.mddoRate,
                                rateBonus: 0,
                              }
                            : undefined,
                          item.crnaRate
                            ? {
                                providerType: "CRNA",
                                baseRate: item.crnaRate,
                                rateBonus: 0,
                              }
                            : undefined,
                        ]
                          .filter((x) => !!x)
                          .map(function (i) {
                            if (!i) return <></>;

                            return item.provider!.providerType ===
                              i.providerType
                              ? `$${Math.floor(
                                  (((i.baseRate + i.rateBonus) / 100) *
                                    moment(item.endDate).diff(
                                      moment(item.startDate)
                                    )) /
                                    3600000
                                ).toLocaleString("en-US")}`
                              : null;
                          })}
                      </p>
                    )}
                  </div>
                </td>
              );
            },
          },
          {
            columnTitle: "$/Hour",
            columnKey: "$/Hour",
            sort: false,
            showColumn(windowWidth) {
              return windowWidth >= windowTrigger;
            },
            cell(item, windowWidth) {
              return (
                <td>
                  {" "}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    {item.provider === undefined ? (
                      [
                        item.mddoRate
                          ? {
                              providerType: "MD/DO",
                              baseRate: item.mddoRate,
                              rateBonus: 0,
                            }
                          : undefined,
                        item.crnaRate
                          ? {
                              providerType: "CRNA",
                              baseRate: item.crnaRate,
                              rateBonus: 0,
                            }
                          : undefined,
                      ]
                        .filter((x) => !!x)
                        .map(function (i) {
                          if (!i) return <></>;

                          return (
                            <p
                              key={i.providerType}
                              style={{
                                display: "flex",
                                maxLines: 1,
                                flexShrink: 0,
                              }}
                            >
                              {i.providerType.charAt(0).toUpperCase()}
                              {i.providerType.slice(1)}: $
                              {(
                                (i.baseRate + i.rateBonus) /
                                100
                              ).toLocaleString("en-US")}
                              /hour
                            </p>
                          );
                        })
                    ) : (
                      <p
                        style={{
                          display: "flex",
                          maxLines: 1,
                          flexShrink: 0,
                        }}
                      >
                        {item.provider.providerType}:&nbsp;
                        {[
                          item.mddoRate
                            ? {
                                providerType: "MD/DO",
                                baseRate: item.mddoRate,
                                rateBonus: 0,
                              }
                            : undefined,
                          item.crnaRate
                            ? {
                                providerType: "CRNA",
                                baseRate: item.crnaRate,
                                rateBonus: 0,
                              }
                            : undefined,
                        ]
                          .filter((x) => !!x)
                          .map(function (i) {
                            if (!i) return <></>;

                            return item.provider!.providerType ===
                              i.providerType
                              ? `$${Math.floor(
                                  (i.baseRate + i.rateBonus) / 100
                                ).toLocaleString("en-US")}/hour`
                              : null;
                          })}
                      </p>
                    )}
                  </div>
                </td>
              );
            },
          },
          {
            columnTitle: "Posted",
            columnKey: "Posted",
            sort: true,
            showColumn(windowWidth) {
              return windowWidth >= windowTrigger;
            },
            cell(item, windowWidth) {
              return (
                <td>
                  {`${new Date().getFullYear()}${new Date().getMonth()}${new Date().getDate()}` ===
                  `${new Date(item.createdAt).getFullYear()}${new Date(
                    item.createdAt
                  ).getMonth()}${new Date(item.createdAt).getDate()}`
                    ? "Today"
                    : new Date(item.createdAt).toLocaleDateString("en-US")}
                </td>
              );
            },
          },
          {
            columnTitle: "Status",
            columnKey: "Status",
            sort: true,
            showColumn(windowWidth) {
              return true;
            },
            cell(item, windowWidth) {
              return (
                <td style={{ alignItems: "flex-start" }}>
                  <div
                    style={{
                      border: "0px",
                      borderRadius: 30,
                      paddingBlock: 4,
                      paddingInline: 12,
                      background: item.cancelledDate
                        ? Colors.red
                        : !item.provider
                        ? Colors.blue
                        : Colors.blueBrightPrimary,
                      color: item.cancelledDate
                        ? Colors.blueBrightPrimary
                        : !item.provider
                        ? Colors.blueBrightPrimary
                        : Colors.blue,
                      fontWeight: 600,
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                      alignSelf: "flex-start",
                    }}
                  >
                    <p style={{ fontSize: 14 }}>
                      {item.cancelledDate
                        ? "Canceled"
                        : item.provider === undefined
                        ? "Open"
                        : "Filled"}
                    </p>
                  </div>
                </td>
              );
            },
          },
          {
            columnTitle: "",
            columnKey: "Action",
            sort: false,
            showColumn(windowWidth) {
              return windowWidth >= windowTrigger;
            },
            cell(item, windowWidth) {
              return (
                <td>
                  <MdChevronRight size={20} />
                </td>
              );
            },
          },
        ]}
        emptyContent={(windowWidth: number) => {
          return (
            <div
              style={{
                padding: 20,
                background: Colors.border,
                border: `1px solid ${Colors.blue}`,
                color: Colors.blue,
                fontWeight: 600,
                borderRadius: 8,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <FaExclamationCircle
                size={24}
                style={{ marginRight: 12, flexShrink: 0 }}
              />
              <p>
                You don't have any active job listings right now.&nbsp;
                <Link
                  style={{ color: Colors.blue, cursor: "pointer" }}
                  href={"/facility/listings/addlisting"}
                >
                  Add a listing to get started!
                  <FaChevronRight size={10} style={{ marginLeft: 8 }} />
                </Link>
              </p>
            </div>
          );
        }}
        headerContent={(windowWidth: number) => {
          return (
            <>
              <h1
                style={{
                  margin: 0,
                  fontSize: 32,
                  marginBottom: 12,
                  fontWeight: "700",
                }}
              >
                Your Job Board
              </h1>
              <p style={{ color: Colors.lightGray }}>
                These are jobs that you have listed at your facilities.
              </p>
            </>
          );
        }}
        rowKey={(row: any) => {
          return row._id;
        }}
        addCallback={() => {
          navigate("addlisting");
        }}
        pageSizeOptions={[100]}
        defaultPageSize={100}
        defaultSortedColumn={"Date / Time"}
        defaultSortedDirection={1}
        rowClickCallback={(rowData) => {
          setModalData(rowData);
        }}
        load={async (
          page: number,
          pageSize: number,
          sortColumn: string | undefined,
          sortedDirection: number
        ) => {
          const result = await globalContext.api.facilityJobListings.call(
            globalContext.callOptions(),
            {},
            {
              pagination: {
                pageSize: pageSize,
                page: page,
              },
              ...(sortColumn
                ? {
                    sort: {
                      sortBy: sortColumn,
                      sortOrder: sortedDirection === 1 ? "asc" : "desc",
                    },
                  }
                : {}),
            }
          );

          if (result.status === 200) {
            return {
              data: result.data!.jobs ?? [],
              total: result.data!.size ?? 0,
            };
          } else {
            return {
              error: result.error,
              data: [],
              total: 0,
            };
          }
        }}
      />
    </>
  );
}
