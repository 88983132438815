import { Link } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { MdVisibility, MdVisibilityOff } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { GlobalStateContext } from "../contexts/GlobalStateContext";
import { Colors } from "../Colors";
import { FaStethoscope } from "react-icons/fa";
import { Oval } from "react-loader-spinner";

// function getCancelTokenSource() {
//   const cancelToken = axios.CancelToken;
//   const source = cancelToken.source();
//   return source;
// }

export default function Login() {
  let navigate = useNavigate();
  let globalContext = useContext(GlobalStateContext);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [passwordVisible, setPasswordVisible] = useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(false);

  return (
    <div
      style={{
        minHeight: "100vh",
        background: Colors.border,
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div style={{ marginBottom: 200, marginTop: 40, marginInline: 20 }}>
        <span
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: 20,
          }}
        >
          <div
            style={{
              background: Colors.blue,
              borderRadius: 24,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              height: 48,
              width: 48,
            }}
          >
            <FaStethoscope style={{ color: "#FBFBFD" }} size={20} />
          </div>
          <h2
            className="noSelect"
            style={{
              color: Colors.blue,
              fontSize: 40,
              fontWeight: 800,
            }}
          >
            Pagerr
          </h2>
        </span>
        <div
          style={{
            display: "flex",
            padding: 16,
            borderRadius: 8,
            background: "#fff",
            boxShadow: "0px 4px 8px rgba(0,0,0,0.2)",
            flexDirection: "column",
          }}
        >
          <h3 style={{ marginBottom: 24 }}>Login</h3>
          <form
            style={{
              display: "flex",
              gap: 12,
              flexDirection: "column",
            }}
          >
            <label>
              Email:
              <input
                type="email"
                onChange={(evt) => setEmail(evt.target.value)}
                value={email}
                disabled={loading}
              />
            </label>
            <label
              style={{
                display: "flex",
                flex: 1,
              }}
            >
              Password:
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  contain: "layout",
                  flex: 1,
                }}
              >
                <input
                  type={passwordVisible === false ? "password" : "text"}
                  onChange={(evt) => setPassword(evt.target.value)}
                  value={password}
                  disabled={loading}
                  style={{
                    paddingRight: 32,
                    flex: 1,
                  }}
                />
                {passwordVisible === false ? (
                  <MdVisibility
                    size={20}
                    onClick={() => setPasswordVisible(!passwordVisible)}
                    style={{
                      position: "absolute",
                      right: 12,
                      cursor: "pointer",
                    }}
                  />
                ) : (
                  <MdVisibilityOff
                    size={20}
                    onClick={() => setPasswordVisible(!passwordVisible)}
                    style={{
                      position: "absolute",
                      right: 12,
                      cursor: "pointer",
                    }}
                  />
                )}
              </div>
            </label>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginBlock: 12,
                borderRadius: 4,
                color: Colors.blue,
                background: Colors.blueBrightPrimary,
                border: `1px solid ${Colors.blue}`,
                flex: 1,
              }}
            >
              {loading === true ? (
                <Oval
                  color={Colors.blue}
                  secondaryColor={Colors.blue}
                  height={34}
                  width={20}
                  strokeWidth={8}
                />
              ) : (
                <input
                  type="submit"
                  style={{
                    borderRadius: 4,
                    padding: 12,
                    fontSize: 16,
                    color: Colors.blue,
                    fontWeight: 600,
                    background: Colors.blueBrightPrimary,
                    border: "none",
                    cursor: "pointer",
                    flex: 1,
                    height: 40,
                  }}
                  value="Login"
                  onClick={async (e) => {
                    // login()
                    e.preventDefault();
                    setLoading(true);
                    globalContext.api.login
                      .call(
                        globalContext.callOptions(),
                        {},
                        {
                          username: email,
                          password: password,
                        }
                      )
                      .then((response) => {
                        console.log(response?.data?.token);

                        if (response.status === 200) {
                          globalContext.token.setValue(response.data!.token);

                          //Get the session info
                          globalContext.api.sessionInfo
                            .call(globalContext.callOptions(), {})
                            .then((sessionInfo) => {
                              if (sessionInfo.error === undefined) {
                                console.log(sessionInfo);

                                globalContext.sessionInfo.setValue(
                                  sessionInfo.data
                                );

                                if (globalContext.redirect.value) {
                                  const redirect = globalContext.redirect.value;
                                  globalContext.redirect.setValue(undefined);
                                  navigate({
                                    pathname: redirect.pathname,
                                    search: redirect.search,
                                  });
                                } else if (sessionInfo.data?.admin) {
                                  navigate("/admin");
                                } else if (sessionInfo.data!.provider) {
                                  navigate("/provider/listings");
                                } else if (
                                  sessionInfo.data!.facilities &&
                                  Array.isArray(sessionInfo.data!.facilities)
                                  // sessionInfo.data!.facilities?.length > 0
                                ) {
                                  navigate("/facility/listings");
                                }
                              }
                            })
                            .catch((error) => {
                              setLoading(false);
                            });
                        } else {
                          globalContext.token.setValue(undefined);
                          alert("Invalid credentials.");
                          setLoading(false);
                        }
                      })
                      .catch((error) => {
                        console.log(error);
                        setLoading(false);
                        alert(`Error: ${error}`);
                      });
                  }}
                />
              )}
            </div>
          </form>
          <p style={{ fontSize: 14, margin: 12 }}>
            Forgot your password?{" "}
            <Link style={{ cursor: "pointer" }} href="/forgotpassword">
              Click here.
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
}
