import { useState, useEffect } from "react";
import { FaStethoscope } from "react-icons/fa";
import { Outlet } from "react-router-dom";
import { Colors } from "../Colors";

export default function SignupChrome() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener("resize", updateWidthAndHeight);
    return () => window.removeEventListener("resize", updateWidthAndHeight);
  });

  const updateWidthAndHeight = () => {
    setWindowWidth(window.innerWidth);
  };

  return (
    <div
      style={{
        minHeight: "100vh",
        background: Colors.border,
        alignItems: "center",
        justifyContent: "flex-start ",
        display: "flex",
        flexDirection: "column",
        maxWidth: windowWidth,
      }}
    >
      <span
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          marginBlock: 20,
        }}
      >
        <div
          style={{
            background: Colors.blue,
            borderRadius: 24,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            height: 48,
            width: 48,
          }}
        >
          <FaStethoscope style={{ color: "#FBFBFD" }} size={20} />
        </div>
        <h2
          className="noSelect"
          style={{
            color: Colors.blue,
            fontSize: 40,
            fontWeight: 800,
          }}
        >
          Pagerr
        </h2>
      </span>
      <div
        style={{
          display: "flex",
          padding: 24,
          borderRadius: 8,
          background: "#fff",
          // border: "1px solid gray",
          boxShadow: "0px 4px 8px rgba(0,0,0,0.2)",
          flexDirection: "column",
          // marginBlock: "4%",
          marginInline: 5,
          maxWidth: windowWidth - 48 - 4,
        }}
      >
        <Outlet />
      </div>
    </div>
  );
}
