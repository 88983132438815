import { Drawer } from "@mui/material";
import { useEffect, useState } from "react";
import { FaBars, FaStethoscope } from "react-icons/fa";
import { MdClose } from "react-icons/md";
import { Navigate, useNavigate, useNavigationType } from "react-router-dom";
import { Colors } from "../Colors";
import LogoutButton from "./LogoutButton";
import { mobileTrigger } from "./MobileTrigger";
import ProviderMenu from "./ProviderMenu";

export default function ProviderHeader(props: { windowTrigger: number }) {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [scrollPosition, setScrollPosition] = useState(window.scrollY);

  const windowTrigger = props.windowTrigger;

  const navigate = useNavigate();

  useEffect(() => {
    window.addEventListener("resize", updateWidthAndHeight);
    return () => window.removeEventListener("resize", updateWidthAndHeight);
  });

  useEffect(() => {
    window.addEventListener("scroll", updateScrollPosition);
    return () => window.removeEventListener("scroll", updateScrollPosition);
  });

  const updateWidthAndHeight = () => {
    setWindowWidth(window.innerWidth);
  };

  const updateScrollPosition = () => {
    setScrollPosition(window.scrollY);
  };

  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <>
      {scrollPosition > 40 && windowWidth < windowTrigger ? (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            margin: 0,
            background: "#fff",
            paddingInline: 20,
            paddingBlock: 10,
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            zIndex: 20,
            boxShadow: "0px 0px 4px rgba(0,0,0,0.5)",
          }}
        >
          <FaBars
            size={24}
            style={{
              color: Colors.blue,
              cursor: "pointer",
            }}
            onClick={() => setMenuOpen(true)}
          />
          <LogoutButton />
        </div>
      ) : null}
      <Drawer anchor="left" open={menuOpen} onClose={() => setMenuOpen(false)}>
        <div style={{ background: "#FBFBFD", padding: 10 }}>
          <div
            style={{
              display: "flex",
              alignItems: "flex-end",
              justifyContent: "flex-end",
              alignSelf: "stretch",
            }}
          >
            <MdClose
              size={28}
              style={{
                color: "#26390F",
                cursor: "pointer",
              }}
              onClick={() => setMenuOpen(false)}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              margin: 12,
            }}
          >
            <div
              style={{
                background: Colors.blue,
                borderRadius: 20,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                height: 40,
                width: 40,
              }}
            >
              <FaStethoscope style={{ color: "#FBFBFD" }} />
            </div>
            <h2
              className="noSelect"
              style={{
                color: Colors.blue,
                fontSize: 28,
              }}
            >
              Pagerr
            </h2>
          </div>
          <ProviderMenu handleClick={() => setMenuOpen(false)} />
        </div>
      </Drawer>

      <div
        style={{
          background: "#FBFBFD",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          padding: 10,
          top: 0,
          right: 0,
          left: 0,
          paddingInline: 30,
        }}
      >
        {windowWidth < windowTrigger ? (
          <FaBars
            size={24}
            style={{
              color: Colors.blue,
              cursor: "pointer",
            }}
            onClick={() => setMenuOpen(true)}
          />
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              paddingBlock: 4,
              borderRadius: 2,
              cursor: "pointer",
            }}
            onClick={() => navigate("/provider/listings")}
          >
            <div
              style={{
                background: Colors.blue,
                borderRadius: 20,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                height: 40,
                width: 40,
              }}
            >
              <FaStethoscope style={{ color: "#FBFBFD" }} />
            </div>
            <h2
              className="noSelect"
              style={{ color: Colors.blue, fontSize: 28 }}
            >
              Pagerr
            </h2>
          </div>
        )}
        <LogoutButton />
      </div>
    </>
  );
}
