import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { GlobalStateContext } from "../contexts/GlobalStateContext";

export default function AdminRequired(props: { children: any }) {
  const navigate = useNavigate();
  const globalContext = useContext(GlobalStateContext);
  const [authorized, setAuthorized] = useState(false);

  // useEffect(() => {
  //   if (globalContext.ready.current) {
  //     if (globalContext.token.value && globalContext.token.value.length > 0) {
  //       setAuthorized(true);
  //     } else {
  //       //Navigate to the login page
  //       navigate("/login");
  //     }
  //   }
  // }, [globalContext.ready.current, globalContext.token.value]);

  useEffect(() => {
    if (
      globalContext.ready.current &&
      globalContext.sessionInfo.value?.admin !== true
    ) {
      navigate("/login");
    } else {
      setAuthorized(true);
    }
  }, [globalContext.ready.current, globalContext.token.value]);

  if (authorized) {
    return <>{props.children}</>;
  } else {
    return <div></div>;
  }
}
