import { useState } from "react";
import { ButtonUnstyled } from "@mui/base";
import { Link } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { Colors } from "../Colors";
import { MdVisibility, MdVisibilityOff } from "react-icons/md";
import { useSignup } from "../contexts/useSignup";
import {
  ApiCallOptions,
  AuthProvider,
  FailureHandler,
  ProviderType,
} from "@xaratan/pagerr-common";
import { useGlobals } from "../contexts/useGlobals";
import { useGetState } from "../helpers/useGetState";

export default function ProviderSignupAccountDetails() {
  const signup = useSignup();
  const globals = useGlobals();
  const params = useParams();

  const signupToken = params.signupToken;

  const [passwordRules] = useGetState(
    globals.api.passwordRules,
    globals.callOptions(),
    {}
  );

  const [education, setEducation] = useState<undefined | ProviderType>(
    undefined
  );

  const [password, setPassword] = useState(signup.password.value ?? "");
  const [confirmPassword, setConfirmPassword] = useState(
    signup.password.value ?? ""
  );
  const navigate = useNavigate();

  function validateFirstName(): string {
    if (!signup.firstName.value || signup.firstName.value.length === 0) {
      return "First name is required";
    }

    return "";
  }

  function validateLastName(): string {
    if (!signup.lastName.value || signup.lastName.value.length === 0) {
      return "Last name is required";
    }

    return "";
  }

  function validateType(): string | undefined {
    if (education === undefined) {
      return "Please select an option";
    }
    return "";
  }

  function validatePassword(): string[] {
    if (passwordRules?.rules) {
      const failures: string[] = [];

      for (let r of passwordRules.rules) {
        if (
          Array.from(password.matchAll(new RegExp(r.regex, "g"))).length <
          r.count
        ) {
          failures.push(r.message);
        }
      }

      return failures;
    }

    return [];
  }

  function validateConfirmPassword(): string {
    if (password === confirmPassword) {
      return "";
    }

    return "Passwords must match";
  }

  function validate() {
    return !(
      !!validateFirstName() ||
      !!validateLastName() ||
      validatePassword().length !== 0 ||
      !!validateConfirmPassword() ||
      !!validateType()
    );
  }

  const [passwordVisible, setPasswordVisible] = useState<boolean>(false);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <p
        style={{
          textAlign: "right",
          fontSize: 12,
          fontWeight: 700,
          color: "#fff",
          paddingBlock: 4,
          paddingInline: 8,
          borderRadius: 8,
          background: Colors.blue,
          alignSelf: "flex-end",
        }}
      >
        Step 1/4
      </p>
      <h3 style={{ margin: 0 }}>Welcome to Pagerr!</h3>
      <p style={{ marginBottom: 24 }}>
        Fill out your account info below to get signed up.
      </p>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 12,
        }}
      >
        <label>
          First Name:
          <input
            type="text"
            onChange={(evt) =>
              signup.firstName.setValue(evt.currentTarget.value)
            }
            value={signup.firstName.value ?? ""}
          />
          <p
            style={{
              height: 12,
              fontWeight: 600,
              color: Colors.blue,
              marginInline: 8,
            }}
          >
            {validateFirstName()}
          </p>
        </label>
        <label>
          Last name:
          <input
            type="text"
            onChange={(evt) =>
              signup.lastName.setValue(evt.currentTarget.value)
            }
            value={signup.lastName.value ?? ""}
          />
          <p
            style={{
              height: 12,
              fontWeight: 600,
              color: Colors.blue,
              marginInline: 8,
            }}
          >
            {validateLastName()}
          </p>
        </label>
        <label>
          Education level:
          <select
            style={{
              padding: 7,
              borderRadius: 8,
              fontSize: 16,
              height: 37,
            }}
            value={education}
            onChange={(e) =>
              setEducation(e.currentTarget.value as ProviderType)
            }
          >
            <option value="" disabled selected>
              Please select an option...
            </option>
            <option value="MD" label="MD">
              MD
            </option>
            <option value="DO" label="DO">
              DO
            </option>
            <option value="CRNA" label="CRNA">
              CRNA
            </option>
          </select>
          <p
            style={{
              height: 12,
              fontWeight: 600,
              color: Colors.blue,
              marginInline: 8,
            }}
          >
            {validateType()}
          </p>
        </label>

        <label>
          Password:
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              contain: "layout",
            }}
          >
            <input
              type={passwordVisible === true ? "text" : "password"}
              onChange={(evt) => {
                setPassword(evt.currentTarget.value);

                if (evt.currentTarget.value === confirmPassword) {
                  signup.password.setValue(evt.currentTarget.value);
                }
              }}
              value={password}
              style={{ flex: 1 }}
            />
            {passwordVisible === false ? (
              <MdVisibility
                size={20}
                onClick={() => setPasswordVisible(!passwordVisible)}
                style={{
                  color: Colors.blue,
                  position: "absolute",
                  right: 8,
                }}
              />
            ) : (
              <MdVisibilityOff
                size={20}
                onClick={() => setPasswordVisible(!passwordVisible)}
                style={{
                  color: Colors.blue,
                  position: "absolute",
                  right: 8,
                }}
              />
            )}
          </div>
          <p
            style={{
              height: 12,
              fontWeight: 600,
              color: Colors.blue,
              marginInline: 8,
            }}
          >
            <ul>
              {validatePassword().map((failure) => {
                return <li>{failure}</li>;
              })}
            </ul>
          </p>
        </label>
        <label>
          Confirm Password:
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              contain: "layout",
            }}
          >
            <input
              type={passwordVisible === true ? "text" : "password"}
              onChange={(evt) => {
                setConfirmPassword(evt.currentTarget.value);

                if (evt.currentTarget.value === password) {
                  signup.password.setValue(evt.currentTarget.value);
                }
              }}
              value={confirmPassword}
              style={{ flex: 1 }}
            />
            {passwordVisible === false ? (
              <MdVisibility
                size={20}
                onClick={() => setPasswordVisible(!passwordVisible)}
                style={{
                  color: Colors.blue,
                  position: "absolute",
                  right: 8,
                }}
              />
            ) : (
              <MdVisibilityOff
                size={20}
                onClick={() => setPasswordVisible(!passwordVisible)}
                style={{
                  color: Colors.blue,
                  position: "absolute",
                  right: 8,
                }}
              />
            )}
          </div>
          <p
            style={{
              height: 12,
              fontWeight: 600,
              color: Colors.blue,
              marginInline: 8,
            }}
          >
            {validateConfirmPassword()}
          </p>
        </label>
      </div>
      <ButtonUnstyled
        onClick={() => {
          if (validate()) {
            const ops: ApiCallOptions = {
              authProvider: AuthProvider.null(),
              failureHandler: FailureHandler.null(),
              baseUrl: "",
            };
            globals.api.providerOnboarding
              .call(
                ops,
                {},
                {
                  firstName: signup.firstName.value!,
                  lastName: signup.lastName.value!,
                  password: signup.password.value!,
                  educationLevel: education!,
                  token: signupToken!,
                }
              )
              .then((response) => {
                if (response.status === 200) {
                  const sessionKey = response.data?.session ?? "";

                  globals.token.setValue(sessionKey);

                  globals.api.sessionInfo
                    .call(globals.callOptions(), {})
                    .then((response) => {
                      if (response.status === 200) {
                        globals.sessionInfo.setValue(response.data);
                        signup.firstName.setValue(undefined);
                        signup.lastName.setValue(undefined);
                        signup.password.setValue(undefined);
                        signup.crnaMdDo.setValue(undefined);
                        navigate("/providersignup/2");
                      } else {
                        alert("Error logging in.");
                      }
                    });
                } else {
                  alert("Error creating account.");
                }
              });
          }
        }}
        style={{
          margin: 12,
          borderRadius: 4,
          padding: 12,
          fontSize: 14,
          color: validate() === true ? Colors.blue : "lightgray",
          fontWeight: "600",
          background:
            validate() === true ? Colors.blueBrightPrimary : "#eaeaea",
          border:
            validate() === true
              ? `1px solid ${Colors.blue}`
              : "1px solid lightgray",
          cursor: validate() === true ? "pointer" : undefined,
        }}
        disabled={!validate()}
      >
        Continue
      </ButtonUnstyled>
      <p style={{ fontSize: 16, margin: 12 }}>
        By signing up you agree to the&nbsp;
        <Link style={{ cursor: "pointer" }}>Terms and Conditions</Link>
        &nbsp;and&nbsp;
        <Link style={{ cursor: "pointer" }}>Privacy Policy</Link>.
      </p>
      <p style={{ fontSize: 16, margin: 12 }}>
        Already have an account?&nbsp;<Link href="/login">Login</Link>.
      </p>
    </div>
  );
}
