import "../App.css";
import { useNavigate } from "react-router-dom";
import { useState, useEffect, useContext } from "react";
import {
  FaChevronRight,
  FaEdit,
  FaExclamationCircle,
  FaPhone,
  FaUsers,
} from "react-icons/fa";
import { MdDescription, MdLocationPin } from "react-icons/md";
import { Colors } from "../Colors";
import { GlobalStateContext } from "../contexts/GlobalStateContext";
import { usePostState } from "../helpers/usePostState";
import { Link } from "@mui/material";

export default function FacilityLocations() {
  const navigate = useNavigate();
  const globalContext = useContext(GlobalStateContext);

  const [facilities, _setFacilities, _reloadFacilities] = usePostState(
    globalContext.api.facilityListingView,
    globalContext.callOptions(),
    {},
    {
      pagination: {
        pageSize: 10,
        page: 1,
      },
    }
  );

  // const [drawerData, setDrawerData] = useState<
  //   EndPointType<{
  //     required: {
  //       _id: "string";
  //       active: "boolean";
  //       name: "string";
  //       entranceProtocol: "string";
  //       address: {
  //         required: {
  //           address1: "string";
  //           address2: "string";
  //           city: "string";
  //           state: "string";
  //           zip: "string";
  //         };
  //       };
  //       phone: "string";
  //       credentialing: [
  //         {
  //           required: {
  //             active: "boolean";
  //             status: "string";
  //             provider: {
  //               required: {
  //                 // TODO (Shaun) return provider first and last name
  //                 _id: "string";
  //                 accountId: "string";
  //                 account: {
  //                   required: {
  //                     email: "string";
  //                     firstName: "string";
  //                     lastName: "string";
  //                     phones: [
  //                       {
  //                         required: {
  //                           number: "string";
  //                           mobile: "boolean";
  //                           primary: "boolean";
  //                         };
  //                       }
  //                     ];
  //                   };
  //                   optional: {
  //                     address: {
  //                       required: {
  //                         address1: "string";
  //                         address2: "string";
  //                         city: "string";
  //                         state: "string";
  //                         zip: "string";
  //                       };
  //                     };
  //                   };
  //                 };
  //                 boardCertification: "string";
  //                 specialties: [
  //                   {
  //                     required: {
  //                       specialty: "string";
  //                     };
  //                   }
  //                 ];
  //                 status: "string";
  //               };
  //             };
  //           };
  //         }
  //       ];
  //     };
  //   }>
  // >();

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener("resize", updateWidthAndHeight);
    return () => window.removeEventListener("resize", updateWidthAndHeight);
  });

  const updateWidthAndHeight = () => {
    setWindowWidth(window.innerWidth);
  };

  if (!facilities) return <p>loading... </p>;
  else
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          flexDirection: "column",
          flex: 1,
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "flex-start",
            flexDirection: "column",
          }}
        >
          <h1
            style={{
              margin: 0,
              fontSize: 32,
              marginBottom: 12,
              fontWeight: "700",
            }}
          >
            My Facilities
          </h1>
          <p style={{ color: Colors.lightGray, marginBottom: 20 }}>
            These are facilities where you can post shifts.
          </p>
        </div>
        <div style={{ flex: 1 }}>
          {facilities.facilities.length === 0 ? (
            <div
              style={{
                padding: 20,
                background: Colors.border,
                border: `1px solid ${Colors.blue}`,
                color: Colors.blue,
                fontWeight: 600,
                borderRadius: 8,
                display: "flex",
                flex: 1,
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <FaExclamationCircle
                size={24}
                style={{ marginRight: 12, flexShrink: 0 }}
              />
              <p>You aren't connected with any facilities in Pagerr yet.</p>
            </div>
          ) : null}
          <div>
            <ul
              style={{
                listStyle: "none",
                padding: 0,
                margin: 0,
                gridTemplateColumns: windowWidth > 1000 ? "1fr" : "1fr",
                display: "inline-grid",
                maxWidth: 640,
                rowGap: 20,
                columnGap: 20,
                flex: 1,
                width: "100%",
              }}
            >
              {(facilities.facilities ?? []).map(function (item) {
                return (
                  <li
                    key={item._id}
                    style={{
                      flex: 1,
                      display: "flex",
                      flexWrap: "wrap",
                      border: "1px solid #eaeaea",
                      borderRadius: 4,
                      padding: 12,
                      alignItems: "stretch",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      gap: 8,
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "flex-start",
                        flexWrap: "wrap",
                      }}
                    >
                      <p style={{ fontWeight: "700", marginBottom: 0 }}>
                        {item.name}
                      </p>
                      <FaEdit
                        style={{ cursor: "pointer", color: Colors.blue }}
                        onClick={() =>
                          navigate("/facility/locations/edit/" + item._id)
                        }
                      />
                    </div>

                    <div
                      style={{
                        height: 1,
                        background: "#eaeaea",
                      }}
                    />
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "flex-start",
                      }}
                    >
                      <MdLocationPin
                        style={{
                          height: 12,
                          width: 12,
                          padding: 4,
                          background: Colors.blue,
                          color: Colors.blueBrightPrimary,
                          borderRadius: 2,
                          marginRight: 4,
                          flexShrink: 0,
                        }}
                      />
                      <p
                        style={{
                          fontSize: 16,
                          fontWeight: 500,
                          marginTop: 1,
                        }}
                      >
                        {item.address.address1}
                      </p>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "flex-start",
                      }}
                    >
                      <FaPhone
                        style={{
                          height: 12,
                          width: 12,
                          padding: 4,
                          background: Colors.blue,
                          color: Colors.blueBrightPrimary,
                          borderRadius: 2,
                          marginRight: 4,
                          flexShrink: 0,
                        }}
                      />
                      <p
                        style={{
                          fontSize: 16,
                          fontWeight: 500,
                          marginTop: 1,
                        }}
                      >
                        {item.phone}
                      </p>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "flex-start",
                      }}
                    >
                      <MdDescription
                        style={{
                          height: 12,
                          width: 12,
                          padding: 4,
                          background: Colors.blue,
                          color: Colors.blueBrightPrimary,
                          borderRadius: 2,
                          marginRight: 4,
                          flexShrink: 0,
                        }}
                      />
                      <p
                        style={{
                          fontSize: 16,
                          fontWeight: 500,
                          marginTop: 1,
                        }}
                      >
                        {item.entranceProtocol}
                      </p>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 8,
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "flex-start",
                        }}
                      >
                        <FaUsers
                          style={{
                            height: 12,
                            width: 12,
                            padding: 4,
                            background: Colors.blue,
                            color: Colors.blueBrightPrimary,
                            borderRadius: 2,
                            marginRight: 4,
                            flexShrink: 0,
                          }}
                        />
                        <p
                          style={{
                            fontSize: 16,
                            fontWeight: 500,
                            marginTop: 1,
                          }}
                        >
                          {item.credentialing.length ?? 0} provider
                          {item.credentialing.length === 1 ? "" : "s"}{" "}
                          credentialed at this facility
                        </p>
                      </div>
                      <div
                        style={{
                          marginLeft: 20,
                          display: "flex",
                          flexDirection: "column",
                          gap: 12,
                          flex: 1,
                        }}
                      >
                        {item.credentialing.map(function (c) {
                          return (
                            <div
                              key={c.provider._id}
                              style={{
                                border: "1px solid lightgray",
                                borderRadius: 4,
                                flex: 1,
                                padding: 8,
                              }}
                            >
                              <p>
                                {c.provider?.account.firstName}&nbsp;
                                {c.provider?.account.lastName},&nbsp;
                                {c.provider?.educationLevel} (
                                {c.provider?.account.email})
                              </p>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    );
}
