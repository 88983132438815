import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Dialog, Link } from "@mui/material";
import { Colors } from "../Colors";
import { ButtonUnstyled } from "@mui/base";
import { FaStethoscope } from "react-icons/fa";
import { useGlobals } from "../contexts/useGlobals";
import {
  ApiCallOptions,
  AuthProvider,
  FailureHandler,
} from "@xaratan/pagerr-common";

export default function ForgotPassword() {
  let navigate = useNavigate();
  const globalState = useGlobals();

  const [email, setEmail] = useState("");

  function validateEmail(): string {
    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      return "Invalid email address";
    }
    return "";
  }

  const [successVisible, setSuccessVisible] = useState<
    | undefined
    | {
        title: "success" | "error";
        message: string;
      }
  >(undefined);

  return (
    <div
      style={{
        minHeight: "100vh",
        background: Colors.border,
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Dialog
        open={!!successVisible}
        onClose={() => {
          successVisible?.title === "success"
            ? navigate("/login")
            : setSuccessVisible(undefined);
        }}
      >
        <div style={{ padding: 20, display: "flex", flexDirection: "column" }}>
          <h3 style={{ color: Colors.blue }}>
            {successVisible?.title.toUpperCase()}
          </h3>
          <p style={{ marginInline: 4 }}>{successVisible?.message} </p>
          <ButtonUnstyled
            onClick={() => {
              successVisible?.title === "success"
                ? navigate("/login")
                : setSuccessVisible(undefined);
            }}
            style={{
              margin: 12,
              borderRadius: 4,
              paddingInline: 24,
              paddingBlock: 8,
              fontSize: 16,
              color: Colors.blue,
              fontWeight: "600",
              background: Colors.blueBrightPrimary,
              border: `1px solid ${Colors.blue}`,
              cursor: "pointer",
              alignSelf: "flex-end",
            }}
          >
            Continue
          </ButtonUnstyled>
        </div>
      </Dialog>
      <div style={{ marginBottom: 200, marginTop: 40, marginInline: 20 }}>
        <span
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: 20,
          }}
        >
          <div
            style={{
              background: Colors.blue,
              borderRadius: 24,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              height: 48,
              width: 48,
            }}
          >
            <FaStethoscope style={{ color: "#FBFBFD" }} size={20} />
          </div>
          <h2
            style={{
              color: Colors.blue,
              fontSize: 40,
              fontWeight: 800,
            }}
          >
            Pagerr
          </h2>
        </span>
        <div
          style={{
            display: "flex",
            padding: 16,
            borderRadius: 8,
            background: "#fff",
            boxShadow: "0px 4px 8px rgba(0,0,0,0.2)",
            flexDirection: "column",
          }}
        >
          <h3>Forgot your password?</h3>
          <p style={{ maxWidth: 420, marginBottom: 12, marginInline: 4 }}>
            Enter your email below and we'll send you a message with
            instructions on how reset to your password.
          </p>
          <form
            style={{ display: "flex", flexDirection: "column", gap: 20 }}
            onSubmit={(e) => {
              e.preventDefault();

              const callOptions: ApiCallOptions = {
                authProvider: AuthProvider.null(),
                baseUrl: "",
                failureHandler: FailureHandler.null(),
              };

              globalState.api.forgotPassword
                .call(callOptions, {}, { email: email })
                .then((result) => {
                  if (result.status === 200) {
                    //Do something
                    setSuccessVisible({
                      title: "success",
                      message:
                        "If an account is associated with this email address, you will receive an email shortly.",
                    });
                  } else {
                    setSuccessVisible({
                      title: "error",
                      message: result.error ?? "Unknown error",
                    });
                  }
                });
            }}
          >
            <label>
              Email:
              <input
                type="email"
                onChange={(evt) => setEmail(evt.target.value)}
                value={email}
                style={{}}
              />
            </label>
            <input
              type="submit"
              style={{
                borderRadius: 4,
                padding: 12,
                fontSize: 14,
                color: !validateEmail() === true ? Colors.blue : "lightgray",
                fontWeight: 600,
                background: Colors.blueBrightPrimary,
                border:
                  !validateEmail() === true
                    ? `1px solid ${Colors.blue}`
                    : "1px solid transparent",
                cursor: !validateEmail() === true ? "pointer" : undefined,
              }}
              disabled={!!validateEmail()}
            />
          </form>
          <p style={{ fontSize: 12, margin: 12 }}>
            Remembered your password?&nbsp;
            <Link style={{ cursor: "pointer" }} href="/login">
              Click here to login.
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
}
