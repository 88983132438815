import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { GlobalStateContext } from "../contexts/GlobalStateContext";

export default function AuthorizationRequired(props: { children: any }) {
  const navigate = useNavigate();
  const location = useLocation();
  const globalContext = useContext(GlobalStateContext);
  const [authorized, setAuthorized] = useState(false);

  useEffect(() => {
    if (
      globalContext.ready.current &&
      (globalContext.token.value === undefined ||
        globalContext.token.value?.length === 0)
    ) {
      globalContext.redirect.setValue({
        pathname: location.pathname,
        search: location.search,
      });
      navigate("/login");
    } else {
      setAuthorized(true);
    }
  }, [globalContext.ready.current, globalContext.token.value]);

  if (authorized) {
    return <>{props.children}</>;
  } else {
    return <div></div>;
  }
}
