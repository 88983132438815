import { ButtonUnstyled } from "@mui/base";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Dialog,
  Switch,
} from "@mui/material";
import {
  FaChevronCircleDown,
  FaChevronRight,
  FaGraduationCap,
  FaMobileAlt,
  FaPhone,
  FaPlusCircle,
  FaRegHospital,
  FaStethoscope,
} from "react-icons/fa";
import { Colors } from "../Colors";
import {
  MdClose,
  MdEmail,
  MdLocationPin,
  MdManageAccounts,
} from "react-icons/md";
import { useGlobals } from "../contexts/useGlobals";
import { usePostState } from "../helpers/usePostState";
import { useGetState } from "../helpers/useGetState";

export default function AdminProviderList() {
  const navigate = useNavigate();
  const globalContext = useGlobals();

  const [data, _, reloadData] = usePostState(
    globalContext.api.adminProviderListingView,
    globalContext.callOptions(),
    {},
    {}
  );

  const credentialedStatuses = ["approved", "pending", "rejected", "cancelled"];
  const employmentTypes = ["w2", "1099"];
  const [employmentType, setEmploymentType] = useState<string>("");

  const [facilities] = useGetState(
    globalContext.api.getAllFacilities,
    globalContext.callOptions(),
    {}
  );

  // const [modalData, setModalData] = useState<string>("");
  const [modalData, setModalData] = useState<
    | undefined
    | {
        facilityId: string;
        credential: string;
        providerId: string;
        update?: boolean;
      }
  >(undefined);
  const [successVisible, setSuccessVisible] = useState<boolean>(false);

  // const [facility, setFacility] = useState<string>("");
  // const [credential, setCredential] = useState<string>("");

  function validateFacility(): string {
    if (modalData?.facilityId === "") {
      return "Please select a facility.";
    }
    return "";
  }

  function validateEmploymentTypes(): string {
    if (employmentType === "") {
      return "Please select an employment type.";
    }
    return "";
  }

  function validateCredential(): string {
    if (modalData?.credential === "") {
      return "Please select a credentialed status.";
    }
    return "";
  }

  function validate() {
    return !(
      !!validateFacility() ||
      !!validateEmploymentTypes() ||
      !!validateCredential()
    );
  }

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-start",
        flexDirection: "column",
        flex: 1,
        paddingBottom: 420,
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "flex-start",
          flexDirection: "column",
          marginBottom: 24,
        }}
      >
        <h1
          style={{
            margin: 0,
            fontSize: 32,
            marginBottom: 12,
            fontWeight: "700",
          }}
        >
          Providers
        </h1>
        <p style={{ color: Colors.lightGray }}>
          This is a list of providers in Pagerr.
        </p>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 12,
          maxWidth: 800,
        }}
      >
        {(data?.providers ?? []).map(function (p, index) {
          return (
            <Accordion
              key={index}
              className={"jobCard"}
              style={{
                padding: 0,
                display: "flex",
                flexDirection: "column",
                flex: 1,
                border: "1px solid #eaeaea",
                borderRadius: 8,
              }}
              sx={{
                "&:before": {
                  display: "none",
                },
                boxShadow: "2px 2px 4px rgba(0,0,0,0.05)",
                "&:hover": {
                  boxShadow: "2px 2px 4px rgba(0,0,0,0.1)",
                },
              }}
              disableGutters={true}
            >
              <AccordionSummary
                style={{
                  display: "flex",
                  flex: 1,
                  background: "transparent",
                }}
                expandIcon={
                  <FaChevronCircleDown color={Colors.lightGray} size={16} />
                }
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    flex: 1,
                    marginRight: 8,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        height: 32,
                        width: 32,
                        borderRadius: 80,
                        background: Colors.border,
                        display: "flex",
                        flexShrink: 0,
                        alignItems: "center",
                        justifyContent: "center",
                        fontWeight: 700,
                        marginRight: 8,
                        color: Colors.blue,
                      }}
                    >
                      {p.emailVerified === true
                        ? `${p.firstName.charAt(0)}${p.lastName.charAt(0)}`
                        : p.email.charAt(0).toUpperCase()}
                    </div>
                    <p style={{ fontWeight: 400, fontSize: 18 }}>
                      {p.emailVerified === true
                        ? `${p.firstName} ${p.lastName}, ${p.educationLevel}`
                        : `${p.email}`}
                      &nbsp;
                      {p.emailVerified === true ? `(${p.email})` : null}
                    </p>
                  </div>
                  {p.emailVerified === true ? null : (
                    <p
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        paddingBlock: 2,
                        paddingInline: 12,
                        background: Colors.blue,
                        color: "#fff",
                        borderRadius: 20,
                      }}
                    >
                      Email not verified
                    </p>
                  )}
                  {p.status === "approved" ? null : (
                    <p
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        paddingBlock: 2,
                        paddingInline: 12,
                        background: Colors.orange,
                        color: "#fff",
                        borderRadius: 20,
                        maxLines: 1,
                        fontWeight: 500,
                      }}
                    >
                      Status: {p.status.charAt(0).toUpperCase()}
                      {p.status.slice(1)}
                    </p>
                  )}
                </div>
              </AccordionSummary>
              <AccordionDetails>
                {p.emailVerified === true ? (
                  <div
                    style={{
                      display: "flex",
                      flex: 1,
                      background: "transparent",
                      flexDirection: "column",
                      gap: 12,
                    }}
                  >
                    <div
                      style={{ display: "flex", flexDirection: "row", gap: 12 }}
                    >
                      <MdEmail color={Colors.blue} />
                      <p style={{ fontWeight: 600, fontSize: 14 }}>{p.email}</p>
                    </div>
                    <div
                      style={{ display: "flex", flexDirection: "row", gap: 12 }}
                    >
                      <FaGraduationCap color={Colors.blue} />
                      <p style={{ fontWeight: 600, fontSize: 14 }}>
                        {p.providerType.charAt(0).toUpperCase()}
                        {p.providerType.slice(1)}
                      </p>
                    </div>
                    <div
                      style={{ display: "flex", flexDirection: "row", gap: 12 }}
                    >
                      <FaStethoscope color={Colors.blue} />
                      {p.boardCertification === undefined ? (
                        "No certification provided"
                      ) : (
                        <p style={{ fontWeight: 600, fontSize: 14 }}>
                          {p.boardCertification.charAt(0).toUpperCase()}
                          {p.boardCertification.slice(1)}
                        </p>
                      )}
                    </div>
                    {(p.phones ?? []).map(function (ph, index) {
                      return (
                        <div
                          key={index}
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            gap: 12,
                          }}
                        >
                          {ph.mobile === true ? (
                            <FaMobileAlt color={Colors.blue} />
                          ) : (
                            <FaPhone color={Colors.blue} />
                          )}
                          <p style={{ fontWeight: 600, fontSize: 14 }}>
                            ({ph.number.slice(0, 3)})&nbsp;
                            {ph.number.slice(3, 6)}-{ph.number.slice(6, 10)}
                            {ph.primary === true ? " (main)" : null}
                          </p>
                        </div>
                      );
                    })}
                    <div
                      style={{ display: "flex", flexDirection: "row", gap: 12 }}
                    >
                      <MdLocationPin color={Colors.blue} />
                      {p.address ? (
                        <p style={{ fontWeight: 600, fontSize: 14 }}>
                          {/* {p.address ?? } */}
                          {p.address?.address1},
                          {p.address?.address2
                            ? ` ${p.address.address2},`
                            : null}
                          &nbsp;
                          {p.address?.city},&nbsp;
                          {p.address?.state}
                          ,&nbsp;
                          {p.address?.zip}
                        </p>
                      ) : (
                        <p style={{ fontWeight: 600, fontSize: 14 }}>
                          No address provided
                        </p>
                      )}
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: 12,
                        alignItems: "center",
                      }}
                    >
                      <MdManageAccounts color={Colors.blue} />
                      <select
                        style={{
                          padding: 8,
                          display: "flex",
                          flex: 1,
                          fontWeight: 600,
                          fontSize: 14,
                          border: `1px solid ${Colors.blue}`,
                        }}
                        defaultValue={p.status}
                        onChange={(e) => {
                          globalContext.api.adminUpdateProviderStatus.call(
                            globalContext.callOptions(),
                            {},
                            {
                              providerId: p._id,
                              status: e.currentTarget.value,
                            }
                          );
                          reloadData();
                        }}
                      >
                        <option value="approved" label="Approved">
                          Approved
                        </option>
                        <option value="pending" label="Pending">
                          Pending
                        </option>
                        <option value="cancelled" label="Cancelled">
                          Cancelled
                        </option>
                        <option value="rejected" label="Rejected">
                          Rejected
                        </option>
                      </select>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 8,
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          gap: 12,
                        }}
                      >
                        <FaRegHospital color={Colors.blue} />
                        <p style={{ fontWeight: 600, fontSize: 14 }}>
                          {p.facilities.length} facility credentials
                        </p>
                      </div>
                      <div
                        style={{
                          marginLeft: 24,
                          display: "flex",
                          flexDirection: "column",
                          gap: 8,
                          // alignItems: "flex-start",
                        }}
                      >
                        {(p.facilities ?? []).map(function (f, index) {
                          return (
                            <div
                              key={index}
                              onClick={() =>
                                setModalData({
                                  credential: f.credentialingStatus,
                                  facilityId: f._id,
                                  providerId: p._id,
                                  update: true,
                                })
                              }
                              style={{
                                padding: 4,
                                paddingInline: 12,
                                borderRadius: 4,
                                fontWeight: 600,
                                cursor: "pointer",
                                background: Colors.border,
                                color: Colors.blackPrimary,
                                flexDirection: "row",
                                display: "flex",
                                alignItems: "center",
                                gap: 12,
                                flex: 1,
                                justifyContent: "space-between",
                              }}
                            >
                              <p>{f.name}</p>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "inherit",
                                  gap: "inherit",
                                }}
                              >
                                <p
                                  style={{
                                    color: "#fff",
                                    background:
                                      f.credentialingStatus === "approved"
                                        ? Colors.green
                                        : Colors.orange,
                                    borderRadius: 4,
                                    paddingInline: 8,
                                    paddingBlock: 2,
                                    fontSize: 14,
                                    fontWeight: 700,
                                  }}
                                >
                                  {f.credentialingStatus.toUpperCase()}
                                </p>
                                <FaChevronRight size={12} />
                              </div>
                            </div>
                          );
                        })}
                        <ButtonUnstyled
                          style={{
                            padding: 4,
                            paddingInline: 12,
                            borderRadius: 20,
                            fontWeight: 600,
                            background: Colors.blueBrightPrimary,
                            border: `1px solid ${Colors.blue}`,
                            color: Colors.blue,
                            flexDirection: "row",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            gap: 20,
                            fontSize: 16,
                            cursor: "pointer",
                          }}
                          onClick={() =>
                            setModalData({
                              facilityId: "",
                              providerId: p._id,
                              credential: "",
                            })
                          }
                        >
                          Add Another Facility
                          <FaPlusCircle />
                        </ButtonUnstyled>
                      </div>
                    </div>
                  </div>
                ) : null}
              </AccordionDetails>
            </Accordion>
          );
        })}
      </div>
      <Dialog
        open={!!modalData}
        onClose={() => {
          setModalData(undefined);
          // setCredential("");
          // setFacility("");
        }}
      >
        <div
          style={{
            padding: 20,
            background: "#fff",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <MdClose
            style={{ alignSelf: "flex-end", cursor: "pointer" }}
            onClick={() => setModalData(undefined)}
          />
          <p style={{ fontSize: 16, fontWeight: 700 }}>
            Update Provider's Credentials
          </p>
          <p style={{ fontSize: 16, fontWeight: 500, marginBottom: 12 }}>
            This will update this provider's credentialed status at this
            facility.
          </p>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 12,
            }}
          >
            <label>
              <b>Facility</b>
              <select
                style={{
                  fontSize: 16,
                  padding: 8,
                  flex: 1,
                }}
                disabled={modalData?.update ?? false}
                value={modalData?.facilityId ?? ""}
                onChange={(e) =>
                  setModalData({
                    credential: modalData?.credential!,
                    facilityId: e.currentTarget.value,
                    providerId: modalData?.providerId!,
                  })
                }
              >
                <option disabled label="Please select a facility..." value="">
                  Please select a facility...
                </option>
                {facilities?.facilities.map(function (facility) {
                  return (
                    <option
                      key={facility.id}
                      label={facility.name}
                      value={facility.id}
                    >
                      {facility.name}
                    </option>
                  );
                })}
              </select>
              {validateFacility()}
            </label>
            <label>
              <b>Employment Type</b>
              <select
                style={{
                  fontSize: 16,
                  padding: 8,
                  flex: 1,
                }}
                value={employmentType}
                onChange={(e) => setEmploymentType(e.currentTarget.value)}
              >
                <option disabled label="" value="">
                  Please select a status...
                </option>
                {employmentTypes.map(function (status) {
                  return (
                    <option
                      key={status}
                      label={`${status.charAt(0).toUpperCase()}${status.slice(
                        1
                      )}`}
                      value={status}
                    >
                      {status.charAt(0).toUpperCase()}
                      {status.slice(1)}
                    </option>
                  );
                })}
              </select>
              {validateEmploymentTypes()}
            </label>
            <label>
              <b>Credentialed Status</b>
              <select
                style={{
                  fontSize: 16,
                  padding: 8,
                  flex: 1,
                }}
                value={modalData?.credential ?? ""}
                onChange={(e) =>
                  setModalData({
                    credential: e.currentTarget.value,
                    facilityId: modalData?.facilityId!,
                    providerId: modalData?.providerId!,
                  })
                }
              >
                <option disabled label="" value="">
                  Please select a status...
                </option>
                {credentialedStatuses.map(function (status) {
                  return (
                    <option
                      key={status}
                      label={`${status.charAt(0).toUpperCase()}${status.slice(
                        1
                      )}`}
                      value={status}
                    >
                      {status.charAt(0).toUpperCase()}
                      {status.slice(1)}
                    </option>
                  );
                })}
              </select>
              {validateCredential()}
            </label>
          </div>

          <ButtonUnstyled
            style={{
              cursor: validate() === true ? "pointer" : undefined,
              borderRadius: 4,
              paddingBlock: 6,
              fontSize: 16,
              paddingInline: 20,
              fontWeight: 600,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              background: Colors.border,
              color: validate() === true ? Colors.blue : "lightgray",
              border:
                validate() === true
                  ? `1px solid ${Colors.blue}`
                  : `1px solid ${Colors.border}`,
              boxShadow: "2px 2px 4px rgba(0,0,0,0.2)",
              alignSelf: "flex-end",
              marginTop: 12,
            }}
            disabled={!validate()}
            onClick={() => {
              globalContext.api.upsertCredentialing.call(
                globalContext.callOptions(),
                {},
                {
                  facilityId: modalData?.facilityId!,
                  providerId: modalData?.providerId!,
                  status: modalData?.credential!,
                  employmentType: employmentType,
                }
              );
              setSuccessVisible(true);
              setModalData(undefined);
              // setFacility("");
              // setCredential("");
            }}
          >
            Update Provider's Credentials
          </ButtonUnstyled>
        </div>
      </Dialog>
      <Dialog
        open={successVisible}
        onClose={() => {
          setSuccessVisible(false);
        }}
      >
        <div
          style={{
            padding: 20,
            background: "#fff",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <MdClose
            style={{ alignSelf: "flex-end", cursor: "pointer" }}
            onClick={() => setSuccessVisible(false)}
          />
          <p style={{ fontSize: 16, fontWeight: 700 }}>Success</p>
          <p style={{ fontSize: 12, fontWeight: 500, marginBottom: 12 }}>
            A new credential has been created.
          </p>
          <ButtonUnstyled
            style={{
              cursor: "pointer",
              borderRadius: 4,
              paddingBlock: 6,
              paddingInline: 8,
              fontWeight: 600,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              background: Colors.border,
              color: Colors.blue,
              border: `1px solid ${Colors.blue}`,
              boxShadow: "2px 2px 4px rgba(0,0,0,0.2)",

              alignSelf: "flex-end",
              marginTop: 12,
            }}
            onClick={() => {
              setSuccessVisible(false);
              reloadData();
            }}
          >
            Okay
          </ButtonUnstyled>
        </div>
      </Dialog>
    </div>
  );
}
