import { useState } from "react";
import { ButtonUnstyled } from "@mui/base";
import { useNavigate } from "react-router-dom";
import { Colors } from "../Colors";
import { Certification, Specialty } from "@xaratan/pagerr-common";
import { useSignup } from "../contexts/useSignup";
import { useGlobals } from "../contexts/useGlobals";

export default function ProviderSignupProviderInfo() {
  const navigate = useNavigate();

  const signup = useSignup();
  const globals = useGlobals();

  const specialtyList = [
    "general anesthesia",
    "obstetric",
    "cardiac",
    "regional",
    "critical care",
    "pediatric (<1 years old)",
    "pediatric (<5 years old)",
    "pediatric (5-18 years old)",
  ];

  const [certification, setCertification] = useState<Certification | undefined>(
    signup.boardCertification.value
  );

  const [specialties, setSpecialties] = useState<Specialty[]>(
    signup.specialties.value ?? []
  );

  function validateCertification() {
    if (certification === undefined) {
      return false;
    }
    return true;
  }

  function validate() {
    return !!validateCertification();
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: 20,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <h3 style={{ margin: 0 }}>Welcome to Pagerr!</h3>
        </div>
        <p
          style={{
            textAlign: "right",
            fontSize: 12,
            fontWeight: 700,
            color: "#fff",
            paddingBlock: 4,
            paddingInline: 8,
            borderRadius: 8,
            background: Colors.blue,
          }}
        >
          Step 2/4
        </p>
      </div>
      <p style={{ marginBottom: 24 }}>Tell us about yourself.</p>

      <div style={{ marginBottom: 16 }}>
        <p style={{ fontWeight: 600 }}>
          What is your board certification status?
        </p>
        <div>
          <fieldset
            name="certification"
            style={{
              padding: 0,
              border: "none",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <label
              style={{
                display: "flex",
                flex: 1,
                background:
                  certification === "board certified" ? Colors.border : "none",
                border:
                  certification === "board certified"
                    ? `1px solid ${Colors.blue}`
                    : "1px solid transparent",
                padding: 4,
                borderRadius: 4,
                margin: 2,
                alignItems: "center",
                cursor: "pointer",
                flexDirection: "row",
                fontSize: 16,
              }}
            >
              <input
                type="radio"
                name="certification"
                value="board certified"
                onChange={() => setCertification("board certified")}
                checked={certification === "board certified"}
              />
              Board Certified
            </label>
            <label
              style={{
                display: "flex",
                flex: 1,
                background:
                  certification === "board eligible" ? Colors.border : "none",
                border:
                  certification === "board eligible"
                    ? `1px solid ${Colors.blue}`
                    : "1px solid transparent",
                padding: 4,
                borderRadius: 4,
                margin: 2,
                alignItems: "center",
                cursor: "pointer",
                flexDirection: "row",
                fontSize: 16,
              }}
            >
              <input
                type="radio"
                name="certification"
                value="board certified"
                onChange={() => setCertification("board eligible")}
                checked={certification === "board eligible"}
              />
              <p style={{ flexShrink: 0, maxLines: 1 }}>Board Eligible</p>
            </label>
            <label
              style={{
                display: "flex",
                flex: 1,
                background: certification === "none" ? Colors.border : "none",
                border:
                  certification === "none"
                    ? `1px solid ${Colors.blue}`
                    : "1px solid transparent",

                padding: 4,
                borderRadius: 4,
                margin: 2,
                alignItems: "center",
                cursor: "pointer",
                flexDirection: "row",
                fontSize: 16,
              }}
            >
              <input
                type="radio"
                name="certification"
                value="board certified"
                onChange={() => setCertification("none")}
                checked={certification === "none"}
              />
              <p style={{ flexShrink: 0, maxLines: 1 }}>None</p>
            </label>
          </fieldset>
        </div>
      </div>
      <div style={{ marginBottom: 16 }}>
        <p style={{ fontWeight: 600 }}>
          Do you have any specialties or fellowships?
        </p>
        <fieldset
          name="specialty"
          style={{
            padding: 0,
            border: "none",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {specialtyList.map(function (item: any) {
            return (
              <label
                key={JSON.stringify(item)}
                style={{
                  display: "flex",
                  flex: 1,
                  background:
                    specialties.includes(item) === true
                      ? Colors.border
                      : "none",
                  border:
                    specialties.includes(item) === true
                      ? `1px solid ${Colors.blue}`
                      : "1px solid transparent",
                  padding: 4,
                  borderRadius: 4,
                  margin: 2,
                  alignItems: "center",
                  cursor: "pointer",
                  flexDirection: "row",
                  fontSize: 16,
                }}
              >
                <input
                  type="checkbox"
                  name="specialty"
                  value={item}
                  checked={specialties.includes(item)}
                  onChange={
                    specialties.includes(item) === false
                      ? () => setSpecialties([...specialties, item])
                      : () => {
                          setSpecialties(
                            specialties.filter(function (f) {
                              return f !== item;
                            })
                          );
                        }
                  }
                />
                {item.charAt(0).toUpperCase() + item.slice(1)}
              </label>
            );
          })}
        </fieldset>
      </div>
      <ButtonUnstyled
        onClick={
          validate() === true
            ? () => {
                signup.specialties.setValue(specialties);
                signup.boardCertification.setValue(certification);

                globals.api.updateProvider
                  .call(
                    globals.callOptions(),
                    {},
                    {
                      boardCertification: certification!,
                      specialties: (specialties ?? []).map((c) => ({
                        specialty: c,
                      })),
                    }
                  )
                  .then((result) => {
                    if (result.status === 200) {
                      signup.boardCertification.setValue(undefined);
                      signup.specialties.setValue(undefined);
                      navigate("../3");
                    } else {
                      alert("Error saving.");
                    }
                  });
              }
            : undefined
        }
        style={{
          margin: 12,
          borderRadius: 4,
          padding: 12,
          fontSize: 14,
          color: validate() === true ? Colors.blue : "lightgray",
          fontWeight: "600",
          background: Colors.blueBrightPrimary,
          border:
            validate() === true
              ? `1px solid ${Colors.blue}`
              : "1px solid lightgray",
          cursor: validate() === true ? "pointer" : undefined,
        }}
        disabled={false}
      >
        Continue
      </ButtonUnstyled>
    </div>
  );
}
