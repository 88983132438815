import { Link } from "@mui/material";
import { FaStethoscope } from "react-icons/fa";
import { Colors } from "../Colors";

export default function EmailVerification() {
  return (
    <div
      style={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          margin: 12,
        }}
      >
        <div
          style={{
            background: Colors.blue,
            borderRadius: 30,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            height: 56,
            width: 56,
            marginRight: 6,
          }}
        >
          <FaStethoscope style={{ color: "#FBFBFD" }} size={24} />
        </div>
        <h2
          style={{
            color: Colors.blue,
            fontSize: 56,
          }}
        >
          Pagerr
        </h2>
      </div>
      <div
        style={{
          paddingBottom: 120,
          borderRadius: 8,
          background: "#ffffff",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <p style={{ marginBottom: 20 }}>
          Welcome to Pagerr! Your email has been verified.{" "}
        </p>
        <Link style={{ cursor: "pointer" }}>Click here to get started.</Link>
      </div>
      <div
        style={{
          paddingBottom: 120,
          borderRadius: 8,
          background: "#ffffff",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <p style={{ marginBottom: 20 }}>
          It looks like there was an issue verifying your email.
        </p>
        <Link style={{ cursor: "pointer" }}>Click here to resend.</Link>
      </div>
    </div>
  );
}
