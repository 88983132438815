import { ButtonUnstyled } from "@mui/base";
import "../App.css";
import { useContext, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Dialog,
  Link,
} from "@mui/material";
import { MdChevronRight, MdLocationPin, MdSchedule } from "react-icons/md";
import {
  FaCheck,
  FaChevronCircleDown,
  FaChevronRight,
  FaExclamationCircle,
} from "react-icons/fa";
import { Colors } from "../Colors";
import { GlobalStateContext } from "../contexts/GlobalStateContext";
import { usePostState } from "../helpers/usePostState";
import { Oval } from "react-loader-spinner";
import moment from "moment";

export default function ProviderJobs() {
  const globalContext = useContext(GlobalStateContext);

  const [loading, setLoading] = useState<boolean>(false);

  const [unclaimConfirmation, setUnclaimConfirmation] = useState<
    undefined | { title: "error" | "success"; message: string }
  >(undefined);

  const [cancelVisible, setCancelVisible] = useState<
    undefined | { id: string; date: string }
  >(undefined);

  const [jobs] = usePostState(
    globalContext.api.myJobsListingView,
    globalContext.callOptions(),
    {},
    {
      filter: {
        state: "active",
      },
      pagination: {
        pageSize: 1000000,
        page: 1,
      },
      sort: { sortBy: "Date / Time", sortOrder: "asc" },
    }
  );

  return (
    <>
      <Dialog
        open={!!cancelVisible}
        onClose={() => setCancelVisible(undefined)}
        disableScrollLock
      >
        <div
          style={{
            background: "#fff",
            display: "flex",
            flexDirection: "column",
            borderRadius: 20,
            maxWidth: 520,
            padding: 20,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: 12,
            }}
          >
            <p
              style={{
                fontSize: 20,
                fontWeight: 700,
                margin: 0,
                color: Colors.blue,
              }}
            >
              Are you sure you want to cancel?
            </p>
          </div>
          <div>
            <p style={{ fontSize: 16, fontWeight: 400 }}>
              Are you sure you would like to cancel this job? This action cannot
              be undone.
            </p>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignSelf: "flex-end",
              marginTop: 12,
            }}
          >
            <ButtonUnstyled
              style={{
                cursor: "pointer",
                margin: 4,
                border: "0px",
                borderRadius: 4,
                paddingBlock: 6,
                paddingInline: 8,
                background: "#ffffff",
                color: "gray",
                fontWeight: 600,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
              }}
              onClick={() => setCancelVisible(undefined)}
            >
              GO BACK
            </ButtonUnstyled>
            <ButtonUnstyled
              disabled={loading}
              style={{
                cursor: "pointer",
                margin: 4,
                border: `1px solid ${Colors.blue}`,
                borderRadius: 4,
                paddingBlock: 6,
                paddingInline: 8,
                background: Colors.border,
                color: Colors.blue,
                fontWeight: 600,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                boxShadow: "2px 2px 4px rgba(0,0,0,0.2)",
              }}
              onClick={() => {
                setLoading(true);
                globalContext.api.unclaimJob
                  .call(
                    globalContext.callOptions(),
                    {},
                    {
                      jobId: cancelVisible?.id!,
                    }
                  )
                  .then((result) => {
                    if (result.status === 200) {
                      setLoading(false);
                      setUnclaimConfirmation({
                        title: "success",
                        message: "You have successfully unclaimed this job.",
                      });
                    } else {
                      setLoading(false);
                      setCancelVisible(undefined);
                      setUnclaimConfirmation({
                        title: "error",
                        message: "There was an error unclaiming this job.",
                      });
                    }
                  });
              }}
            >
              {loading === true ? (
                <Oval
                  color={Colors.blue}
                  secondaryColor={Colors.blue}
                  height={20}
                  strokeWidth={8}
                />
              ) : (
                "CONFIRM UNCLAIM JOB"
              )}
            </ButtonUnstyled>
          </div>
        </div>
      </Dialog>
      <Dialog
        open={!!unclaimConfirmation}
        onClose={
          unclaimConfirmation?.title === "success"
            ? () => {
                setCancelVisible(undefined);
                setLoading(false);
                setUnclaimConfirmation(undefined);
              }
            : () => {
                setLoading(false);
                setUnclaimConfirmation(undefined);
              }
        }
      >
        <div
          style={{
            background: "#fff",
            display: "flex",
            flexDirection: "column",
            borderRadius: 20,
            padding: 20,
            gap: 12,
          }}
        >
          <p
            style={{
              fontSize: 20,
              fontWeight: 700,
              margin: 0,
              color: Colors.blue,
            }}
          >
            {unclaimConfirmation?.title.toUpperCase()}
          </p>
          <p
            style={{
              fontSize: 16,
              fontWeight: 500,
              margin: 0,
            }}
          >
            {unclaimConfirmation?.message}
          </p>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignSelf: "flex-end",
            }}
          >
            <ButtonUnstyled
              onClick={
                unclaimConfirmation?.title === "success"
                  ? () => {
                      setCancelVisible(undefined);
                      setLoading(false);
                      setUnclaimConfirmation(undefined);
                    }
                  : () => {
                      setLoading(false);
                      setUnclaimConfirmation(undefined);
                    }
              }
              style={{
                cursor: "pointer",
                border: `1px solid ${Colors.blue}`,
                borderRadius: 4,
                paddingBlock: 6,
                paddingInline: 20,
                background: Colors.border,
                color: Colors.blue,
                fontWeight: 600,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                boxShadow: "2px 2px 4px rgba(0,0,0,0.2)",
                fontSize: 16,
              }}
            >
              OKAY
            </ButtonUnstyled>
          </div>
        </div>
      </Dialog>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          flexDirection: "column",
          flex: 1,
          marginBottom: 320,
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "flex-start",
            flexDirection: "column",
            marginBottom: 24,
          }}
        >
          <h1
            style={{
              margin: 0,
              fontSize: 32,
              marginBottom: 12,
              fontWeight: "700",
            }}
          >
            Your Upcoming Jobs
          </h1>
          <p style={{ color: Colors.lightGray }}>
            These are jobs that you have signed up for.
          </p>
        </div>

        {globalContext.sessionInfo.value?.provider?.status ===
        "approved" ? null : (
          <div
            style={{
              padding: 20,
              background: Colors.border,
              border: `1px solid ${Colors.blue}`,
              color: Colors.blue,
              fontWeight: 600,
              borderRadius: 8,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginBottom: 8,
            }}
          >
            <FaExclamationCircle
              style={{
                color: Colors.blue,
                marginRight: 12,
                flexShrink: 0,
              }}
              size={24}
            />
            <p>
              Welcome to Pagerr! We're still validating your account, so for now
              you won't be able to claim shifts. Expect an email from us once
              your account has been validated.
            </p>
          </div>
        )}

        <div>
          {globalContext.sessionInfo.value?.provider?.status === "pending" ? (
            <></>
          ) : jobs?.jobs.length === 0 ? (
            <div
              style={{
                padding: 20,
                background: Colors.border,
                border: `1px solid ${Colors.blue}`,
                color: Colors.blue,
                fontWeight: 600,
                borderRadius: 8,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <FaExclamationCircle
                size={24}
                style={{ marginRight: 12, flexShrink: 0 }}
              />
              <p>
                Looks like you aren't signed up for any jobs right now. Head
                over and&nbsp;
                <Link
                  style={{ color: Colors.blue, cursor: "pointer" }}
                  href={"/provider/listings"}
                >
                  see what jobs are available!
                  <MdChevronRight size={12} style={{ marginLeft: 8 }} />
                </Link>
              </p>
            </div>
          ) : (
            <></>
          )}
          <div style={{ display: "flex", flexDirection: "column", gap: 12 }}>
            {(jobs?.jobs ?? []).map(function (job) {
              return (
                <Accordion
                  key={job._id}
                  className={"jobCard"}
                  style={{
                    padding: 0,
                    display: "flex",
                    flexDirection: "column",
                    flex: 1,
                    border: "1px solid #eaeaea",
                    borderRadius: 8,
                    // marginInline: 8,
                    // marginBottom: 20,
                  }}
                  sx={{
                    "&:before": {
                      display: "none",
                    },
                    boxShadow: "2px 2px 4px rgba(0,0,0,0.05)",
                    "&:hover": {
                      boxShadow: "2px 2px 4px rgba(0,0,0,0.1)",
                    },
                  }}
                  disableGutters={true}
                >
                  <AccordionSummary
                    style={{
                      display: "flex",
                      flex: 1,
                      background: "transparent",
                      color:
                        new Date() > new Date(job.endDate)
                          ? "gray"
                          : Colors.blue,
                    }}
                    expandIcon={
                      <FaChevronCircleDown color={Colors.lightGray} size={16} />
                    }
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                        flex: 1,
                        marginRight: 8,
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <div
                          style={{
                            background:
                              new Date() > new Date(job.endDate)
                                ? Colors.border
                                : "none",
                            height: 20,
                            width: 20,
                            borderRadius: 30,
                            flexShrink: 0,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            border:
                              new Date() > new Date(job.endDate)
                                ? `2px solid ${Colors.lightGray}`
                                : `2px solid ${Colors.border}`,
                          }}
                        >
                          {new Date() > new Date(job.endDate) ? (
                            <FaCheck color={Colors.lightGray} size={12} />
                          ) : null}
                        </div>
                        <p
                          style={{
                            marginRight: 0,
                            marginBlock: 0,
                            fontWeight: 700,
                            marginLeft: 8,
                            color: job.cancelledDate ? Colors.red : undefined,
                          }}
                        >
                          {new Date(job.startDate).toLocaleString("en-US", {
                            month: "long",
                            day: "numeric",
                          })}
                          &nbsp;at&nbsp;
                          {job.facilityName}
                        </p>
                      </div>
                      {job.cancelledDate ? (
                        <div
                          style={{
                            background: Colors.red,

                            paddingBlock: 4,
                            paddingInline: 12,
                            borderRadius: 40,
                            margin: 2,
                          }}
                        >
                          <p
                            style={{
                              margin: 0,
                              fontWeight: 700,
                              fontSize: 16,
                              color: "#fff",
                            }}
                          >
                            Cancelled
                          </p>
                        </div>
                      ) : (
                        <div
                          style={{
                            background: Colors.border,
                            paddingBlock: 4,
                            paddingInline: 12,
                            borderRadius: 40,
                            margin: 2,
                          }}
                        >
                          <p
                            style={{
                              margin: 0,
                              fontWeight: 700,
                              fontSize: 16,
                            }}
                          >
                            $
                            {globalContext.sessionInfo.value?.provider
                              .providerType === "MD/DO"
                              ? job.mddoRate === 0
                                ? "W2 Rate"
                                : Math.floor(
                                    (job.mddoRate! *
                                      moment(job!.endDate).diff(
                                        moment(job!.startDate)
                                      )) /
                                      3600000
                                  ).toLocaleString("en-US") ?? null
                              : null}
                            {globalContext.sessionInfo.value?.provider
                              .providerType === "CRNA"
                              ? job.crnaRate === 0
                                ? "W2 Rate"
                                : Math.floor(
                                    (job.crnaRate! *
                                      moment(job!.endDate).diff(
                                        moment(job!.startDate)
                                      )) /
                                      3600000
                                  ).toLocaleString("en-US") ?? null
                              : null}{" "}
                            (
                            {globalContext.sessionInfo.value?.provider
                              .providerType === "MD/DO"
                              ? job.mddoRate === 0
                                ? "W2 Rate"
                                : `$${job.mddoRate!}/hr`
                              : null}
                            {globalContext.sessionInfo.value?.provider
                              .providerType === "CRNA"
                              ? job.crnaRate === 0
                                ? "W2 Rate"
                                : `$${job.crnaRate!}/hr`
                              : null}
                            )
                          </p>
                        </div>
                      )}
                    </div>
                  </AccordionSummary>
                  <AccordionDetails
                    style={{
                      display: "flex",
                      flex: 1,
                      background: "transparent",
                      flexDirection: "column",
                    }}
                  >
                    <div style={{ marginBottom: 4 }}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "space-between",
                          // marginTop: 4,
                          flexWrap: "wrap",
                          gap: 4,
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            background: Colors.blue,
                            color: "#fff",
                            paddingBlock: 4,
                            paddingInline: 8,
                            borderRadius: 4,
                            gap: 4,
                          }}
                        >
                          <MdSchedule size={18} style={{ flexShrink: 0 }} />
                          <p style={{ fontSize: 16, fontWeight: 500 }}>
                            {job.startTime}
                            {" to "}
                            {job.endTime}
                          </p>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            background: Colors.blue,
                            color: "#fff",
                            paddingBlock: 4,
                            paddingInline: 8,
                            borderRadius: 4,
                            gap: 4,
                          }}
                        >
                          <MdLocationPin size={18} style={{ flexShrink: 0 }} />
                          <p style={{ fontSize: 16, fontWeight: 500 }}>
                            {job.facilityAddress.address1}
                            &nbsp;
                            {job.facilityAddress.address2},{" "}
                            {job.facilityAddress.city},{" "}
                            {job.facilityAddress.state}{" "}
                            {job.facilityAddress.zip}
                          </p>
                        </div>
                      </div>
                    </div>
                    <p style={{ marginBottom: 12, flex: 1 }}>
                      <p
                        style={{
                          marginBottom: 4,
                          fontWeight: 700,
                          fontSize: 14,
                        }}
                      >
                        JOB DESCRIPTION
                      </p>
                      {job.description}
                    </p>
                    <div style={{ marginBottom: 12 }}>
                      <p
                        style={{
                          // marginBottom: 4,
                          fontWeight: 700,
                          fontSize: 14,
                        }}
                      >
                        SPECIALTY REQUIREMENTS
                      </p>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          flexWrap: "wrap",
                          gap: 4,
                        }}
                      >
                        {job.specialties.map(function (item) {
                          return (
                            <div
                              key={item.specialty}
                              style={{
                                background: "#eaeaea",
                                paddingBlock: 4,
                                paddingInline: 12,
                                borderRadius: 12,
                                // margin: 4,
                              }}
                            >
                              <p style={{ fontWeight: 500, maxLines: 1 }}>
                                {item.specialty}
                              </p>
                            </div>
                          );
                        })}
                      </div>
                      {job.cancelledDate ? null : (
                        <div
                          style={{
                            display: "flex",
                            flex: 1,
                            justifyContent: "flex-end",
                            marginTop: 8,
                          }}
                        >
                          <ButtonUnstyled
                            style={{
                              cursor: "pointer",
                              border: `1px solid ${Colors.red}`,
                              borderRadius: 4,
                              paddingBlock: 6,
                              paddingInline: 8,
                              background: "#fff",
                              color: Colors.red,
                              fontWeight: 600,
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              justifyContent: "space-between",
                              boxShadow: "2px 2px 4px rgba(0,0,0,0.2)",
                            }}
                            onClick={() => {
                              setCancelVisible({
                                id: job._id,
                                date: job.startDate,
                              });
                            }}
                          >
                            UNCLAIM JOB
                            <FaChevronRight
                              style={{ marginLeft: 20 }}
                              size={10}
                            />
                          </ButtonUnstyled>
                        </div>
                      )}
                    </div>
                  </AccordionDetails>
                </Accordion>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
}
