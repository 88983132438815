import {
  ApiCallOptions,
  EndPointType,
  EndPointTypeDefinition,
  FlatEndPointType,
  FlatEndPointTypeDefinition,
  HttpResponse,
  ProviderJobListingView,
} from "@xaratan/pagerr-common";
import { Dispatch, SetStateAction, useEffect, useState } from "react";

type PostCaller<
  RequestParamsType extends FlatEndPointTypeDefinition,
  RequestBodyType extends EndPointTypeDefinition,
  ResponseType extends EndPointTypeDefinition
> = (
  callOptions: ApiCallOptions,
  requestParameters: FlatEndPointType<RequestParamsType>,
  requestBody: EndPointType<RequestBodyType>,
  abortController?: AbortController
) => Promise<HttpResponse<EndPointType<ResponseType>>>;

type PostEndPoint<
  RequestParamsType extends FlatEndPointTypeDefinition,
  RequestBodyType extends EndPointTypeDefinition,
  ResponseType extends EndPointTypeDefinition
> = {
  call: PostCaller<RequestParamsType, RequestBodyType, ResponseType>;
};

export function usePostState<
  RequestParamsType extends FlatEndPointTypeDefinition,
  RequestBodyType extends EndPointTypeDefinition,
  ResponseType extends EndPointTypeDefinition
>(
  endPoint: PostEndPoint<RequestParamsType, RequestBodyType, ResponseType>,
  callOptions: ApiCallOptions,
  requestParameters: FlatEndPointType<RequestParamsType>,
  requestBody: EndPointType<RequestBodyType>,
  loadOnMount: boolean = true
): [
  EndPointType<ResponseType> | undefined,
  Dispatch<SetStateAction<EndPointType<ResponseType> | undefined>>,
  (
    abortController?: AbortController,
    parameters?: FlatEndPointType<RequestParamsType>,
    body?: EndPointType<RequestBodyType>
  ) => void
] {
  const [state, setState] = useState<EndPointType<ResponseType> | undefined>(
    undefined
  );

  function load(
    abortController?: AbortController,
    parameters?: FlatEndPointType<RequestParamsType>,
    body?: EndPointType<RequestBodyType>
  ) {
    endPoint
      .call(
        callOptions,
        parameters ?? requestParameters,
        body ?? requestBody,
        abortController
      )
      .then((response) => {
        if (response.status === 200) {
          setState(response.data);
        } else {
          //Let the failure handler take care of any issues.
        }
      })
      .catch((err) => {
        //Let the failure handler take care of any issues.
      });
  }

  useEffect(() => {
    if (loadOnMount) {
      const abortController = new AbortController();

      load(abortController);

      return () => {
        abortController.abort();
      };
    }
  }, []);

  return [state, setState, load];
}
