import { Dialog, ListItem } from "@mui/material";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ButtonUnstyled } from "@mui/base";
import { Colors } from "../Colors";
import { GlobalStateContext } from "../contexts/GlobalStateContext";
import moment from "moment";
import { Oval } from "react-loader-spinner";
import { FaExclamationCircle } from "react-icons/fa";

export default function FacilityAddListing() {
  const navigate = useNavigate();
  const globalContext = useContext(GlobalStateContext);
  const [confirmVisible, setConfirmVisible] = useState(false);

  const [md, setMd] = useState<boolean>(false);
  const [mdRate, setMdRate] = useState<number>(0);
  const [mdRateExtra, setMdRateExtra] = useState<number>(0);

  const [crna, setCrna] = useState<boolean>(false);
  const [crnaRate, setCrnaRate] = useState<number>(0);
  const [crnaRateExtra, setCrnaRateExtra] = useState<number>(0);

  const [description, setDescription] = useState<string>("");

  const [location, setLocation] = useState<string | undefined>(undefined);
  const [specialties, setSpecialties] = useState<string[]>([]);
  const [certification, setCertification] = useState<string | undefined>(
    undefined
  );

  const [employment, setEmployment] = useState<
    { w2: boolean; 1099: boolean } | undefined
  >(undefined);

  const [startDate, setStartDate] = useState<string>(
    new Date().toLocaleDateString("en-CA")
  );
  const [startHour, setStartHour] = useState<number>(12);
  const [startMinute, setStartMinute] = useState<string>("00");
  const [startAmPm, setStartAmPm] = useState<number>(0);

  const [endDate, setEndDate] = useState<string>(
    new Date().toLocaleDateString("en-CA")
  );
  const [endHour, setEndHour] = useState<number>(12);
  const [endMinute, setEndMinute] = useState<string>("00");
  const [endAmPm, setEndAmPm] = useState<number>(0);

  var startTime = `${startDate}T${(startHour === 12 && startAmPm === 0
    ? 0
    : startHour === 12 && startAmPm === 12
    ? 12
    : startHour + startAmPm
  )
    .toString()
    .padStart(2, "0")}:${startMinute}`;
  var endTime = `${endDate}T${(endHour === 12 && endAmPm === 0
    ? 0
    : endHour === 12 && endAmPm === 12
    ? 12
    : endHour + endAmPm
  )
    .toString()
    .padStart(2, "0")}:${endMinute}`;

  const [loading, setLoading] = useState<boolean>(false);

  const Specialties = [
    { id: "general anesthesia", label: "General Anesthesia" },
    { id: "obstetric", label: "Obstetric" },
    { id: "cardiac", label: "Cardiac" },
    { id: "regional", label: "Regional with Ultrasound" },
    { id: "critical care", label: "Critical Care" },
    { id: "pediatric (<1 years old)", label: "Pediatric (<1 years old)" },
    { id: "pediatric (<5 years old)", label: "Pediatric (<5 years old)" },
    { id: "pediatric (5-18 years old)", label: "Pediatric (5-18 years old)" },
  ];

  const Certifications = [
    { id: "board certified", label: "Board Certified" },
    { id: "board eligible", label: "Board Eligible" },
    { id: "none", label: "None" },
  ];

  const EmploymentTypes = [
    { label: "All Providers (W2 and 1099)", value: { w2: true, 1099: true } },
    { label: "Employees Only(W2)", value: { w2: true, 1099: false } },
    { label: "Locums Only (1099)", value: { w2: false, 1099: true } },
  ];

  function validateDescription(): boolean | string {
    if (description.length === 0) {
      return "Add a description";
    }
    return true;
  }

  function validateFacility(): boolean | string {
    if (location === undefined || location === "Select a facility...") {
      return "Please select a facility";
    }
    return true;
  }

  function validateStartDate(): boolean | string {
    if (startDate === "") {
      return "Please select a start date";
    }
    if (new Date() > new Date(startTime)) {
      return "Cannot be in the past";
    }

    return true;
  }

  function validateEndDate(): string | boolean {
    if (
      new Date(startTime) >
      new Date(
        `${endDate}T${(endHour === 12 && endAmPm === 0
          ? 0
          : endHour === 12 && endAmPm === 12
          ? 12
          : endHour + endAmPm
        )
          .toString()
          .padStart(2, "0")}:${endMinute}`
      )
    ) {
      return "Must be on/after start date";
    }
    return true;
  }

  function validateDuration(): boolean | string {
    if (new Date(endTime) < new Date(startTime)) {
      return "Cannot be less than 0";
    }

    return true;
  }

  function validateSpecialties(): string | boolean {
    if (specialties.length === 0) {
      return "Select at least one specialty";
    }
    return true;
  }

  function validateBoardCertification(): string | boolean {
    if (certification === undefined) {
      return "Select one";
    }
    return true;
  }

  function validateEmployeeType(): string | boolean {
    if (employment === undefined) {
      return "Select one";
    }
    return true;
  }

  function validateRates(): string | boolean {
    if (md === false && crna === false) {
      return "Select at least one";
    }
    return true;
  }

  function validateMdRate(): string | boolean {
    if (md === true && mdRate < 100 && employment?.[1099] === true) {
      return "Please input a rate greater than $100/hour";
    }

    if (md === true && employment?.[1099] === false) return true;

    return true;
  }

  function validateCrnaRate(): string | boolean {
    if (crna === true && crnaRate < 100 && employment?.[1099] === true) {
      return "Please input a rate greater than $100/hour";
    }

    if (crna === true && employment?.[1099] === false) return true;
    return true;
  }

  function validate() {
    if (
      validateDescription() === true &&
      validateFacility() === true &&
      validateStartDate() === true &&
      validateEndDate() === true &&
      validateSpecialties() === true &&
      validateBoardCertification() === true &&
      validateEmployeeType() === true &&
      validateRates() === true &&
      validateMdRate() === true &&
      validateCrnaRate() === true
    ) {
      return true;
    }
    return false;
  }

  const ProviderTypes = [
    {
      label: "MD/DO",
      toggle: md,
      toggleFunction: () => setMd(!md),
      rate: mdRate,
      setRate: (e: any) => setMdRate(e.currentTarget.value),
      validate: validateMdRate(),
    },
    {
      label: "CRNA",
      toggle: crna,
      toggleFunction: () => setCrna(!crna),
      rate: crnaRate,
      setRate: (e: any) => setCrnaRate(e.currentTarget.value),
      validate: validateCrnaRate(),
    },
  ];

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-start",
        flexDirection: "column",
        flex: 1,
      }}
    >
      <Dialog
        open={confirmVisible}
        onClose={() => setConfirmVisible(!confirmVisible)}
        disableScrollLock
      >
        <div
          style={{
            background: "#ffffff",
            display: "flex",
            flexDirection: "column",
            borderRadius: 20,
            padding: 20,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: 12,
            }}
          >
            <p style={{ fontSize: 20, fontWeight: 700, margin: 0 }}>Success!</p>
          </div>
          <div>
            <p style={{ fontSize: 14, fontWeight: 400 }}>
              Your job has been posted. We'll let you know once it's been filled
              by a provider.
            </p>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignSelf: "flex-end",
              marginTop: 12,
            }}
          >
            <ButtonUnstyled
              style={{
                cursor: "pointer",
                margin: 4,
                borderRadius: 4,
                paddingBlock: 6,
                paddingInline: 8,
                fontWeight: 600,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                background: Colors.border,
                color: Colors.blue,
                border: `1px solid ${Colors.blue}`,
              }}
              onClick={() => {
                navigate("/facility/listings");
              }}
            >
              <p>SOUNDS GOOD</p>
            </ButtonUnstyled>
          </div>
        </div>
      </Dialog>

      <div
        style={{
          display: "flex",
          alignItems: "flex-start",
          flexDirection: "column",
          marginBottom: 24,
        }}
      >
        <h1
          style={{
            margin: 0,
            fontSize: 32,
            marginBottom: 12,
            fontWeight: "700",
          }}
        >
          Add Job Listing
        </h1>
        <p style={{ color: Colors.lightGray }}>
          Add enough detail so providers can quickly decide whether or not they
          want to pick up a job.
        </p>
      </div>
      <div
        style={{
          marginBottom: 28,
          border: "1px solid #eaeaea",
          borderRadius: 8,
          padding: 12,
        }}
      >
        <form
          style={{
            margin: 12,
            display: "flex",
            flexDirection: "column",
            // maxWidth: 620,
            gap: 48,
          }}
        >
          <DetailContainer label="Facility" validation={validateFacility()}>
            <label>
              <select
                style={{
                  borderRadius: 8,
                  fontSize: 16,
                  padding: 8,
                  border:
                    validateFacility() === true
                      ? undefined
                      : `1px solid ${Colors.blue}`,
                }}
                onChange={(e) => {
                  setLocation(e.target.value);
                }}
              >
                <option value={undefined} label={"Select a facility..."}>
                  Select a facility...
                </option>
                {globalContext.sessionInfo.value?.facilities.map(function (
                  item
                ) {
                  return (
                    <option key={item._id} value={item._id} label={item.name}>
                      {item.name}
                    </option>
                  );
                })}
              </select>
            </label>
          </DetailContainer>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: 24,
              flexWrap: "wrap",
            }}
          >
            <DetailContainer
              label="Shift Start"
              validation={validateStartDate()}
            >
              <label
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  gap: 4,
                }}
              >
                <input
                  type={"date"}
                  onChange={(e) => setStartDate(e.currentTarget.value)}
                  value={startDate}
                  min={new Date().toLocaleDateString("en-CA")}
                  style={{
                    border:
                      validateStartDate() === true
                        ? undefined
                        : `1px solid ${Colors.blue}`,
                  }}
                />
                <select
                  style={{
                    padding: 8,
                    paddingRight: 24,
                    fontSize: 16,
                    border:
                      validateStartDate() === true
                        ? undefined
                        : `1px solid ${Colors.blue}`,
                  }}
                  value={startHour}
                  onChange={(e) =>
                    setStartHour(parseInt(e.currentTarget.value))
                  }
                >
                  <option value={1}>1</option>
                  <option value={2}>2</option>
                  <option value={3}>3</option>
                  <option value={4}>4</option>
                  <option value={5}>5</option>
                  <option value={6}>6</option>
                  <option value={7}>7</option>
                  <option value={8}>8</option>
                  <option value={9}>9</option>
                  <option value={10}>10</option>
                  <option value={11}>11</option>
                  <option value={12}>12</option>
                </select>
                <select
                  style={{
                    padding: 8,
                    paddingRight: 24,
                    fontSize: 16,
                    border:
                      validateStartDate() === true
                        ? undefined
                        : `1px solid ${Colors.blue}`,
                  }}
                  value={startMinute}
                  onChange={(e) => setStartMinute(e.currentTarget.value)}
                >
                  <option value="00">00</option>
                  <option value="15">15</option>
                  <option value="30">30</option>
                  <option value="45">45</option>
                </select>
                <select
                  style={{
                    padding: 8,
                    paddingRight: 24,
                    fontSize: 16,
                    border:
                      validateStartDate() === true
                        ? undefined
                        : `1px solid ${Colors.blue}`,
                  }}
                  value={startAmPm}
                  onChange={(e) => {
                    setStartAmPm(parseInt(e.currentTarget.value));
                  }}
                >
                  <option value={0}>AM</option>
                  <option value={12}>PM</option>
                </select>
              </label>
            </DetailContainer>

            <DetailContainer label="Shift End" validation={validateEndDate()}>
              <label
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  gap: 4,
                }}
              >
                <input
                  type={"date"}
                  onChange={(e) => setEndDate(e.currentTarget.value)}
                  value={endDate}
                  min={startDate}
                  style={{
                    border:
                      validateEndDate() === true
                        ? undefined
                        : `1px solid ${Colors.blue}`,
                  }}
                />
                <select
                  style={{
                    padding: 8,
                    paddingRight: 24,
                    fontSize: 16,
                    border:
                      validateEndDate() === true
                        ? undefined
                        : `1px solid ${Colors.blue}`,
                  }}
                  value={endHour}
                  onChange={(e) => setEndHour(parseInt(e.currentTarget.value))}
                >
                  <option value={1}>1</option>
                  <option value={2}>2</option>
                  <option value={3}>3</option>
                  <option value={4}>4</option>
                  <option value={5}>5</option>
                  <option value={6}>6</option>
                  <option value={7}>7</option>
                  <option value={8}>8</option>
                  <option value={9}>9</option>
                  <option value={10}>10</option>
                  <option value={11}>11</option>
                  <option value={12}>12</option>
                </select>
                <select
                  style={{
                    padding: 8,
                    paddingRight: 24,
                    fontSize: 16,
                    border:
                      validateEndDate() === true
                        ? undefined
                        : `1px solid ${Colors.blue}`,
                  }}
                  value={endMinute}
                  onChange={(e) => setEndMinute(e.currentTarget.value)}
                >
                  <option value="00">00</option>
                  <option value="15">15</option>
                  <option value="30">30</option>
                  <option value="45">45</option>
                </select>
                <select
                  style={{
                    padding: 8,
                    paddingRight: 24,
                    fontSize: 16,
                    border:
                      validateEndDate() === true
                        ? undefined
                        : `1px solid ${Colors.blue}`,
                  }}
                  value={endAmPm}
                  onChange={(e) => {
                    setEndAmPm(parseInt(e.currentTarget.value));
                  }}
                >
                  <option value={0}>AM</option>
                  <option value={12}>PM</option>
                </select>
              </label>
            </DetailContainer>

            {/* <DetailContainer label="Duration" validation={validateDuration()}>
              {validateDuration() === true ? (
                <p
                  style={{
                    padding: 10,
                    borderRadius: 8,
                    background: Colors.border,
                    width: 120,
                  }}
                >
                  {Math.floor(
                    moment(
                      new Date(
                        `${endDate}T${(endHour === 12 && endAmPm === 0
                          ? 0
                          : endHour === 12 && endAmPm === 12
                          ? 12
                          : endHour + endAmPm
                        )
                          .toString()
                          .padStart(2, "0")}:${endMinute}`
                      )
                    ).diff(
                      moment(
                        new Date(
                          `${startDate}T${(startHour === 12 && startAmPm === 0
                            ? 0
                            : startHour === 12 && startAmPm === 12
                            ? 12
                            : startHour + startAmPm
                          )
                            .toString()
                            .padStart(2, "0")}:${startMinute}`
                        )
                      )
                    ) / 3600000
                  ).toFixed(0)}
                  h&nbsp;
                  {Math.round(
                    (Math.abs(
                      moment(
                        new Date(
                          `${endDate}T${(endHour === 12 && endAmPm === 0
                            ? 0
                            : endHour === 12 && endAmPm === 12
                            ? 12
                            : endHour + endAmPm
                          )
                            .toString()
                            .padStart(2, "0")}:${endMinute}`
                        )
                      ).diff(
                        moment(
                          new Date(
                            `${startDate}T${(startHour === 12 && startAmPm === 0
                              ? 0
                              : startHour === 12 && startAmPm === 12
                              ? 12
                              : startHour + startAmPm
                            )
                              .toString()
                              .padStart(2, "0")}:${startMinute}`
                          )
                        )
                      ) / 3600000
                    ) -
                      parseInt(
                        Math.floor(
                          moment(
                            new Date(
                              `${endDate}T${(endHour === 12 && endAmPm === 0
                                ? 0
                                : endHour === 12 && endAmPm === 12
                                ? 12
                                : endHour + endAmPm
                              )
                                .toString()
                                .padStart(2, "0")}:${endMinute}`
                            )
                          ).diff(
                            moment(
                              new Date(
                                `${startDate}T${(startHour === 12 &&
                                startAmPm === 0
                                  ? 0
                                  : startHour === 12 && startAmPm === 12
                                  ? 12
                                  : startHour + startAmPm
                                )
                                  .toString()
                                  .padStart(2, "0")}:${startMinute}`
                              )
                            )
                          ) / 3600000
                        ).toFixed(0)
                      )) *
                      60
                  )}
                  m
                </p>
              ) : (
                <div
                  style={{
                    background: Colors.border,
                    height: 38,
                    borderRadius: 8,
                    width: 120,
                  }}
                ></div>
              )}
            </DetailContainer> */}
          </div>
          <DetailContainer
            label="Description"
            validation={validateDescription()}
          >
            <label>
              <textarea
                rows={4}
                style={{
                  border:
                    validateDescription() === true
                      ? "1px solid lightgray"
                      : `1px solid ${Colors.blue}`,
                  padding: 8,
                  fontSize: 16,
                  borderRadius: 8,
                }}
                value={description}
                onChange={(e) => setDescription(e.currentTarget.value)}
              />
            </label>
          </DetailContainer>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              gap: "inherit",
            }}
          >
            <DetailContainer
              label="Specialty Requirements"
              validation={validateSpecialties()}
            >
              <fieldset
                style={{
                  padding: 0,
                  border: "none",
                  flexDirection: "column",
                  display: "flex",
                  flexWrap: "wrap",
                  gap: 12,
                }}
              >
                {Specialties.map(function (item) {
                  return (
                    <label
                      key={item.id}
                      style={{
                        flexDirection: "row",
                        alignItems: "center",
                        fontSize: 16,
                        fontWeight: 400,
                      }}
                    >
                      <input
                        type={"checkbox"}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setSpecialties([...specialties, item.id]);
                          } else {
                            setSpecialties(
                              specialties.filter(function (i) {
                                return i !== item.id;
                              })
                            );
                          }
                        }}
                      />
                      {item.label}
                    </label>
                  );
                })}
              </fieldset>
            </DetailContainer>
            <DetailContainer
              label="Minimum Certification Requirements"
              validation={validateBoardCertification()}
            >
              <fieldset
                name="certifications"
                style={{
                  padding: 0,
                  border: "none",
                  display: "flex",
                  flexWrap: "wrap",
                  gap: 12,
                  flexDirection: "column",
                }}
              >
                {Certifications.map(function (item) {
                  return (
                    <label
                      key={item.id}
                      style={{
                        flexDirection: "row",
                        alignItems: "center",
                        fontSize: 16,
                        fontWeight: 400,
                      }}
                    >
                      <input
                        type={"radio"}
                        name="certifications"
                        value={item.label}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setCertification(item.id);
                          }
                        }}
                      />
                      {item.label}
                    </label>
                  );
                })}
              </fieldset>
            </DetailContainer>
            <DetailContainer
              label="Accepted Employment Types"
              validation={validateEmployeeType()}
            >
              <fieldset
                name="employmentTypes"
                style={{
                  padding: 0,
                  border: "none",
                  display: "flex",
                  flexWrap: "wrap",
                  gap: 12,
                  flexDirection: "column",
                }}
              >
                {EmploymentTypes.map(function (item, index) {
                  return (
                    <label
                      key={index}
                      style={{
                        flexDirection: "row",
                        alignItems: "center",
                        fontSize: 16,
                        fontWeight: 400,
                      }}
                    >
                      <input
                        type={"radio"}
                        name="employmentTypes"
                        value={item.label}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setEmployment(item.value);
                          }
                        }}
                      />
                      {item.label}
                    </label>
                  );
                })}
              </fieldset>
            </DetailContainer>
          </div>
          <DetailContainer
            label="Accepted Provider Type(s)"
            validation={validateRates()}
          >
            <div
              style={{
                padding: 4,
                border: "none",
                flexDirection: "column",
                display: "flex",
                flexWrap: "wrap",
                gap: 12,
              }}
            >
              {ProviderTypes.map(function (type, index) {
                return (
                  <label
                    key={index}
                    style={{
                      flexDirection: "column",
                      alignItems: "flex-start",
                      fontSize: 16,
                      fontWeight: 400,
                    }}
                  >
                    <div
                      style={{
                        flexDirection: "row",
                        alignItems: "flex-start",
                        fontSize: 16,
                        fontWeight: 400,
                      }}
                    >
                      <input type={"checkbox"} onChange={type.toggleFunction} />
                      {type.label}
                    </div>
                    {employment?.[1099] === true ? (
                      type.toggle === true ? (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "flex-start",
                              marginLeft: 20,
                            }}
                          >
                            <b>Locums Rate:</b>
                            <label
                              style={{
                                margin: 0,
                                padding: 0,
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                contain: "layout",
                                fontSize: 20,
                              }}
                            >
                              <p
                                style={{
                                  position: "absolute",
                                  left: 8,
                                }}
                              >
                                $
                              </p>
                              <input
                                style={{
                                  margin: 0,
                                  paddingLeft: 28,
                                  paddingBlock: 8,
                                  paddingRight: 8,
                                  background: "#fff",
                                  maxWidth: 100,
                                  fontSize: 20,
                                  fontWeight: 600,
                                  boxShadow: "2px 2px 4px rgba(0,0,0,0.2)",
                                }}
                                value={type.rate}
                                type="number"
                                min={0}
                                onChange={type.setRate}
                              />
                              &nbsp;/hour ($
                              {(
                                (moment(new Date(endTime)).diff(
                                  moment(new Date(startTime))
                                ) /
                                  3600000) *
                                type.rate
                              ).toLocaleString("en-US", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                              &nbsp;total)
                            </label>
                            <p
                              style={{
                                fontSize: 16,
                                fontWeight: 600,
                                margin: 4,
                                color: Colors.blue,
                                height: 12,
                              }}
                            >
                              {type.validate}
                            </p>
                          </div>
                        </div>
                      ) : null
                    ) : null}
                  </label>
                );
              })}
            </div>
          </DetailContainer>
          <div
            style={{
              alignSelf: "flex-end",
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
              flexDirection: "row",
              marginBottom: 200,
            }}
          >
            {loading === true ? (
              <Oval
                color={Colors.blue}
                secondaryColor={Colors.blue}
                width={20}
                strokeWidth={8}
              />
            ) : (
              <>
                <ButtonUnstyled
                  underline="none"
                  style={{
                    fontWeight: 600,
                    fontSize: 16,
                    cursor: "pointer",
                    color: "gray",
                    border: "0px",
                    borderRadius: 4,
                    padding: 12,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    background: "#eaeaea",
                    margin: 4,
                  }}
                  onClick={() => navigate("/facility/listings")}
                >
                  Cancel
                </ButtonUnstyled>
                <input
                  style={{
                    cursor: validate() === true ? "pointer" : undefined,
                    borderRadius: 4,
                    padding: 12,
                    fontWeight: 600,
                    fontSize: 16,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    border:
                      validate() === true
                        ? `1px solid ${Colors.blue}`
                        : "1px solid transparent",
                    background: validate() === true ? Colors.border : "#eaeaea",
                    color: validate() === true ? Colors.blue : "gray",
                    margin: 4,
                  }}
                  disabled={!validate()}
                  type={"submit"}
                  value="Add Listing"
                  onClick={(e) => {
                    e.preventDefault();
                    setLoading(true);

                    if (!certification || !startTime || !endTime || !location) {
                      return;
                    }

                    globalContext.api.createJob
                      .call(
                        globalContext.callOptions(),
                        {},
                        {
                          description: description,
                          boardCertification: certification,
                          startDate: startTime,
                          endDate: endTime,
                          facilityId: location,
                          show1099s: employment?.[1099]!,
                          showW2s: employment?.w2!,
                          specialties: specialties.map((s) => ({
                            specialty: s,
                          })),
                          //TODO (Joe): Support shift and call job types
                          ...(md
                            ? {
                                mddoRate:
                                  employment?.[1099] === true
                                    ? mdRate * 100
                                    : 0,
                              }
                            : {}),
                          ...(crna
                            ? {
                                crnaRate:
                                  employment?.[1099] === true
                                    ? crnaRate * 100
                                    : 0,
                              }
                            : {}),
                          type: "shift",
                        }
                      )
                      .then((response) => {
                        if (response.status === 200) {
                          setConfirmVisible(true);
                        } else {
                          alert("Error creating job");
                        }
                      })
                      .catch((error) => {
                        //Ignore
                      });
                  }}
                />
              </>
            )}
          </div>
        </form>
      </div>
    </div>
  );
}

function DetailContainer(props: {
  children?: any;
  label: string;
  validation?: string | boolean;
}) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 4,
        // flex: 1,
        minWidth: 200,
      }}
    >
      <div
        style={{
          justifyContent: "flex-start",
          display: "flex",
          flexDirection: "row",
          gap: 12,
        }}
      >
        <b>{props.label}</b>
        {props.validation === true ? null : (
          <>
            <p
              style={{
                color: Colors.blue,
                textAlign: "center",
                alignItems: "center",
                display: "flex",
                gap: 4,
              }}
            >
              <FaExclamationCircle style={{ display: "flex", flexShrink: 0 }} />
              {props.validation}
            </p>
          </>
        )}
      </div>
      {props.children}
    </div>
  );
}
