export const Colors = {
  blue: "#443df6",
  blueBrightPrimary: "#F6F6FF",
  lightGray: "#667085",
  border: "#EFEFF4",
  blackPrimary: "#01161e",
  green: "#4FD3C4",
  lightgreen: "#C1F8CF",
  red: "#f64747",
  orange: "#FFA500",
};
