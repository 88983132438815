import { ButtonUnstyled } from "@mui/base";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { Colors } from "../Colors";
import { useGlobals } from "../contexts/useGlobals";
import PagerrLoader from "./PagerrLoader";
import { Oval } from "react-loader-spinner";

export default function AdminAddProvider() {
  const navigate = useNavigate();
  const globalState = useGlobals();

  const [loading, setLoading] = useState<boolean>(false);

  const [email, setEmail] = useState<string>("");

  function validateEmail(): string {
    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      return "Invalid email address";
    }

    return "";
  }

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-start",
        flexDirection: "column",
        flex: 1,
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "flex-start",
          flexDirection: "column",
          marginBottom: 24,
        }}
      >
        <h1
          style={{
            margin: 0,
            fontSize: 32,
            marginBottom: 12,
            fontWeight: "700",
          }}
        >
          Add Provider
        </h1>
        <p style={{ color: Colors.lightGray }}>
          Add a provider to Pagerr by entering their email below.
        </p>
      </div>
      <div style={{ maxWidth: 600, display: "flex", flexDirection: "column" }}>
        <label>
          Email
          <input
            type="text"
            value={email}
            onChange={(e) => setEmail(e.currentTarget.value)}
          />
          <p style={{ height: 20, marginInline: 8 }}>{validateEmail()}</p>
        </label>
        <ButtonUnstyled
          style={{
            cursor: validateEmail() === "" ? "pointer" : undefined,
            borderRadius: 4,
            paddingBlock: 6,
            paddingInline: 24,
            width: 160,
            height: 48,
            fontWeight: 600,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            alignSelf: "flex-end",
            background: Colors.border,
            color: validateEmail() === "" ? Colors.blue : "lightgray",
            border:
              validateEmail() === ""
                ? `1px solid ${Colors.blue}`
                : `1px solid lightgray`,
            boxShadow:
              validateEmail() === "" ? "2px 2px 4px rgba(0,0,0,0.2)" : "none",
          }}
          disabled={!!validateEmail()}
          onClick={() => {
            setLoading(true);
            {
              globalState.api.providerOnboardingInit
                .call(
                  globalState.callOptions(),
                  {},
                  {
                    email: email,
                  }
                )
                .then((response) => {
                  if (response.status === 200) {
                    alert("Email sent");
                    navigate("/admin/providers");
                  } else {
                    alert(`Error: ${response.status} - ${response.error}`);
                    setLoading(false);
                  }
                });
            }
          }}
        >
          {loading === false ? (
            "Add Provider"
          ) : (
            <Oval
              color={Colors.blue}
              secondaryColor={Colors.blue}
              width={20}
              strokeWidth={8}
            />
          )}
        </ButtonUnstyled>
      </div>
    </div>
  );
}
