import { ButtonUnstyled } from "@mui/base";
import { Popover } from "@mui/material";
import { useEffect, useState } from "react";
import "../styles/ListStyle.css";
import {
  FaArrowDown,
  FaArrowUp,
  FaChevronLeft,
  FaChevronRight,
  FaPlus,
  FaSort,
} from "react-icons/fa";
import { Colors } from "../Colors";
import PagerrLoader from "./PagerrLoader";
import { mobileTrigger } from "./MobileTrigger";

export interface LoadResponse<T> {
  data: T[];
  total: number;
  error?: string;
}

export interface SmartTableColumn<T> {
  columnKey: string;
  columnTitle: string;
  showColumn?: (windowWidth: number) => boolean;
  cell: (rowData: T, windowWidth: number) => JSX.Element;
  sort: boolean;
}

function pageCount(total: number, perPage: number) {
  return Math.ceil(total / perPage);
}

function pageRange(page: number, perPage: number, total: number) {
  const start = (page - 1) * perPage;
  const end = Math.min(start + perPage, total);

  if (total === 0) {
    return [0, 0];
  } else {
    return [start + 1, end];
  }
}

export function SmartTable<T>(props: {
  columns: SmartTableColumn<T>[];
  load: (
    page: number,
    pageSize: number,
    sortColumn: string | undefined,
    sortDirection: 1 | -1
  ) => Promise<LoadResponse<T>>;
  rowKey: (row: T) => any;
  headerContent: (windowWidht: number) => JSX.Element;
  emptyContent: (windowWidth: number) => JSX.Element;
  addCallback?: () => void;
  rowClickCallback?: (rowData: T) => void;
  defaultSortedColumn?: string;
  defaultSortedDirection?: 1 | -1;
  pageSizeOptions?: number[];
  defaultPageSize?: number;
}) {
  const [data, setData] = useState<undefined | T[]>(undefined);
  const [recordCount, setRecordCount] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(100);

  const [sortedColumn, setSortedColumn] = useState<string | undefined>(
    props.defaultSortedColumn ?? props.columns[0].columnKey ?? undefined
  );
  const [sortDirection, setSortDirection] = useState<1 | -1>(
    props.defaultSortedDirection ?? 1
  );

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener("resize", updateWidthAndHeight);
    return () => window.removeEventListener("resize", updateWidthAndHeight);
  }, []);

  const updateWidthAndHeight = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    props.load(currentPage, pageSize, sortedColumn, sortDirection).then((d) => {
      if ("error" in d) {
        alert(d.error);
      } else {
        setData(d.data);
        setRecordCount(d.total);
      }
    });
  }, [pageSize, currentPage, sortedColumn, sortDirection]);

  const windowTrigger = mobileTrigger;

  return (
    <>
      {props.addCallback !== undefined && windowWidth < windowTrigger ? (
        <ButtonUnstyled
          className="addButton"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "fixed",
            bottom: "5%",
            right: "5%",
            height: 64,
            width: 64,
          }}
          onClick={() => {
            props.addCallback!();
          }}
        >
          <FaPlus style={{ flexShrink: 0 }} size={24} />
        </ButtonUnstyled>
      ) : null}
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          flexDirection: "column",
          flex: 1,
          paddingBottom: 420,
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "flex-end",
            flexDirection: "row",
            justifyContent: "space-between",
            marginBottom: 24,
            flexWrap: "wrap",
            gap: 12,
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "flex-start",
              flexDirection: "column",
            }}
          >
            {props.headerContent(windowWidth)}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: 12,
              alignItems: "center",
              color: Colors.lightGray,
            }}
          >
            <p style={{ fontWeight: 600, fontSize: 14 }}>
              {pageRange(currentPage, pageSize, recordCount).join(" - ")} of{" "}
              {recordCount}
            </p>
            <ButtonUnstyled
              className="pageButton"
              disabled={currentPage <= 1}
              onClick={() => {
                if (currentPage > 1) {
                  const newPage = currentPage - 1;

                  setCurrentPage(newPage);

                  props
                    .load(newPage, pageSize, sortedColumn, sortDirection)
                    .then((d) => {
                      if ("error" in d) {
                        alert(d.error);
                      } else {
                        setData(d.data);
                        setRecordCount(d.total);
                      }
                    });
                }
              }}
            >
              <FaChevronLeft />
            </ButtonUnstyled>
            <ButtonUnstyled
              className="pageButton"
              disabled={currentPage >= pageCount(recordCount, pageSize)}
              onClick={() => {
                if (currentPage < pageCount(recordCount, pageSize)) {
                  const newPage = currentPage + 1;

                  setCurrentPage(newPage);

                  props
                    .load(newPage, pageSize, sortedColumn, sortDirection)
                    .then((d) => {
                      if ("error" in d) {
                        alert(d.error);
                      } else {
                        setData(d.data);
                        setRecordCount(d.total);
                      }
                    });
                }
              }}
            >
              <FaChevronRight />
            </ButtonUnstyled>
            {props.addCallback !== undefined && windowWidth > windowTrigger ? (
              <ButtonUnstyled
                className="addButton"
                onClick={() => {
                  props.addCallback!();
                }}
              >
                <FaPlus size={20} />
              </ButtonUnstyled>
            ) : null}
          </div>
        </div>

        {data === undefined ? (
          <PagerrLoader />
        ) : (
          <>
            {data.length === 0 ? (
              props.emptyContent(windowWidth)
            ) : (
              <>
                <table>
                  <thead>
                    {/* <tr
                      style={{
                        display: "table-row",
                        textAlign: "left",
                        cursor: "default",
                      }}
                    > */}
                    <tr
                      style={{
                        display: "table-row",
                        textAlign: "left",
                        cursor: "default",
                      }}
                    >
                      {props.columns.map((column) => {
                        return column.showColumn &&
                          !column.showColumn(windowWidth) ? null : (
                          <th
                            key={column.columnKey}
                            style={
                              {
                                // borderTopLeftRadius: 8,
                                // borderBottomLeftRadius: 8,
                              }
                            }
                          >
                            {column.sort ? (
                              <FilterButton
                                label={column.columnTitle}
                                onSortAsc={() => {
                                  setSortedColumn(column.columnKey);
                                  setSortDirection(1);
                                }}
                                onSortDesc={() => {
                                  setSortedColumn(column.columnKey);
                                  setSortDirection(-1);
                                }}
                                active={column.columnKey === sortedColumn}
                                ascending={sortDirection === 1}
                              />
                            ) : (
                              <div
                                style={{
                                  padding: 8,
                                  color: "#8d98af",
                                  fontWeight: 600,
                                  borderRadius: "inherit",
                                }}
                              >
                                {column.columnTitle}
                              </div>
                            )}
                          </th>
                        );
                      })}
                    </tr>
                  </thead>
                  <tbody>
                    {data.map(function (item) {
                      return (
                        <tr
                          key={props.rowKey(item)}
                          // style={{
                          //   borderBottom: "1px solid #D0D5DD",
                          //   textAlign: "left",
                          // }}
                          onClick={
                            props.rowClickCallback
                              ? () => {
                                  props.rowClickCallback!(item);
                                }
                              : undefined
                          }
                        >
                          {props.columns.map((column) => {
                            return column.showColumn &&
                              !column.showColumn(windowWidth)
                              ? null
                              : column.cell(item, windowWidth);
                          })}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </>
            )}
          </>
        )}
      </div>
    </>
  );
}

const filterButtonStyle = {
  margin: 0,
  borderRadius: 0,
  paddingBlock: 8,
  paddingInline: 0,
  fontSize: 14,
  color: "#8D98AF",
  fontWeight: "600",
  cursor: "pointer",
  flexDirection: "row",
  display: "flex",
  alignItems: "center",
  border: "0px",
  textAlign: "left",
  flex: 1,
  background: "transparent",
};

function FilterButton(props: {
  label: string;
  active: boolean;
  ascending: boolean;
  onSortAsc: () => void;
  onSortDesc: () => void;
}) {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <>
      <ButtonUnstyled
        className="filter"
        style={
          props.active
            ? {
                color: Colors.blue,
              }
            : null
        }
        onClick={handleClick}
      >
        {props.label}
        {props.active === true ? (
          props.ascending === true ? (
            <FaArrowUp size={14} style={{ display: "flex", flexShrink: 0 }} />
          ) : (
            <FaArrowDown size={14} style={{ display: "flex", flexShrink: 0 }} />
          )
        ) : (
          <FaSort
            size={12}
            style={{ opacity: 0.5, display: "flex", flexShrink: 0 }}
          />
        )}
      </ButtonUnstyled>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <ButtonUnstyled
            className={"sortSelector"}
            onClick={() => {
              props.onSortAsc();
              handleClose();
            }}
          >
            Sort Ascending <FaArrowUp size={14} />
          </ButtonUnstyled>
          <ButtonUnstyled
            className={"sortSelector"}
            onClick={() => {
              props.onSortDesc();
              handleClose();
            }}
          >
            Sort Descending <FaArrowDown size={14} />
          </ButtonUnstyled>
        </div>
      </Popover>
    </>
  );
}
