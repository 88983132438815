import { ButtonUnstyled } from "@mui/base";
import { useState } from "react";
import { MdOutlineCancel } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { Colors } from "../Colors";
import type { Address, EndPointType } from "@xaratan/pagerr-common";
import { FaHospital, FaPlusCircle } from "react-icons/fa";
import { useGetState } from "../helpers/useGetState";
import { useGlobals } from "../contexts/useGlobals";
import { useSignup } from "../contexts/useSignup";
import { FacilityDefinition } from "../contexts/SignupContext";

type X = EndPointType<{
  required: {
    existing: "boolean";
  };
  optional: {
    id: "string";
    name: "string";
    phone: "string";
    address: {
      required: {
        address1: "string";
        address2: "string";
        city: "string";
        state: "string";
        zip: "string";
      };
    };
  };
}>;

function getFacilityInfo(
  facilityEntry: X,
  mapping: Map<
    string,
    EndPointType<{
      required: {
        id: "string";
        name: "string";
        address: {
          required: {
            address1: "string";
            address2: "string";
            city: "string";
            state: "string";
            zip: "string";
          };
        };
      };
    }>
  >
): {
  name: string;
  address: Address;
} {
  if (!facilityEntry) {
    return {
      name: "",
      address: {
        address1: "",
        address2: "",
        city: "",
        state: "",
        zip: "",
      },
    };
  }

  if (facilityEntry.existing && mapping.has(facilityEntry.id!)) {
    return mapping.get(facilityEntry.id!)!;
  } else {
    return {
      name: facilityEntry.name!,
      address: {
        address1: facilityEntry.address?.address1! ?? "",
        address2: facilityEntry.address?.address2! ?? "",
        city: facilityEntry.address?.city! ?? "",
        state: facilityEntry.address?.state! ?? "",
        zip: facilityEntry.address?.zip! ?? "",
      },
    };
  }
}

export default function ProviderSignupAddFacilities() {
  const globals = useGlobals();
  const signup = useSignup();

  const [facilityList] = useGetState(
    globals.api.getFacilities,
    globals.callOptions(),
    {}
  );

  const facilityMapping = new Map<
    string,
    EndPointType<{
      required: {
        id: "string";
        name: "string";
        address: {
          required: {
            address1: "string";
            address2: "string";
            city: "string";
            state: "string";
            zip: "string";
          };
        };
      };
    }>
  >();

  if (facilityList) {
    facilityList.facilities.forEach((f) => {
      facilityMapping.set(f.id, f);
    });
  }

  const [newFacility, setNewFacility] = useState<
    Partial<FacilityDefinition> | undefined
  >(undefined);
  const [credentialed, setCredentialed] = useState<boolean | undefined>(
    undefined
  );
  const [addFacility, setAddFacility] = useState<boolean>(false);

  const EmploymentTypes = [
    {
      label: "Not credentialed at this facility",
      value: { credentialed: false, w2: false },
    },
    {
      label: "Credentialed at this facility (W2/employee)",
      value: { credentialed: true, w2: true },
    },
    {
      label: "Credentialed at this facility (1099/contractor)",
      value: { credentialed: true, w2: false },
    },
  ];

  const [employment, setEmployment] = useState<
    | undefined
    | {
        credentialed: boolean;
        w2: boolean;
      }
  >(undefined);

  const states = [
    {
      name: "Alabama",
      abbreviation: "AL",
    },
    {
      name: "Alaska",
      abbreviation: "AK",
    },
    {
      name: "American Samoa",
      abbreviation: "AS",
    },
    {
      name: "Arizona",
      abbreviation: "AZ",
    },
    {
      name: "Arkansas",
      abbreviation: "AR",
    },
    {
      name: "California",
      abbreviation: "CA",
    },
    {
      name: "Colorado",
      abbreviation: "CO",
    },
    {
      name: "Connecticut",
      abbreviation: "CT",
    },
    {
      name: "Delaware",
      abbreviation: "DE",
    },
    {
      name: "District Of Columbia",
      abbreviation: "DC",
    },
    {
      name: "Federated States Of Micronesia",
      abbreviation: "FM",
    },
    {
      name: "Florida",
      abbreviation: "FL",
    },
    {
      name: "Georgia",
      abbreviation: "GA",
    },
    {
      name: "Guam",
      abbreviation: "GU",
    },
    {
      name: "Hawaii",
      abbreviation: "HI",
    },
    {
      name: "Idaho",
      abbreviation: "ID",
    },
    {
      name: "Illinois",
      abbreviation: "IL",
    },
    {
      name: "Indiana",
      abbreviation: "IN",
    },
    {
      name: "Iowa",
      abbreviation: "IA",
    },
    {
      name: "Kansas",
      abbreviation: "KS",
    },
    {
      name: "Kentucky",
      abbreviation: "KY",
    },
    {
      name: "Louisiana",
      abbreviation: "LA",
    },
    {
      name: "Maine",
      abbreviation: "ME",
    },
    {
      name: "Marshall Islands",
      abbreviation: "MH",
    },
    {
      name: "Maryland",
      abbreviation: "MD",
    },
    {
      name: "Massachusetts",
      abbreviation: "MA",
    },
    {
      name: "Michigan",
      abbreviation: "MI",
    },
    {
      name: "Minnesota",
      abbreviation: "MN",
    },
    {
      name: "Mississippi",
      abbreviation: "MS",
    },
    {
      name: "Missouri",
      abbreviation: "MO",
    },
    {
      name: "Montana",
      abbreviation: "MT",
    },
    {
      name: "Nebraska",
      abbreviation: "NE",
    },
    {
      name: "Nevada",
      abbreviation: "NV",
    },
    {
      name: "New Hampshire",
      abbreviation: "NH",
    },
    {
      name: "New Jersey",
      abbreviation: "NJ",
    },
    {
      name: "New Mexico",
      abbreviation: "NM",
    },
    {
      name: "New York",
      abbreviation: "NY",
    },
    {
      name: "North Carolina",
      abbreviation: "NC",
    },
    {
      name: "North Dakota",
      abbreviation: "ND",
    },
    {
      name: "Northern Mariana Islands",
      abbreviation: "MP",
    },
    {
      name: "Ohio",
      abbreviation: "OH",
    },
    {
      name: "Oklahoma",
      abbreviation: "OK",
    },
    {
      name: "Oregon",
      abbreviation: "OR",
    },
    {
      name: "Palau",
      abbreviation: "PW",
    },
    {
      name: "Pennsylvania",
      abbreviation: "PA",
    },
    {
      name: "Puerto Rico",
      abbreviation: "PR",
    },
    {
      name: "Rhode Island",
      abbreviation: "RI",
    },
    {
      name: "South Carolina",
      abbreviation: "SC",
    },
    {
      name: "South Dakota",
      abbreviation: "SD",
    },
    {
      name: "Tennessee",
      abbreviation: "TN",
    },
    {
      name: "Texas",
      abbreviation: "TX",
    },
    {
      name: "Utah",
      abbreviation: "UT",
    },
    {
      name: "Vermont",
      abbreviation: "VT",
    },
    {
      name: "Virgin Islands",
      abbreviation: "VI",
    },
    {
      name: "Virginia",
      abbreviation: "VA",
    },
    {
      name: "Washington",
      abbreviation: "WA",
    },
    {
      name: "West Virginia",
      abbreviation: "WV",
    },
    {
      name: "Wisconsin",
      abbreviation: "WI",
    },
    {
      name: "Wyoming",
      abbreviation: "WY",
    },
  ];

  const [name, setName] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [address1, setAddress1] = useState<string>("");
  const [address2, setAddress2] = useState<string>("");
  const [city, setCity] = useState<string>("");
  const [state, setState] = useState<string>("");
  const [zip, setZip] = useState<string>("");

  function validateName(): string {
    if (name.length === 0) {
      return "Name is required.";
    }
    return "";
  }

  function validatePhone(): string {
    if (phone.length === 10) {
      return "";
    }
    return "Please enter a valid phone number (including area code) with no dashes or spaces.";
  }

  function validateAddress1(): string {
    if (address1.length === 0) {
      return "Address is required.";
    }
    return "";
  }

  function validateCity(): string {
    if (city.length === 0) {
      return "City is required.";
    }
    return "";
  }

  function validateState(): string {
    if (state.length === 0) {
      return "State is required.";
    }
    return "";
  }

  function validateZip(): string {
    if (zip.length === 5) {
      return "";
    }
    return "A valid zip code is required.";
  }

  function validateNewFacility(): string {
    if (newFacility === undefined) {
      return "Facility choice is required.";
    }
    return "";
  }

  function validateCredentials(): string {
    if (employment === undefined) {
      return "Credentialed status is required.";
    }
    return "";
  }

  function validateNotOther() {
    return !(!!validateNewFacility() || !!validateCredentials());
  }

  function validateOther() {
    return !(
      !!validateCredentials() ||
      !!validateName() ||
      !!validateCity() ||
      !!validateAddress1() ||
      !!validateZip() ||
      !!validatePhone() ||
      !!validateState()
    );
  }

  const navigate = useNavigate();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        maxWidth: 600,
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: 20,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          {/* <MdArrowBack
            size={24}
            style={{ cursor: "pointer", display: "flex", flexShrink: 0 }}
            onClick={() => navigate("../2")}
          /> */}
          <h3 style={{ margin: 0 }}>Add Facilities</h3>
        </div>
        <p
          style={{
            textAlign: "right",
            fontSize: 12,
            fontWeight: 700,
            color: "#fff",
            paddingBlock: 4,
            paddingInline: 8,
            borderRadius: 8,
            background: Colors.blue,
            alignSelf: "flex-end",
          }}
        >
          Step 4/4
        </p>
      </div>
      <p style={{ marginBottom: 24 }}>
        Add facilities where you are credentialed. Please note that we will need
        to verify your credentialed status.
      </p>
      {(signup.facilities.value ?? []).map(function (item, index) {
        return (
          <div
            key={index}
            style={{
              borderRadius: 4,
              paddingBlock: 12,
              paddingInline: 6,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              border: "1px solid lightgray",
              marginBottom: 8,
            }}
          >
            <div
              key={index}
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                // justifyContent: "space-between",
                // border: "1px solid lightgray",
                // marginBottom: 8,
                gap: 12,
              }}
            >
              <FaHospital
                size={24}
                style={{
                  padding: 10,
                  background: Colors.blueBrightPrimary,
                  color: Colors.blue,
                  borderRadius: 40,
                }}
              />
              <div>
                <p style={{ fontWeight: 600, fontSize: 16 }}>
                  {getFacilityInfo(item, facilityMapping).name} (
                  {item.credentialed ? "Credentialed" : "Not credentialed"})
                </p>
                <p style={{ fontWeight: 400, fontSize: 12 }}>
                  {getFacilityInfo(item, facilityMapping).address!.address1}
                  ,&nbsp;{getFacilityInfo(item, facilityMapping).address!.city}
                  ,&nbsp;
                  {getFacilityInfo(item, facilityMapping).address!.state}
                </p>
              </div>
            </div>

            <p
              style={{
                padding: 4,
                background: Colors.red,
                fontWeight: 500,
                color: "#fff",
                fontSize: 16,
                cursor: "pointer",
                borderRadius: 4,
              }}
              onClick={() => {
                signup.facilities.setValue((v) =>
                  !v ? [] : [...v.slice(0, index), ...v.slice(index + 1)]
                );
                //
                // setFacilities(
                //   facilities.filter(function (i) {
                //     return i._id !== item._id;
                //   })
                // )
              }}
            >
              Remove
            </p>
          </div>
        );
      })}
      {addFacility === false ? (
        <ButtonUnstyled
          style={{
            cursor: "pointer",
            borderRadius: 4,
            paddingBlock: 12,
            paddingInline: 6,
            fontWeight: 600,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            background: Colors.border,
            color: Colors.blue,
            border: `1px solid ${Colors.blue}`,
            boxShadow: "2px 2px 4px rgba(0,0,0,0.2)",
            fontSize: 16,
          }}
          onClick={() => setAddFacility(true)}
        >
          Add Facility <FaPlusCircle />
        </ButtonUnstyled>
      ) : (
        <div
          style={{
            maxWidth: 620,
            border: `1px solid ${Colors.blue}`,
            boxShadow: "2px 2px 4px rgba(0,0,0,0.2)",
            borderRadius: 4,
            padding: 12,
            display: "flex",
            flexDirection: "column",
            gap: 12,
          }}
        >
          <p style={{ fontWeight: 600, fontSize: 16, color: Colors.blue }}>
            Add Facility
          </p>
          <label>
            Facility
            <select
              style={{
                padding: 7,
                borderRadius: 8,
                fontSize: 16,
              }}
              defaultValue={""}
              // value={newFacility?.name}
              onChange={(e) =>
                e.currentTarget.value === "other"
                  ? setNewFacility({ name: "other" })
                  : setNewFacility(JSON.parse(e.currentTarget.value))
              }
            >
              <option disabled value="" label="Please select a facility...">
                Please select a facility...
              </option>
              {(facilityList?.facilities ?? []).map(function (item, index) {
                return (
                  <option
                    key={index}
                    style={{
                      height: 20,
                      marginInline: 8,
                      color: Colors.blue,
                    }}
                    value={JSON.stringify(item)}
                    label={`${item.name} (${item.address.city}, ${item.address.state})`}
                  >
                    {item.name} ({item.address.city}, {item.address.state})
                  </option>
                );
              })}
              {/* <option value="other" label="Other...">
                Other...
              </option> */}
            </select>
            <p
              style={{
                height: 20,
                marginInline: 8,
                color: Colors.blue,
              }}
            >
              {validateNewFacility()}
            </p>
          </label>
          {getFacilityInfo(newFacility as any, facilityMapping).name ===
          "other" ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "inherit",
              }}
            >
              <label>
                Facility Name
                <input
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.currentTarget.value)}
                />
                <p
                  style={{
                    height: 20,
                    marginInline: 8,
                    color: Colors.blue,
                  }}
                >
                  {validateName()}
                </p>
              </label>
              <label>
                Phone Number of Credentialing Department
                <input
                  type="text"
                  value={phone}
                  onChange={(e) => setPhone(e.currentTarget.value)}
                  maxLength={10}
                />
                <p
                  style={{
                    height: 20,
                    marginInline: 8,
                    color: Colors.blue,
                  }}
                >
                  {validatePhone()}
                </p>
              </label>
              <label>
                Street Address 1
                <input
                  type="text"
                  value={address1}
                  onChange={(e) => setAddress1(e.currentTarget.value)}
                />
                <p
                  style={{
                    height: 20,
                    marginInline: 8,
                    color: Colors.blue,
                  }}
                >
                  {validateAddress1()}
                </p>
              </label>
              <label>
                Street Address 2
                <input
                  type="text"
                  value={address2}
                  onChange={(e) => setAddress2(e.currentTarget.value)}
                />
                <p
                  style={{
                    height: 20,
                    marginInline: 8,
                    color: Colors.blue,
                  }}
                ></p>
              </label>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  gap: "inherit",
                }}
              >
                <label
                  style={{ display: "flex", flexDirection: "column", flex: 1 }}
                >
                  City
                  <input
                    type="text"
                    value={city}
                    onChange={(e) => setCity(e.currentTarget.value)}
                  />
                  <p
                    style={{
                      height: 20,
                      marginInline: 8,
                      color: Colors.blue,
                    }}
                  >
                    {validateCity()}
                  </p>
                </label>
                <label
                  style={{ display: "flex", flexDirection: "column", flex: 1 }}
                >
                  State
                  <select
                    style={{
                      padding: 7,
                      borderRadius: 8,
                      fontSize: 16,
                      height: 37,
                    }}
                    value={state}
                    onChange={(e) => setState(e.currentTarget.value)}
                  >
                    <option disabled value="" label="">
                      Select a state...
                    </option>
                    {states.map(function (item, index) {
                      return (
                        <option
                          key={index}
                          style={{
                            height: 20,
                            marginInline: 8,
                            color: Colors.blue,
                          }}
                          value={item.abbreviation}
                          label={`${item.abbreviation} - ${item.name}`}
                        >
                          {item.abbreviation} - {item.name}
                        </option>
                      );
                    })}
                  </select>
                  <p
                    style={{
                      height: 20,
                      marginInline: 8,
                      color: Colors.blue,
                    }}
                  >
                    {validateState()}
                  </p>
                </label>
                <label style={{ flex: 1 }}>
                  Zip Code
                  <input
                    type="text"
                    value={zip}
                    maxLength={5}
                    onChange={(e) => setZip(e.currentTarget.value)}
                  />
                  <p
                    style={{
                      height: 20,
                      marginInline: 8,
                      color: Colors.blue,
                    }}
                  >
                    {validateZip()}
                  </p>
                </label>
              </div>
            </div>
          ) : null}
          <label>
            Status
            <fieldset
              name="employmentTypes"
              style={{
                padding: 0,
                border: "none",
                display: "flex",
                flexWrap: "wrap",
                gap: 12,
                flexDirection: "column",
              }}
            >
              {EmploymentTypes.map(function (item, index) {
                return (
                  <label
                    key={index}
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      fontSize: 16,
                      fontWeight: 400,
                    }}
                  >
                    <input
                      type={"radio"}
                      name="employmentTypes"
                      value={item.label}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setEmployment(item.value);
                        }
                      }}
                    />
                    {item.label}
                  </label>
                );
              })}
            </fieldset>
            <p
              style={{
                height: 20,
                marginInline: 8,
                color: Colors.blue,
              }}
            >
              {validateCredentials()}
            </p>
          </label>
          {/* <label>
            Credentialed Status
            <select
              style={{
                padding: 7,
                borderRadius: 8,
                fontSize: 16,
              }}
              value={
                credentialed === undefined
                  ? ""
                  : credentialed
                  ? "true"
                  : "false"
              }
              onChange={(e) => setCredentialed(Boolean(e.currentTarget.value))}
            >
              <option disabled value="" label="Please select an option...">
                Please select an option...
              </option>
              <option
                value="true"
                label="I am credentialed to work at this facility."
              >
                I am credentialed to work at this facility.
              </option>
              <option
                value="false"
                label="I am not credentialed to work at this facility but would like to be."
              >
                I am not credentialed to work at this facility but would like to
                be.
              </option>
            </select>
            <p
              style={{
                height: 20,
                marginInline: 8,
                color: Colors.blue,
              }}
            >
              {validateCredentials()}
            </p>
          </label> */}

          <div
            style={{
              display: "flex",
              marginTop: 10,
              gap: 12,
              justifyContent: "flex-end",
              flexDirection: "row",
            }}
          >
            <ButtonUnstyled
              style={{
                cursor: "pointer",
                borderRadius: 4,
                paddingBlock: 6,
                paddingInline: 32,
                fontWeight: 600,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                background: "none",
                color: "gray",
                border: "none",
                boxShadow: "none",
                fontSize: 16,
              }}
              onClick={() => {
                setAddFacility(false);
                setCity("");
                setCredentialed(undefined);
                setNewFacility(undefined);
              }}
            >
              Cancel
            </ButtonUnstyled>
            {getFacilityInfo(newFacility as any, facilityMapping).name ===
            "other" ? (
              <ButtonUnstyled
                style={{
                  cursor: validateOther() === true ? "pointer" : undefined,
                  borderRadius: 4,
                  paddingBlock: 6,
                  paddingInline: 32,
                  fontWeight: 600,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  background: Colors.border,
                  fontSize: 16,
                  color: validateOther() === true ? Colors.blue : "lightgray",
                  border:
                    validateOther() === true
                      ? `1px solid ${Colors.blue}`
                      : `1px solid lightgray`,
                  boxShadow:
                    validateOther() === true
                      ? "2px 2px 4px rgba(0,0,0,0.2)"
                      : "none",
                }}
                onClick={() => {
                  signup.facilities.setValue((v) =>
                    !v
                      ? [
                          {
                            existing: false,
                            name: name,
                            address: {
                              address1: address1,
                              address2: address2,
                              city: city,
                              state: state,
                              zip: zip,
                            },
                            phone: phone,
                            credentialed: employment!.credentialed,
                            employmentType:
                              employment!.w2 === true ? "w2" : "1099",
                          },
                        ]
                      : [
                          ...v,
                          {
                            existing: false,
                            name: name,
                            address: {
                              address1: address1,
                              address2: address2,
                              city: city,
                              state: state,
                              zip: zip,
                            },
                            phone: phone,
                            credentialed: employment!.credentialed,
                            employmentType:
                              employment!.w2 === true ? "w2" : "1099",
                          },
                        ]
                  );
                  setNewFacility(undefined);
                  setAddFacility(false);
                  setName("");
                  setPhone("");
                  setAddress1("");
                  setAddress2("");
                  setCity("");
                  setState("");
                  setZip("");
                  setCredentialed(undefined);
                }}
              >
                Add Facility
              </ButtonUnstyled>
            ) : (
              <ButtonUnstyled
                disabled={!validateNotOther()}
                style={{
                  cursor: validateNotOther() === true ? "pointer" : undefined,
                  borderRadius: 4,
                  paddingBlock: 6,
                  paddingInline: 32,
                  fontWeight: 600,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  background: Colors.border,
                  fontSize: 16,
                  color:
                    validateNotOther() === true ? Colors.blue : "lightgray",
                  border:
                    validateNotOther() === true
                      ? `1px solid ${Colors.blue}`
                      : `1px solid lightgray`,
                  boxShadow:
                    validateNotOther() === true
                      ? "2px 2px 4px rgba(0,0,0,0.2)"
                      : "none",
                }}
                onClick={() => {
                  signup.facilities.setValue((v) => {
                    return !v
                      ? [{ ...newFacility, existing: true }!]
                      : [...v, { ...newFacility, existing: true } as any];
                  });
                  //setFacilities([...facilities, newFacility!]);
                  setNewFacility(undefined);
                  setAddFacility(false);
                }}
              >
                Add Facility
              </ButtonUnstyled>
            )}
          </div>
        </div>
      )}

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          flex: 1,
        }}
      >
        {addFacility === true ? null : (
          <ButtonUnstyled
            onClick={() => {
              globals.api.updateCredentialings
                .call(
                  globals.callOptions(),
                  {},
                  {
                    facilities: signup.facilities.value ?? [],
                  }
                )
                .then((result) => {
                  if (result.status === 200) {
                    //
                    globals.api.providerOnboardingFinish
                      .call(globals.callOptions(), {}, {})
                      .then((result) => {
                        if (result.status === 200) {
                          signup.facilities.setValue(undefined);
                          // navigate("/providersignup/4");
                          navigate("/provider/listings");
                        } else {
                          alert("Error submitting the onboarding form");
                        }
                      });
                  } else {
                    alert("Error updating credentialings");
                  }
                });
            }}
            style={{
              marginTop: 12,
              borderRadius: 4,
              padding: 12,
              alignSelf: "flex-start",
              fontWeight: 700,
              border: `1px solid ${Colors.blue}`,
              background: Colors.border,
              color: Colors.blue,
              cursor: "pointer",
              fontSize: 16,
            }}
          >
            CONTINUE
          </ButtonUnstyled>
        )}
      </div>
    </div>
  );
}
