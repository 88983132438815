import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import Login from "./components/Login";
import ProviderJobListings from "./components/ProviderJobListings";
import ProviderAddFacility from "./components/ProviderAddFacility";
import { getGlobalStateProvider } from "./contexts/GlobalStateContext";
import ProviderAlertSettings from "./components/ProviderAlertSettings";
import Profile from "./components/ProviderCredentialedFacilities";
import ProviderJobs from "./components/ProviderJobs";
import FacilityJobListings from "./components/FacilityJobListings";
import FacilityAddListing from "./components/FacilityAddListing";
import FacilityChrome from "./components/FacilityChrome";
import ProviderChrome from "./components/ProviderChrome";
import FacilityPastJobs from "./components/FacilityPastJobs";
import ScrollToTop from "./helpers/ScrollToTop";
import ProviderSignupAccountDetails from "./components/ProviderSignupAccountDetails";
import SignupChrome from "./components/SignupChrome";
import ProviderSignupAgreement from "./components/ProviderSignupAgreement";
import ProviderSignupAddFacilities from "./components/ProviderSignupAddFacilities";
import FacilityLocations from "./components/FacilityLocations";
import EmailVerification from "./components/EmailVerification";
import ProviderProfile from "./components/ProviderProfile";
import AdminChrome from "./components/AdminChrome";
import AdminProviderList from "./components/AdminProviderList";
import AdminAddProvider from "./components/AdminAddProvider";
import AdminAddManager from "./components/AdminAddManager";
import AdminFacilityList from "./components/AdminFacilityList";
import AdminAddFacility from "./components/AdminAddFacility";
import AdminManagerList from "./components/AdminManagerList";
import Logo from "./components/Logo";
import FacilityEditFacility from "./components/FacilityEditFacility";
import FacilityAddFacility from "./components/FacilityAddFacility";
import ProviderSignupProviderInfo from "./components/ProviderSignupProviderInfo";
import AuthorizationRequired from "./components/AuthorizationRequired";
import FacilityProfile from "./components/FacilityProfile";
import ForgotPassword from "./components/ForgotPassword";
import ResetPassword from "./components/ResetPassword";
import { getSignupStateProvider } from "./contexts/SignupContext";
import AdminRequired from "./components/AdminRequired";
import FacilitySignupAccountInfo from "./components/FacilitySignupAccountInfo";
import ProviderSignupAddress from "./components/ProviderSignupAddress";
import ProviderPastJobs from "./components/ProviderPastJobs";

const GlobalStateProvider = getGlobalStateProvider();
const SignupStateProvider = getSignupStateProvider();

ReactDOM.render(
  <React.StrictMode>
    <GlobalStateProvider>
      <BrowserRouter>
        <ScrollToTop>
          <Routes>
            <Route path="/" element={<App />}>
              <Route path="/logo" element={<Logo />} />
              {/* <Route path="signup" element={<Signup />} /> */}
              <Route
                path="admin"
                element={
                  <AuthorizationRequired>
                    <AdminRequired>
                      <AdminChrome />
                    </AdminRequired>
                  </AuthorizationRequired>
                }
              >
                <Route index element={<AdminProviderList />} />
                <Route path="providers" element={<AdminProviderList />} />
                <Route path="addprovider" element={<AdminAddProvider />} />
                <Route path="facilities" element={<AdminFacilityList />} />
                <Route path="addfacility" element={<AdminAddFacility />} />
                <Route path="managers" element={<AdminManagerList />} />
                <Route path="addmanager" element={<AdminAddManager />} />
              </Route>
              <Route path="login" element={<Login />} />
              <Route path="forgotpassword" element={<ForgotPassword />} />
              <Route path="resetpassword/:token" element={<ResetPassword />} />
              <Route path="verifyemail" element={<EmailVerification />} />
              <Route
                path="providersignup/:signupToken"
                element={
                  <SignupStateProvider>
                    <SignupChrome />
                  </SignupStateProvider>
                }
              >
                <Route index element={<ProviderSignupAccountDetails />} />
              </Route>
              <Route
                path="providersignup"
                element={
                  <AuthorizationRequired>
                    <SignupStateProvider>
                      <SignupChrome />
                    </SignupStateProvider>
                  </AuthorizationRequired>
                }
              >
                <Route path="2" element={<ProviderSignupProviderInfo />} />
                <Route path="3" element={<ProviderSignupAddress />} />
                <Route path="4" element={<ProviderSignupAddFacilities />} />
                {/* <Route path="5" element={<ProviderSignupAgreement />} /> */}
              </Route>
              <Route
                path="facilitysignup/:signupToken"
                element={<SignupChrome />}
              >
                <Route index element={<FacilitySignupAccountInfo />} />
              </Route>
              <Route
                path="provider"
                element={
                  <AuthorizationRequired>
                    <ProviderChrome />
                  </AuthorizationRequired>
                }
              >
                <Route path="listings" element={<ProviderJobListings />} />
                <Route path="jobs" element={<ProviderJobs />} />
                <Route path="jobhistory" element={<ProviderPastJobs />} />
                <Route path="settings" element={<ProviderAlertSettings />} />
                <Route path="facilities" element={<Profile />} />
                <Route path="profile" element={<ProviderProfile />} />
                <Route path="addhospital" element={<ProviderAddFacility />} />
                <Route index element={<ProviderJobListings />} />
              </Route>
              <Route
                path="facility"
                element={
                  <AuthorizationRequired>
                    <FacilityChrome />
                  </AuthorizationRequired>
                }
              >
                <Route path="listings" element={<Outlet />}>
                  <Route index element={<FacilityJobListings />} />
                  <Route path="addlisting" element={<FacilityAddListing />} />
                </Route>
                {/* <Route path="credentials" element={<ProviderList />} /> */}
                <Route path="addlisting" element={<FacilityAddListing />} />
                <Route path="jobs" element={<FacilityPastJobs />} />
                <Route path="profile" element={<FacilityProfile />} />
                <Route path="locations" element={<Outlet />}>
                  <Route index element={<FacilityLocations />} />
                  <Route
                    path="edit/:facilityId"
                    element={<FacilityEditFacility />}
                  />
                  <Route path="add" element={<FacilityAddFacility />} />
                </Route>
                <Route index element={<FacilityJobListings />} />
              </Route>
              <Route index element={<Login />} />
            </Route>
            <Route path="*" element={<App />} />
          </Routes>
        </ScrollToTop>
      </BrowserRouter>
    </GlobalStateProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
