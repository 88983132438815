import { Link } from "@mui/material";
import { MdEvent, MdEventAvailable, MdWorkspaces } from "react-icons/md";
import { useLocation, useNavigate } from "react-router-dom";
import { Colors } from "../Colors";
import "../App.css";
import { FaHospitalSymbol, FaStethoscope, FaUserCircle } from "react-icons/fa";
import { usePostState } from "../helpers/usePostState";
import { useContext } from "react";
import { GlobalStateContext } from "../contexts/GlobalStateContext";

export default function ProviderMenu(props: { handleClick: () => void }) {
  const globalContext = useContext(GlobalStateContext);

  const [jobs, setJobs, updateJobs] = usePostState(
    globalContext.api.myJobsListingView,
    globalContext.callOptions(),
    {},
    {
      filter: {
        state: "active",
      },
      pagination: {
        pageSize: 1000000,
        page: 1,
      },
      sort: { sortBy: "Date / Time", sortOrder: "asc" },
    }
  );

  return (
    <div
      style={{
        background: "#FBFBFD",
        paddingInline: 30,
        minHeight: "100vh",
        zIndex: 1,
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <MenuHeader label="Jobs" />
        <MenuLink
          title={"Job Board"}
          link="listings"
          icon={MdWorkspaces}
          handleClick={props.handleClick}
        />
        <MenuLink
          title={"Upcoming Jobs"}
          link="jobs"
          icon={MdEvent}
          handleClick={props.handleClick}
        >
          {jobs?.size === undefined ? null : (
            <div
              style={{
                display: "flex",
                background: Colors.green,
                alignItems: "center",
                justifyContent: "center",
                padding: 4,
                borderRadius: 4,
                color: "#fff",
                fontWeight: 800,
                width: 16,
                height: 16,
                fontSize: 12,
              }}
            >
              {jobs?.size ?? 0}
            </div>
          )}
        </MenuLink>
        <MenuLink
          title={"Job History"}
          link="jobhistory"
          icon={MdEventAvailable}
          handleClick={props.handleClick}
        />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <MenuHeader label="Account" />
        <MenuLink
          title={"Profile"}
          icon={FaUserCircle}
          link="profile"
          handleClick={props.handleClick}
        />
        <MenuLink
          title={"My Facilities"}
          link="facilities"
          icon={FaHospitalSymbol}
          handleClick={props.handleClick}
        />
      </div>
      {/* <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <MenuHeader label="Credentialing" />
        <MenuLink
          title={"Coming Soon"}
          icon={FaStethoscope}
          handleClick={props.handleClick}
        />
      </div> */}
    </div>
  );
}

function MenuHeader(props: { label: string }) {
  return (
    <p
      style={{
        margin: 0,
        marginTop: 28,
        fontWeight: 700,
        fontSize: 14,
        lineHeight: "18px",
        color: Colors.lightGray,
      }}
    >
      {props.label.toUpperCase()}
    </p>
  );
}

function MenuLink(props: {
  title: string;
  link?: string;
  icon: any;
  children?: any;
  handleClick: () => void;
}) {
  const location = useLocation();
  const path = location.pathname;
  const navigate = useNavigate();

  return (
    <Link
      onClick={
        props.link === undefined
          ? undefined
          : () => {
              navigate(`./${props.link}`);
              props.handleClick();
            }
      }
      underline={"none"}
      component="button"
      style={{
        display: "flex",
        flex: 1,
        padding: 12,
        marginBlock: 8,
        background:
          path === `/provider/${props.link}`
            ? `${Colors.blueBrightPrimary}`
            : "transparent",
        fontWeight: 400,
        borderRadius: 12,
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "flex-start",
        color:
          props.link === undefined
            ? "rgba(102, 112, 133, 0.4)"
            : path === `/provider/${props.link}`
            ? Colors.blue
            : Colors.lightGray,
        fontSize: 16,
        cursor: props.link === undefined ? "default" : "pointer",
        gap: 12,
      }}
    >
      <props.icon style={{ display: "flex", flexShrink: 0 }} />
      <p className="p.link">{props.title}</p>
      {props.children}
    </Link>
  );
}
