import { Link } from "@mui/material";
import { MdEventNote, MdWorkspaces } from "react-icons/md";
import { useLocation, useNavigate } from "react-router-dom";
import { Colors } from "../Colors";
import "../App.css";
import { FaPlusCircle, FaRegBuilding, FaUserCircle } from "react-icons/fa";

export default function FacilityMenu(props: { handleClick: () => void }) {
  return (
    <>
      <div
        style={{
          background: "#FBFBFD",
          paddingInline: 30,
          minHeight: "100vh",
          zIndex: 1,
          borderRight: "1px solid lightgray",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <MenuHeader label="Jobs" />
          <MenuLink
            title={"Job Board"}
            link="listings"
            icon={MdWorkspaces}
            handleClick={props.handleClick}
          />
          <MenuLink
            title={"Job History"}
            link="jobs"
            icon={MdEventNote}
            handleClick={props.handleClick}
          />
          <MenuLink
            title={"Add Job"}
            icon={FaPlusCircle}
            link="listings/addlisting"
            handleClick={props.handleClick}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <MenuHeader label="Facilities" />
          <MenuLink
            title={"Facility List"}
            icon={FaRegBuilding}
            link="locations"
            handleClick={props.handleClick}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <MenuHeader label="Account" />
          <MenuLink
            title={"Your Profile"}
            icon={FaUserCircle}
            link="profile"
            handleClick={props.handleClick}
          />
        </div>
      </div>
    </>
  );
}

function MenuHeader(props: { label: string }) {
  return (
    <p
      style={{
        margin: 0,
        marginTop: 28,
        fontWeight: 700,
        fontSize: 14,
        lineHeight: "18px",
        color: Colors.lightGray,
      }}
    >
      {props.label.toUpperCase()}
    </p>
  );
}

function MenuLink(props: {
  title: string;
  link?: string;
  icon: any;
  handleClick: () => void;
}) {
  const location = useLocation();
  const path = location.pathname;
  const navigate = useNavigate();

  return (
    <Link
      onClick={
        props.link === undefined
          ? undefined
          : () => {
              navigate(`./${props.link}`);
              props.handleClick();
            }
      }
      underline={"none"}
      component="button"
      style={{
        display: "flex",
        flex: 1,
        padding: 12,
        marginBlock: 8,
        background:
          path === `/facility/${props.link}`
            ? `${Colors.blueBrightPrimary}`
            : "transparent",
        fontWeight: 400,
        borderRadius: 12,
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "flex-start",
        color:
          props.link === undefined
            ? "rgba(102, 112, 133, 0.4)"
            : path === `/facility/${props.link}`
            ? `${Colors.blue}`
            : Colors.lightGray,
        fontSize: 16,
        cursor: props.link === undefined ? "default" : "pointer",
      }}
    >
      <props.icon />
      <p style={{ marginLeft: 12 }} className="p.link">
        {props.title}
      </p>
    </Link>
  );
}
