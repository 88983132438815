import { ButtonUnstyled } from "@mui/base";
import { useNavigate } from "react-router-dom";
import { useState, useEffect, useContext } from "react";
import { Colors } from "../Colors";
import { GlobalStateContext } from "../contexts/GlobalStateContext";
import { Dialog } from "@mui/material";
import { MdClose } from "react-icons/md";
import TimezoneSelect from "react-timezone-select";

export default function AdminAddFacility() {
  const navigate = useNavigate();

  const globalContext = useContext(GlobalStateContext);

  const [successVisible, setSuccessVisible] = useState<boolean>(false);

  const states = [
    {
      name: "Alabama",
      abbreviation: "AL",
    },
    {
      name: "Alaska",
      abbreviation: "AK",
    },
    {
      name: "American Samoa",
      abbreviation: "AS",
    },
    {
      name: "Arizona",
      abbreviation: "AZ",
    },
    {
      name: "Arkansas",
      abbreviation: "AR",
    },
    {
      name: "California",
      abbreviation: "CA",
    },
    {
      name: "Colorado",
      abbreviation: "CO",
    },
    {
      name: "Connecticut",
      abbreviation: "CT",
    },
    {
      name: "Delaware",
      abbreviation: "DE",
    },
    {
      name: "District Of Columbia",
      abbreviation: "DC",
    },
    {
      name: "Federated States Of Micronesia",
      abbreviation: "FM",
    },
    {
      name: "Florida",
      abbreviation: "FL",
    },
    {
      name: "Georgia",
      abbreviation: "GA",
    },
    {
      name: "Guam",
      abbreviation: "GU",
    },
    {
      name: "Hawaii",
      abbreviation: "HI",
    },
    {
      name: "Idaho",
      abbreviation: "ID",
    },
    {
      name: "Illinois",
      abbreviation: "IL",
    },
    {
      name: "Indiana",
      abbreviation: "IN",
    },
    {
      name: "Iowa",
      abbreviation: "IA",
    },
    {
      name: "Kansas",
      abbreviation: "KS",
    },
    {
      name: "Kentucky",
      abbreviation: "KY",
    },
    {
      name: "Louisiana",
      abbreviation: "LA",
    },
    {
      name: "Maine",
      abbreviation: "ME",
    },
    {
      name: "Marshall Islands",
      abbreviation: "MH",
    },
    {
      name: "Maryland",
      abbreviation: "MD",
    },
    {
      name: "Massachusetts",
      abbreviation: "MA",
    },
    {
      name: "Michigan",
      abbreviation: "MI",
    },
    {
      name: "Minnesota",
      abbreviation: "MN",
    },
    {
      name: "Mississippi",
      abbreviation: "MS",
    },
    {
      name: "Missouri",
      abbreviation: "MO",
    },
    {
      name: "Montana",
      abbreviation: "MT",
    },
    {
      name: "Nebraska",
      abbreviation: "NE",
    },
    {
      name: "Nevada",
      abbreviation: "NV",
    },
    {
      name: "New Hampshire",
      abbreviation: "NH",
    },
    {
      name: "New Jersey",
      abbreviation: "NJ",
    },
    {
      name: "New Mexico",
      abbreviation: "NM",
    },
    {
      name: "New York",
      abbreviation: "NY",
    },
    {
      name: "North Carolina",
      abbreviation: "NC",
    },
    {
      name: "North Dakota",
      abbreviation: "ND",
    },
    {
      name: "Northern Mariana Islands",
      abbreviation: "MP",
    },
    {
      name: "Ohio",
      abbreviation: "OH",
    },
    {
      name: "Oklahoma",
      abbreviation: "OK",
    },
    {
      name: "Oregon",
      abbreviation: "OR",
    },
    {
      name: "Palau",
      abbreviation: "PW",
    },
    {
      name: "Pennsylvania",
      abbreviation: "PA",
    },
    {
      name: "Puerto Rico",
      abbreviation: "PR",
    },
    {
      name: "Rhode Island",
      abbreviation: "RI",
    },
    {
      name: "South Carolina",
      abbreviation: "SC",
    },
    {
      name: "South Dakota",
      abbreviation: "SD",
    },
    {
      name: "Tennessee",
      abbreviation: "TN",
    },
    {
      name: "Texas",
      abbreviation: "TX",
    },
    {
      name: "Utah",
      abbreviation: "UT",
    },
    {
      name: "Vermont",
      abbreviation: "VT",
    },
    {
      name: "Virgin Islands",
      abbreviation: "VI",
    },
    {
      name: "Virginia",
      abbreviation: "VA",
    },
    {
      name: "Washington",
      abbreviation: "WA",
    },
    {
      name: "West Virginia",
      abbreviation: "WV",
    },
    {
      name: "Wisconsin",
      abbreviation: "WI",
    },
    {
      name: "Wyoming",
      abbreviation: "WY",
    },
  ];

  const [name, setName] = useState<string>("");
  const [entranceProtocol, setEntranceProtocol] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [address1, setAddress1] = useState<string>("");
  const [address2, setAddress2] = useState<string>("");
  const [city, setCity] = useState<string>("");
  const [state, setState] = useState<string>("");
  const [zip, setZip] = useState<string>("");

  const [selectedTimezone, setSelectedTimezone] = useState<string | undefined>(
    undefined
  );

  function validateName(): string {
    if (name.length === 0) {
      return "Name is required.";
    }
    return "";
  }

  function validateEntranceProtocol(): string {
    if (entranceProtocol.length === 0) {
      return "Entrance protocol is required.";
    }
    return "";
  }

  function validatePhone(): string {
    if (phone.length === 10) {
      return "";
    }
    return "Please enter a valid phone number (including area code) with no dashes or spaces.";
  }

  function validateAddress1(): string {
    if (address1.length === 0) {
      return "Address is required.";
    }
    return "";
  }

  function validateCity(): string {
    if (city.length === 0) {
      return "City is required.";
    }
    return "";
  }

  function validateState(): string {
    if (state.length === 0) {
      return "State is required.";
    }
    return "";
  }

  function validateZip(): string {
    if (zip.length === 5) {
      return "";
    }
    return "A valid zip code is required.";
  }

  function validate() {
    return !(
      !!validateName() ||
      !!validateAddress1() ||
      !!validateCity() ||
      !!validateEntranceProtocol() ||
      !!validatePhone() ||
      !!validateState() ||
      !!validateZip()
    );
  }

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-start",
        flexDirection: "column",
        flex: 1,
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "flex-start",
          flexDirection: "column",
          marginBottom: 24,
        }}
      >
        <h1
          style={{
            margin: 0,
            fontSize: 32,
            marginBottom: 12,
            fontWeight: "700",
          }}
        >
          Add Facility
        </h1>
        <p style={{ color: Colors.lightGray }}>Add a facility to Pagerr.</p>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginBottom: 320,
          maxWidth: 620,
          gap: 12,
        }}
      >
        <label>
          Facility Name
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.currentTarget.value)}
          />
          <p style={{ height: 20, marginInline: 8, color: Colors.blue }}>
            {validateName()}
          </p>
        </label>
        <label>
          Entrance Protocol
          <input
            type="text"
            value={entranceProtocol}
            onChange={(e) => setEntranceProtocol(e.currentTarget.value)}
          />
          <p style={{ height: 20, marginInline: 8, color: Colors.blue }}>
            {validateEntranceProtocol()}
          </p>
        </label>
        <label>
          Phone Number
          <input
            type="phone"
            value={phone}
            onChange={(e) => setPhone(e.currentTarget.value)}
            maxLength={10}
          />
          <p style={{ height: 20, marginInline: 8, color: Colors.blue }}>
            {validatePhone()}
          </p>
        </label>
        <label>
          Street Address 1
          <input
            type="text"
            value={address1}
            onChange={(e) => setAddress1(e.currentTarget.value)}
          />
          <p style={{ height: 20, marginInline: 8, color: Colors.blue }}>
            {validateAddress1()}
          </p>
        </label>
        <label>
          Street Address 2
          <input
            type="text"
            value={address2}
            onChange={(e) => setAddress2(e.currentTarget.value)}
          />
          <p style={{ height: 20, marginInline: 8, color: Colors.blue }}></p>
        </label>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            gap: "inherit",
          }}
        >
          <label style={{ flex: 1 }}>
            City
            <input
              type="text"
              value={city}
              onChange={(e) => setCity(e.currentTarget.value)}
            />
            <p style={{ height: 20, marginInline: 8, color: Colors.blue }}>
              {validateCity()}
            </p>
          </label>
          <label style={{ flex: 1 }}>
            State
            <select
              style={{
                padding: 7,
                borderRadius: 8,
                fontSize: 16,
                height: 37,
              }}
              value={state}
              onChange={(e) => setState(e.currentTarget.value)}
            >
              <option disabled value="" label="">
                Select a state...
              </option>
              {states.map(function (item) {
                return (
                  <option
                    key={item.name}
                    style={{
                      height: 20,
                      marginInline: 8,
                      color: Colors.blue,
                    }}
                    value={item.abbreviation}
                    label={`${item.abbreviation} - ${item.name}`}
                  >
                    {item.abbreviation} - {item.name}
                  </option>
                );
              })}
            </select>
            <p style={{ height: 20, marginInline: 8, color: Colors.blue }}>
              {validateState()}
            </p>
          </label>
          <label style={{ flex: 1 }}>
            Zip Code
            <input
              type="text"
              value={zip}
              maxLength={5}
              onChange={(e) => setZip(e.currentTarget.value)}
            />
            <p style={{ height: 20, marginInline: 8, color: Colors.blue }}>
              {validateZip()}
            </p>
          </label>
        </div>
        <label>
          Time Zone
          <TimezoneSelect
            value={selectedTimezone ?? ""}
            onChange={(e) => setSelectedTimezone(e.value)}
          />
        </label>
        <div
          style={{
            display: "flex",
            marginTop: 20,
            justifyContent: "flex-end",
          }}
        >
          <ButtonUnstyled
            style={{
              cursor: validate() === true ? "pointer" : undefined,
              borderRadius: 4,
              paddingBlock: 6,
              paddingInline: 32,
              fontSize: 16,
              fontWeight: 600,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              background: Colors.border,
              color: validate() === true ? Colors.blue : "lightgray",
              border:
                validate() === true
                  ? `1px solid ${Colors.blue}`
                  : `1px solid lightgray`,
              boxShadow:
                validate() === true ? "2px 2px 4px rgba(0,0,0,0.2)" : "none",
            }}
            disabled={!validate()}
            onClick={() => {
              if (validate() === true) {
                globalContext.api.createFacility
                  .call(
                    globalContext.callOptions(),
                    {},
                    {
                      name: name,
                      entranceProtocol: entranceProtocol,
                      phone: phone,
                      timezone: selectedTimezone!,
                      address1: address1,
                      address2: address2,
                      city: city,
                      state: state,
                      zip: zip,
                    }
                  )
                  .then((response) => {
                    if (response.status === 200) {
                      setSuccessVisible(true);
                    } else {
                      alert(JSON.stringify(response.error));
                    }
                  });
              }
            }}
          >
            Add Facility
          </ButtonUnstyled>
        </div>
      </div>

      <Dialog
        open={successVisible}
        onClose={() => navigate("/admin/facilities")}
      >
        <div
          style={{
            padding: 20,
            background: "#fff",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <MdClose
            style={{ alignSelf: "flex-end", cursor: "pointer" }}
            onClick={() => navigate("/admin/facilities")}
          />
          <p style={{ fontSize: 16, fontWeight: 700 }}>Success</p>
          <p style={{ fontSize: 12, fontWeight: 500, marginBottom: 12 }}>
            {name} has been added to Pagerr.
          </p>
          <ButtonUnstyled
            style={{
              cursor: "pointer",
              borderRadius: 4,
              paddingBlock: 6,
              paddingInline: 8,
              fontWeight: 600,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              background: Colors.border,
              color: Colors.blue,
              border: `1px solid ${Colors.blue}`,
              boxShadow: "2px 2px 4px rgba(0,0,0,0.2)",

              alignSelf: "flex-end",
              marginTop: 12,
            }}
            onClick={() => {
              navigate("/admin/facilities");
            }}
          >
            Okay
          </ButtonUnstyled>
        </div>
      </Dialog>
    </div>
  );
}
