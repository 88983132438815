import {
  AuthProvider,
  FailureHandler,
  HttpRequest,
  HttpResponse,
} from "@xaratan/pagerr-common";
import axios, { AxiosRequestConfig } from "axios";

export default class AxiosHttpRequest extends HttpRequest {
  async get(
    url: string,
    authProvider: AuthProvider,
    failureHandler: FailureHandler,
    abortController?: AbortController | undefined
  ): Promise<HttpResponse<any>> {
    try {
      let config: AxiosRequestConfig = abortController
        ? {
            signal: abortController?.signal,
          }
        : {};

      config = authProvider.addAuth(config);

      const response = await axios.get(url, config);

      return {
        status: response.status,
        data: response.data,
      };
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return {
          status: error.response?.status ?? 500,
          error: (error.response?.data as any)?.error ?? "Unknown error (0xA)",
        };
      } else {
        return {
          status: 500,
          error: "Unknown error (0xB)",
        };
      }
    }
  }
  async post(
    url: string,
    body: any,
    authProvider: AuthProvider,
    failureHandler: FailureHandler,
    abortController?: AbortController | undefined
  ): Promise<HttpResponse<any>> {
    try {
      let config: AxiosRequestConfig = abortController
        ? {
            signal: abortController?.signal,
          }
        : {};

      config = authProvider.addAuth(config);

      const response = await axios.post(url, body, config);

      return {
        status: response.status,
        data: response.data,
      };
    } catch (error) {
      if (axios.isAxiosError(error)) {
        failureHandler.handle(
          error.response?.status ?? 500,
          (error.response?.data as any)?.error ?? "Unknown error (0xC)"
        );

        return {
          status: error.response?.status ?? 500,
          error: (error.response?.data as any)?.error ?? "Unknown error (0xC)",
        };
      } else {
        failureHandler.handle(500, "Unknown error (0xD)");

        return {
          status: 500,
          error: "Unknown error (0xD)",
        };
      }
    }
  }
}
