import { ButtonUnstyled } from "@mui/base";
import { useContext, useState } from "react";
import type { Facility } from "@xaratan/pagerr-common";
import { Colors } from "../Colors";
import { MdArrowBack } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { useSignup } from "../contexts/useSignup";
import { GlobalStateContext } from "../contexts/GlobalStateContext";
import { Dialog } from "@mui/material";
import { Oval } from "react-loader-spinner";
import { useGetState } from "../helpers/useGetState";
import { usePostState } from "../helpers/usePostState";
import { useGlobals } from "../contexts/useGlobals";
import { waitForElementToBeRemoved } from "@testing-library/react";

export default function ProviderAddFacility() {
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(false);

  const globalContext = useContext(GlobalStateContext);
  const globalState = useGlobals();

  const [facilityList] = useGetState(
    globalContext.api.getFacilities,
    globalContext.callOptions(),
    {}
  );

  const [myFacilities] = usePostState(
    globalState.api.myFacilitiesListingView,
    globalState.callOptions(),
    {},
    {}
  );

  var myArray = facilityList?.facilities;
  var toRemove = myFacilities?.facilities;

  for (var i = (myArray ?? []).length - 1; i >= 0; i--) {
    for (var j = 0; j < (toRemove ?? []).length; j++) {
      if (myArray![i] && myArray![i].id === (toRemove ?? [])[j].facilityId) {
        (myArray ?? []).splice(i, 1);
      }
    }
  }

  const [dialog, setDialog] = useState<
    | undefined
    | {
        type: "success" | "error";
        message: string;
      }
  >(undefined);

  const [newFacility, setNewFacility] = useState<string>("");
  const [credentialed, setCredentialed] = useState<string>("");

  const states = [
    {
      name: "Alabama",
      abbreviation: "AL",
    },
    {
      name: "Alaska",
      abbreviation: "AK",
    },
    {
      name: "American Samoa",
      abbreviation: "AS",
    },
    {
      name: "Arizona",
      abbreviation: "AZ",
    },
    {
      name: "Arkansas",
      abbreviation: "AR",
    },
    {
      name: "California",
      abbreviation: "CA",
    },
    {
      name: "Colorado",
      abbreviation: "CO",
    },
    {
      name: "Connecticut",
      abbreviation: "CT",
    },
    {
      name: "Delaware",
      abbreviation: "DE",
    },
    {
      name: "District Of Columbia",
      abbreviation: "DC",
    },
    {
      name: "Federated States Of Micronesia",
      abbreviation: "FM",
    },
    {
      name: "Florida",
      abbreviation: "FL",
    },
    {
      name: "Georgia",
      abbreviation: "GA",
    },
    {
      name: "Guam",
      abbreviation: "GU",
    },
    {
      name: "Hawaii",
      abbreviation: "HI",
    },
    {
      name: "Idaho",
      abbreviation: "ID",
    },
    {
      name: "Illinois",
      abbreviation: "IL",
    },
    {
      name: "Indiana",
      abbreviation: "IN",
    },
    {
      name: "Iowa",
      abbreviation: "IA",
    },
    {
      name: "Kansas",
      abbreviation: "KS",
    },
    {
      name: "Kentucky",
      abbreviation: "KY",
    },
    {
      name: "Louisiana",
      abbreviation: "LA",
    },
    {
      name: "Maine",
      abbreviation: "ME",
    },
    {
      name: "Marshall Islands",
      abbreviation: "MH",
    },
    {
      name: "Maryland",
      abbreviation: "MD",
    },
    {
      name: "Massachusetts",
      abbreviation: "MA",
    },
    {
      name: "Michigan",
      abbreviation: "MI",
    },
    {
      name: "Minnesota",
      abbreviation: "MN",
    },
    {
      name: "Mississippi",
      abbreviation: "MS",
    },
    {
      name: "Missouri",
      abbreviation: "MO",
    },
    {
      name: "Montana",
      abbreviation: "MT",
    },
    {
      name: "Nebraska",
      abbreviation: "NE",
    },
    {
      name: "Nevada",
      abbreviation: "NV",
    },
    {
      name: "New Hampshire",
      abbreviation: "NH",
    },
    {
      name: "New Jersey",
      abbreviation: "NJ",
    },
    {
      name: "New Mexico",
      abbreviation: "NM",
    },
    {
      name: "New York",
      abbreviation: "NY",
    },
    {
      name: "North Carolina",
      abbreviation: "NC",
    },
    {
      name: "North Dakota",
      abbreviation: "ND",
    },
    {
      name: "Northern Mariana Islands",
      abbreviation: "MP",
    },
    {
      name: "Ohio",
      abbreviation: "OH",
    },
    {
      name: "Oklahoma",
      abbreviation: "OK",
    },
    {
      name: "Oregon",
      abbreviation: "OR",
    },
    {
      name: "Palau",
      abbreviation: "PW",
    },
    {
      name: "Pennsylvania",
      abbreviation: "PA",
    },
    {
      name: "Puerto Rico",
      abbreviation: "PR",
    },
    {
      name: "Rhode Island",
      abbreviation: "RI",
    },
    {
      name: "South Carolina",
      abbreviation: "SC",
    },
    {
      name: "South Dakota",
      abbreviation: "SD",
    },
    {
      name: "Tennessee",
      abbreviation: "TN",
    },
    {
      name: "Texas",
      abbreviation: "TX",
    },
    {
      name: "Utah",
      abbreviation: "UT",
    },
    {
      name: "Vermont",
      abbreviation: "VT",
    },
    {
      name: "Virgin Islands",
      abbreviation: "VI",
    },
    {
      name: "Virginia",
      abbreviation: "VA",
    },
    {
      name: "Washington",
      abbreviation: "WA",
    },
    {
      name: "West Virginia",
      abbreviation: "WV",
    },
    {
      name: "Wisconsin",
      abbreviation: "WI",
    },
    {
      name: "Wyoming",
      abbreviation: "WY",
    },
  ];

  const [name, setName] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [city, setCity] = useState<string>("");
  const [state, setState] = useState<string>("");

  function validateName(): string {
    if (name.length === 0) {
      return "Name is required.";
    }
    return "";
  }

  function validatePhone(): string {
    if (phone.length === 10) {
      return "";
    }
    return "Please enter a valid phone number (including area code) with no dashes or spaces.";
  }

  function validateCity(): string {
    if (city.length === 0) {
      return "City is required.";
    }
    return "";
  }

  function validateState(): string {
    if (state.length === 0) {
      return "State is required.";
    }
    return "";
  }

  function validateNewFacility(): string {
    if (newFacility === "") {
      return "Facility choice is required.";
    }
    return "";
  }

  function validateCredentials(): string {
    if (credentialed === "") {
      return "Credentialed status is required.";
    }
    return "";
  }

  function validateNotOther() {
    return !(!!validateNewFacility() || !!validateCredentials());
  }

  function validateOther() {
    return !(
      !!validateCredentials() ||
      !!validateName() ||
      !!validateCity() ||
      !!validatePhone() ||
      !!validateState()
    );
  }

  var axios = require("axios");
  var data = JSON.stringify({
    fields: {
      Name: "New credentialing request",
      Status: "Todo",
      ProviderId: globalContext.sessionInfo.value?.provider._id,
      ProviderName: `${globalContext.sessionInfo.value?.firstName} ${globalContext.sessionInfo.value?.lastName}`,
      NewFacilityName: name ? name : "n/a",
      ExistingFacility: newFacility === "other" ? false : true,
      FacilityId: newFacility === "other" ? "n/a" : newFacility,
      FacilityPhoneNumber: phone,
      FacilityCity: city,
      FacilityState: state,
      CredentialedStatus: credentialed === "credentialed" ? true : false,
    },
    // typecast: true,
  });

  var config = {
    method: "post",
    url: "https://api.airtable.com/v0/appvC3ANqONYNSyO4/Credentialing%20Requests",
    headers: {
      Authorization: "Bearer keybnv0gNR7elsEEQ",
      "Content-Type": "application/json",
      // Cookie: "brw=brwivyZQVPudxASqM",
    },
    data: data,
  };

  function Send() {
    axios(config)
      .then(function (response: any) {
        console.log(JSON.stringify(response.data));
        setDialog({
          type: "success",
          message:
            "Your credentaling request was successful. The Pagerr team needs to review the request, and then we'll be in touch for next steps!",
        });
        setLoading(false);
      })
      .catch(function (error: any) {
        console.log(error);
        setDialog({
          type: "error",
          message: "Something went wrong. Please try again.",
        });
        setLoading(false);
      });
  }

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        flexDirection: "column",
        flex: 1,
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "flex-start",
          flexDirection: "column",
          justifyContent: "flex-start",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
            marginBottom: 12,
          }}
        >
          <MdArrowBack
            size={24}
            style={{ cursor: "pointer", display: "flex", flexShrink: 0 }}
            onClick={() => navigate("/provider/facilities")}
          />
          <h1
            style={{
              margin: 0,
              fontSize: 32,
              fontWeight: "700",
              marginLeft: 8,
            }}
          >
            Add Facility
          </h1>
        </div>
        <p style={{ color: Colors.lightGray, marginBottom: 20 }}>
          Add a facility where you are credentialed to work, or where you would
          like to begin working.
        </p>
      </div>
      <div
        style={{
          marginBottom: 200,
          display: "flex",
          flexDirection: "column",
          gap: 12,
        }}
      >
        <label>
          Facility
          <select
            style={{
              padding: 7,
              borderRadius: 8,
              marginTop: 4,
              fontSize: 16,
              display: "flex",
              textOverflow: "ellipsis",
              overflow: "clip",
            }}
            value={newFacility}
            onChange={(e) => setNewFacility(e.currentTarget.value)}
          >
            <option disabled value="" label="Please select a facility...">
              Please select a facility...
            </option>
            {(facilityList?.facilities ?? []).map(function (item) {
              return (
                <option
                  key={item.id}
                  style={{
                    height: 20,
                    marginInline: 8,
                    color: Colors.blue,
                  }}
                  value={item.id}
                  label={`${item.name} (${item.address.city}, ${item.address.state})`}
                >
                  {item.name} ({item.address.city}, {item.address.state})
                </option>
              );
            })}
            <option value="other" label="Other...">
              Other...
            </option>
          </select>
          <p
            style={{
              height: 20,
              marginInline: 8,
              color: Colors.blue,
            }}
          >
            {validateNewFacility()}
          </p>
        </label>
        {newFacility === "other" ? (
          <div
            style={{ display: "flex", flexDirection: "column", gap: "inherit" }}
          >
            <label>
              Facility Name
              <input
                type="text"
                value={name}
                onChange={(e) => setName(e.currentTarget.value)}
              />
              <p
                style={{
                  height: 20,
                  marginInline: 8,
                  color: Colors.blue,
                }}
              >
                {validateName()}
              </p>
            </label>
            <label>
              Phone Number of Credentialing Department
              <input
                type="tel"
                pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                value={phone}
                onChange={(e) => setPhone(e.currentTarget.value)}
                maxLength={10}
              />
              <p
                style={{
                  height: 20,
                  marginInline: 8,
                  color: Colors.blue,
                }}
              >
                {validatePhone()}
              </p>
            </label>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                gap: "inherit",
              }}
            >
              <label style={{ flex: 1 }}>
                City
                <input
                  type="text"
                  value={city}
                  onChange={(e) => setCity(e.currentTarget.value)}
                />
                <p
                  style={{
                    height: 20,
                    marginInline: 8,
                    color: Colors.blue,
                  }}
                >
                  {validateCity()}
                </p>
              </label>
              <label style={{ flex: 1 }}>
                State
                <select
                  style={{
                    padding: 7,
                    borderRadius: 8,
                    fontSize: 16,
                    height: 37,
                    display: "flex",
                    flex: 1,
                  }}
                  value={state}
                  onChange={(e) => setState(e.currentTarget.value)}
                >
                  <option disabled value="" label="">
                    Select a state...
                  </option>
                  {states.map(function (item) {
                    return (
                      <option
                        key={item.name}
                        style={{
                          height: 20,
                          marginInline: 8,
                          color: Colors.blue,
                        }}
                        value={item.abbreviation}
                        label={`${item.abbreviation} - ${item.name}`}
                      >
                        {item.abbreviation} - {item.name}
                      </option>
                    );
                  })}
                </select>
                <p
                  style={{
                    height: 20,
                    marginInline: 8,
                    color: Colors.blue,
                  }}
                >
                  {validateState()}
                </p>
              </label>
            </div>
          </div>
        ) : null}
        <label>
          Credentialed Status
          <select
            style={{
              padding: 7,
              borderRadius: 8,
              marginTop: 4,
              fontSize: 16,
              flex: 1,
              paddingRight: 32,
              display: "flex",
              textOverflow: "ellipsis",
              overflow: "clip",
            }}
            value={credentialed}
            onChange={(e) => setCredentialed(e.currentTarget.value)}
          >
            <option disabled value="" label="Please select an option...">
              Please select an option...
            </option>
            <option
              value="credentialed"
              label="I am credentialed to work at this facility."
            >
              I am credentialed to work at this facility.
            </option>
            <option
              value="not credentialed"
              label="I am not credentialed to work at this facility but would like to be."
            >
              I am not credentialed to work at this facility but would like to
              be.
            </option>
          </select>
          <p
            style={{
              height: 20,
              marginInline: 8,
              color: Colors.blue,
            }}
          >
            {validateCredentials()}
          </p>
        </label>

        <div
          style={{
            display: "flex",
            marginTop: 10,
            marginInline: 12,
            justifyContent: "flex-end",
            flex: 1,
          }}
        >
          {loading === true ? (
            <Oval
              height={20}
              width={20}
              color={Colors.blue}
              secondaryColor={Colors.lightGray}
              strokeWidth={6}
            />
          ) : newFacility === "other" ? (
            <ButtonUnstyled
              style={{
                cursor: validateOther() === true ? "pointer" : undefined,
                borderRadius: 4,
                paddingBlock: 6,
                paddingInline: 32,
                fontWeight: 600,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                fontSize: 16,
                background: Colors.border,
                color: validateOther() === true ? Colors.blue : "lightgray",
                border:
                  validateOther() === true
                    ? `1px solid ${Colors.blue}`
                    : `1px solid lightgray`,
                boxShadow:
                  validateOther() === true
                    ? "2px 2px 4px rgba(0,0,0,0.2)"
                    : "none",
              }}
              disabled={!validateOther() || loading}
              onClick={() => {
                setLoading(true);
                Send();
                // navigate("/provider/facilities");
              }}
            >
              Add Facility
            </ButtonUnstyled>
          ) : (
            <ButtonUnstyled
              style={{
                cursor: validateNotOther() === true ? "pointer" : undefined,
                borderRadius: 4,
                paddingBlock: 6,
                paddingInline: 32,
                fontWeight: 600,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                background: Colors.border,
                fontSize: 16,
                color: validateNotOther() === true ? Colors.blue : "lightgray",
                border:
                  validateNotOther() === true
                    ? `1px solid ${Colors.blue}`
                    : `1px solid lightgray`,
                boxShadow:
                  validateNotOther() === true
                    ? "2px 2px 4px rgba(0,0,0,0.2)"
                    : "none",
              }}
              disabled={!validateNotOther() || loading}
              onClick={() => {
                setLoading(true);
                Send();

                // navigate("/provider/facilities");
              }}
            >
              Add Facility
            </ButtonUnstyled>
          )}
          <Dialog
            open={!!dialog}
            onClose={
              dialog?.type === "error"
                ? () => setDialog(undefined)
                : () => navigate("/provider/facilities")
            }
          >
            <div
              style={{
                background: "#fff",
                padding: 8,
                display: "flex",
                flexDirection: "column",
                gap: 12,
              }}
            >
              <p
                style={{
                  fontWeight: 700,
                  color: dialog?.type === "error" ? Colors.red : Colors.green,
                }}
              >
                {dialog?.type.toUpperCase()}
              </p>
              <p>{dialog?.message}</p>
              <ButtonUnstyled
                style={{
                  cursor: "pointer",
                  borderRadius: 4,
                  paddingBlock: 6,
                  paddingInline: 32,
                  fontWeight: 600,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  background: Colors.border,
                  color: Colors.blue,
                  border: `1px solid ${Colors.blue}`,
                  boxShadow: "2px 2px 4px rgba(0,0,0,0.2)",
                  alignSelf: "flex-end",
                }}
                onClick={
                  dialog?.type === "error"
                    ? () => setDialog(undefined)
                    : () => navigate("/provider/facilities")
                }
              >
                OK
              </ButtonUnstyled>
            </div>
          </Dialog>
        </div>
      </div>
    </div>
  );
}
