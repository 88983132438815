import {
  Address,
  ApiCallOptions,
  AuthProvider,
  Certification,
  EndPointType,
  FailureHandler,
  initApi,
  ProviderType,
  SessionInfoResponseDefinition,
  Specialty,
} from "@xaratan/pagerr-common";
import { ApiEndPoints } from "@xaratan/pagerr-common/dist/api/ApiEndPoints";
import { AxiosRequestConfig } from "axios";
import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import AxiosHttpRequest from "../helpers/AxiosHttpRequest";

const api = initApi(new AxiosHttpRequest());

export type StateVariable<T> = {
  value: T;
  setValue: (value: T | ((value: T) => T)) => void;
};

function nullStateVariable<T>(value: T): StateVariable<T> {
  let obj: any = {};

  obj.value = value;

  obj.setValue = (value: T) => {
    obj.value = value;
  };

  return obj;
}

export type SignupStateType = {
  ready: StateVariable<boolean>;

  firstName: StateVariable<string | undefined>;
  lastName: StateVariable<string | undefined>;
  phone: StateVariable<string | undefined>;
  address: StateVariable<Address | undefined>;
  password: StateVariable<string | undefined>;
  crnaMdDo: StateVariable<ProviderType | undefined>;
  boardCertification: StateVariable<Certification | undefined>;
  facilities: StateVariable<FacilityDefinition[] | undefined>;
  specialties: StateVariable<Specialty[] | undefined>;
};

function callOptions(
  failureHandler?: FailureHandler,
  authProvider?: AuthProvider
): ApiCallOptions {
  return {
    baseUrl: "",
    authProvider: authProvider,
    failureHandler: failureHandler,
  };
}

export type FacilityDefinition =
  | {
      existing: true;
      id: string;
      credentialed: boolean;
      employmentType: string;
    }
  | {
      existing: false;
      name: string;
      phone: string;
      address: Address;
      credentialed: boolean;
      employmentType: string;
    };

export type SignupStateAccessor = {
  api: ApiEndPoints;
  callOptions: typeof callOptions;

  firstName: StateVariable<string | undefined>;
  lastName: StateVariable<string | undefined>;
  phone: StateVariable<string | undefined>;
  address: StateVariable<Address | undefined>;
  password: StateVariable<string | undefined>;
  crnaMdDo: StateVariable<ProviderType | undefined>;
  boardCertification: StateVariable<Certification | undefined>;
  facilities: StateVariable<FacilityDefinition[] | undefined>;
  specialties: StateVariable<Specialty[] | undefined>;

  ready: React.MutableRefObject<boolean>;
};

export function initializeSignupState(): SignupStateType {
  return {
    ready: nullStateVariable<boolean>(false),
    phone: nullStateVariable<string | undefined>(undefined),
    address: nullStateVariable<Address | undefined>(undefined),
    firstName: nullStateVariable<string | undefined>(undefined),
    lastName: nullStateVariable<string | undefined>(undefined),
    password: nullStateVariable<string | undefined>(undefined),
    crnaMdDo: nullStateVariable<ProviderType | undefined>(undefined),
    boardCertification: nullStateVariable<Certification | undefined>(undefined),
    facilities: nullStateVariable<FacilityDefinition[] | undefined>(undefined),
    specialties: nullStateVariable<Specialty[] | undefined>(undefined),
  };
}

function safeJSONParse<T>(text: string, fallback: T) {
  try {
    return JSON.parse(text);
  } catch (error) {
    return fallback;
  }
}

class DefaultAuthProvider extends AuthProvider {
  constructor() {
    super();
  }

  addAuth(request: AxiosRequestConfig): AxiosRequestConfig {
    //do nothing
    return request;
  }

  setAuth(response: any) {
    //this.setStateAuth(response.token);
  }
}

const SignupStateContext = createContext<SignupStateAccessor>({
  //Temporary data
  api: api,
  callOptions: callOptions,

  ready: {} as any,

  firstName: {} as any,
  lastName: {} as any,
  phone: {} as any,
  address: {} as any,
  password: {} as any,
  crnaMdDo: {} as any,
  boardCertification: {} as any,
  facilities: {} as any,
  specialties: {} as any,
});

function useCreateStateVariable<T>(value: T): StateVariable<T> {
  const [state, setState] = useState<T>(value);

  return {
    value: state,
    setValue: setState,
  };
}

function useSaveEffect<T>(
  ready: boolean,
  key: string,
  value: T,
  toString: (value: T) => string
) {
  useEffect(() => {
    if (ready) {
      //The state has changed!
      const saveState = async () => {
        if (value === undefined) {
          localStorage.removeItem(key);
        } else {
          localStorage.setItem(key, toString(value));
        }
      };

      saveState().catch((error) => {
        console.log("Error saving state: " + error);
      });
    }
  }, [value]);
}

function nullToUndefined<T>(value: T | null | undefined): undefined | T {
  return value === null ? undefined : value;
}

function getSignupStateProvider() {
  const SignupStateProvider = (props: { children: ReactNode }) => {
    const SignupContext = useContext(SignupStateContext);

    //init all of the state variables
    const stringKeys: (keyof SignupStateAccessor)[] = [
      "firstName",
      "lastName",
      "phone",
      "password",
      "crnaMdDo",
      "boardCertification",
    ];

    for (let k of stringKeys) {
      //eslint-disable-next-line react-hooks/rules-of-hooks
      (SignupContext as any)[k] = useCreateStateVariable<string | undefined>(
        nullToUndefined(localStorage.getItem(k))
      );
    }

    const objectKeys: (keyof SignupStateAccessor)[] = [
      "address",
      "facilities",
      "specialties",
    ];

    for (let k of objectKeys) {
      //eslint-disable-next-line react-hooks/rules-of-hooks
      (SignupContext as any)[k] = useCreateStateVariable<
        EndPointType<SessionInfoResponseDefinition> | undefined
      >(
        localStorage.getItem(k)
          ? safeJSONParse(localStorage.getItem(k)!, undefined)
          : undefined
      );
    }

    SignupContext.ready = useRef<boolean>(false);

    const defaultAuthProvider = new DefaultAuthProvider();

    for (let k of stringKeys) {
      //eslint-disable-next-line react-hooks/rules-of-hooks
      useSaveEffect(
        SignupContext.ready.current,
        k,
        (SignupContext as any)[k].value,
        (value) => value ?? ""
      );
    }

    for (let k of objectKeys) {
      //eslint-disable-next-line react-hooks/rules-of-hooks
      useSaveEffect(
        SignupContext.ready.current,
        k,
        (SignupContext as any)[k].value,
        (value) => JSON.stringify(value)
      );
    }

    useEffect(() => {
      SignupContext.ready.current = true;
    }, []);

    return (
      <SignupStateContext.Provider
        value={{
          ready: SignupContext.ready,
          firstName: SignupContext.firstName,
          lastName: SignupContext.lastName,
          phone: SignupContext.phone,
          address: SignupContext.address,
          password: SignupContext.password,
          crnaMdDo: SignupContext.crnaMdDo,
          boardCertification: SignupContext.boardCertification,
          facilities: SignupContext.facilities,
          specialties: SignupContext.specialties,
          api: api,
          callOptions: (
            failureHandler?: FailureHandler,
            authProvider?: AuthProvider
          ) => {
            return {
              baseUrl: "",
              authProvider: authProvider ?? defaultAuthProvider,
              failureHandler: failureHandler,
            };
          },
        }}
      >
        {props.children}
      </SignupStateContext.Provider>
    );
  };

  return SignupStateProvider;
}

const SignupStateConsumer = (props: {
  children: (value: SignupStateAccessor) => ReactNode;
}) => {
  return (
    <SignupStateContext.Consumer>{props.children}</SignupStateContext.Consumer>
  );
};

export { getSignupStateProvider, SignupStateConsumer, SignupStateContext };
