import { Oval } from "react-loader-spinner";
import { Colors } from "../Colors";

export default function PagerrLoader() {
  return (
    <Oval
      color={Colors.blue}
      secondaryColor={Colors.blue}
      width={48}
      strokeWidth={4}
    />
  );
}
