import { FaStethoscope } from "react-icons/fa";
import { Colors } from "../Colors";

export default function Logo() {
  return (
    <div
      style={{
        height: "100vh",
        background: Colors.blue,
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          margin: 12,
        }}
      >
        <div
          style={{
            background: Colors.blueBrightPrimary,
            borderRadius: 100,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            height: 160,
            width: 160,
          }}
        >
          <FaStethoscope style={{ color: Colors.blue }} size={80} />
        </div>
        <h2
          style={{
            color: Colors.blueBrightPrimary,
            fontSize: 160,
            marginLeft: 40,
          }}
        >
          Pagerr
        </h2>
      </div>
    </div>
  );
}
